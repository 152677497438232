<template>
    <div>
        <section class="section-01 alturaBase">
            <div class="container">
              <div class="row mb-4">
                <div class="col-12 col-sm-12 col-md-12 cl-lg-12 col-xl-12">
                    <p>Effective February 25, 2024.</p>
                    <h4 class="mb-2">WHO WE ARE</h4>
                    <p>Zumba Fitness, LLC is a Florida limited liability company with its principal place of business at
                        800 Silks Run, Suite 2310, Hallandale, Florida USA 33009 (“Zumba”, “us,” “we” or “our”). If you
                        are resident in the European Economic Area ("EEA") or Switzerland, Zumba Fitness, LLC is the
                        responsible party or data controller with respect to Personal Data (defined below) we collect.
                        If you have any questions or concerns at any time, please do not hesitate to contact us at the
                        address above or by contacting us at privacy@zumba.com.</p>

                    <h4 class="mb-2">OUR PRIVACY STATEMENT</h4>
                    <p>This Privacy Policy sets forth our policy with respect to information that can be associated with
                        or which relates to a person and/or could be used to identify a person (“Personal Data”) that we
                        collect from individuals that access or use our Zumba.com website and other websites we own and
                        administer (e.g., STRONGNation.com, Zumba-Cruise.com, etc.) (the “Sites”), including all of our
                        mobile application(s) or other ZUMBA products or services on any computer, mobile phone, tablet,
                        console or other device. "Non-Personal Data" as used in this Privacy Policy is therefore any
                        information that does not relate to a person and/or cannot be used to identify a person. When
                        you interact with us through the Sites, we may also collect Non-Personal Data. The limitations
                        and requirements in this Privacy Policy on our collection, use, disclosure, transfer and
                        storage/retention of Personal Data do not apply to Non-Personal Data. Our primary goal in
                        collecting your Personal Data and Non-Personal Data is to provide you with superior online
                        services as well as information about our fitness programs, products and services.</p>

                    <p>We take the privacy of your Personal Data very seriously. Because of that, we have created this
                        Privacy Policy. This Privacy Policy describes how we collect and use your Personal and
                        Non-Personal Data through a variety of digital means. By accessing or using our Sites, you
                        consent to this Privacy Policy. Provisions of this Privacy Policy may be changed, modified,
                        added or removed at any time and such changes will become effective immediately upon posting of
                        a new policy on the Sites. Your continued use of our products and services constitutes your
                        acceptance to this Privacy Policy and any updates thereto. This Privacy Policy is incorporated
                        into, and is subject to, our <a href="terminos_uso.html" class="morado">Terms of Use</a></p>

                    <h4 class="mb-2">WHAT INFORMATION ABOUT YOU DO WE COLLECT?</h4>
                    <p>You may visit and browse the Sites without providing Personal Data. However, this may limit your
                        ability to receive certain features, products or services from us.</p>

                    <p>Information you give us: We receive and store information you supply to us or permit us to
                        access. This information may include, but is not limited to, your name, image, gender, date of
                        birth, height, weight, lifestyle choices such as sleeping eating and exercise habits, email
                        address, billing address, mailing address, billing information, telephone number, credit card
                        number, credit card expiration date, shipping address, log-in name and password, social network
                        username, calendar, location (GPS) information, Usage Information (as defined below), and
                        electronic opt-in notifications that you authorize, including, but not limited to, third party
                        opt-in options.</p>

                    <p>Information automatically collected: We automatically receive and store certain types of
                        information regarding your interaction with, and use of, our products and services. For example,
                        as is common practice, we use "cookies" and similar technologies to obtain and determine certain
                        information when your browser accesses the Sites including, but not limited to, the Internet
                        Protocol (IP) address used to connect your computer to the internet, email address, browser type
                        and version, operating system and platform. For users of our mobile service(s), we automatically
                        receive your location when you use the service. We may also receive other information from your
                        mobile device, such as a device identifier. A device identifier is a small data file stored on
                        or associated with your mobile device, which uniquely identifies your mobile device. A device
                        identifier may be data stored in connection with the device hardware, data stored in connection
                        with the device's operating system or other software, or data sent to the device by us. We or
                        our third-party service providers may also use a variety of technologies that passively or
                        automatically collect information about how the Sites is accessed and used (“Usage
                        Information”), including your browser type, device type, operating system, application version,
                        the pages served to you, the pages accessed by you, the time you browse, preceding page views,
                        and your use of features or applications on the Sites. This statistical data helps us understand
                        what is interesting and relevant to users of the Sites so we can best tailor our content. We may
                        also use web beacons or pixel tags, which are transparent graphic images placed on a web page or
                        in an email and indicate that a page or email has been viewed or tell your browser to get
                        content from another server.</p>

                    <p>Information about you given to us by third parties: We may receive information about you from
                        third parties that feature our programs, products, services, or promotional offers if you opt-in
                        to receive information from us. You may also choose to participate in a third party application
                        or social media site through which you allow us to collect (or the third party to share)
                        information about you, including Usage Information and certain Personal and/or Non-Personal
                        Data.</p>

                    <p>We may also receive information about you from outside records from third parties, such as
                        marketing related or demographic information to enhance our ability to tailor our content,
                        products and services that we believe may be of interest to you. We may supplement the
                        information we collect about you through the Sites with such information from third parties in
                        order to enhance our ability to serve you, to tailor our content to you and/or to offer you
                        opportunities to purchase products or services that we believe may be of interest to you. If we
                        combine such data with information we collect through the Sites, such information is subject to
                        this Privacy Policy unless we have disclosed otherwise.</p>

                    <p>Children’s Privacy – We do not knowingly collect Personal Data from children under the age of
                        thirteen (13). If you are under the age of thirteen (13), please do not submit any Personal Data
                        through the Sites. We encourage parents and legal guardians to monitor their children’s Internet
                        usage and to help enforce our Privacy Policy by instructing their children never to provide
                        Personal Data through the Sites without their permission. If you have reason to believe that a
                        child under the age of 13 has provided Personal Data to us through the Sites, please email us at
                        privacy@zumba.com, and we will delete that information from our records.</p>

                    <h4 class="mb-2">WHAT ABOUT COOKIES?</h4>
                    <p>Cookies are alphanumeric identifiers that we transfer to your computer's hard drive through your
                        Web browser to enable our systems to recognize your browser and to provide features for using
                        our services. Cookies allow you to take full advantage of some of the features of our services
                        and the Sites, and we recommend that you leave them turned on.</p>

                    <h4 class="mb-2">WHAT KIND OF COOKIES DO WE USE?</h4>
                    <p>Cookies come in different varieties and our services may use several of those types. Broadly
                        speaking, we use one or more of the following categories of cookies:</p>

                    <p><b><u>Strictly Necessary Cookies</u></b> - These cookies are essential to enable you to navigate
                        the Sites and use their features. Without these cookies, services like the shopping cart cannot
                        be provided.</p>

                    <p><b><u>Performance Cookies</u></b> - These cookies collect information about how you use the Sites
                        or other services we provide — for instance, which webpages you go to most. This data may be
                        used to help optimize the Sites and our services and make it easier for you to navigate. These
                        cookies may also be used to let our affiliates know if you came to the Sites from an affiliate
                        and if your visit resulted in the use or purchase of a product or service from us, including
                        details of the product or service purchased. These cookies do not collect information that
                        identifies you. All information these cookies collect is aggregated and is therefore anonymous.
                    </p>

                    <p><b><u>Functionality Cookies</u></b> - These cookies allow us to remember choices you make while
                        browsing. For example, we may store your geographic location in a cookie to ensure that we show
                        you the Sites localized for your area. They may also be used to keep track of what featured
                        products or videos have been viewed to avoid repetition. The information these cookies collect
                        will not personally identify you, and they cannot track your browsing activity on third-party
                        websites.</p>

                    <p><b><u>Targeting Cookies</u></b> - These cookies are used to deliver advertisements more relevant
                        to you and your interests. They are also used to limit the number of times you see an
                        advertisement as well as help measure the effectiveness of the advertising campaign. They are
                        usually placed by advertising networks with our permission. They remember that you have visited
                        a website and this information is shared with other organizations such as advertisers. Quite
                        often targeting or advertising cookies will be linked to site functionality provided by the
                        other organization.</p>

                    <h4 class="mb-2">WHAT IF YOU DO NOT WANT COOKIES?</h4>
                    <p>The "help" portion of the toolbar on most browsers will tell you how to prevent your browser from
                        accepting new cookies, how to have the browser notify you when you receive a new cookie, or how
                        to disable cookies altogether. You can find out more about how your browser handles cookies by
                        visiting the links below:</p>
                    <ol>
                        <li><a rel="noopener" target="_blank"
                                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                                class="morado" style="color: #007bff;">Mozilla Firefox</a></li>
                        <li><a rel="noopener" target="_blank" href="https://support.apple.com/kb/PH21411?locale=en_US"
                                class="morado" style="color: #007bff;">Safari</a></li>
                        <li><a rel="noopener" target="_blank"
                                href="https://support.google.com/chrome/answer/95647?hl=en" class="morado" style="color: #007bff;">Google
                                Chrome</a></li>
                        <li><a rel="noopener" target="_blank"
                                href="https://privacy.microsoft.com/en-us/privacystatement#maincookiessimilartechnologiesmodule"
                                class="morado" style="color: #007bff;">Internet Explorer</a></li>
                        <li><a rel="noopener" target="_blank"
                                href="http://www.opera.com/help/tutorials/security/cookies/" class="morado" style="color: #007bff;">Opera</a>
                        </li>
                        <li><a rel="noopener" target="_blank" href="https://www.adobe.com/privacy/cookies.html"
                                class="morado" style="color: #007bff;">Adobe (Flash Cookies)</a></li>
                    </ol>
                    <h4 class="mb-2">HOW WE USE PERSONAL DATA?</h4>
                    <p>Except as otherwise provided in this Privacy Policy, we will not provide any of your Personal
                        Data to any third parties without your specific consent. We may share Non-Personal Data, such as
                        aggregate user statistics, Usage Information, and demographic information with third parties.
                    </p>
                    <p>The Personal Data we collect when you create a profile on the Sites will be used for
                        identification and authentication purposes across our services, so you can use the same account
                        details to log in from any location and on any device. The global single sign-on will allow our
                        systems to identify you wherever you are in the world, meaning you will not need to register
                        with us again if you are signing-in from a different country. It is necessary to process your
                        Personal Data in this way for the performance of a contract between you and Zumba.</p>

                    <p>In addition, we will create a profile for you with the information needed to provide you with our
                        products or services. The Personal Data we collect from the other sources listed above will be
                        used in the format it is received, or, where appropriate, will be combined with Personal Data we
                        obtain from other internal or external sources. We use and analyze this Personal Data where
                        necessary for the purposes detailed below:</p>

                    <ol>
                        <li><span style="text-decoration: underline"> Registering for a Zumba<sup>®</sup> or STRONG
                                Nation<sup>™</sup> Instructor Training Course: </span> we use relevant Personal Data
                            (including your name, address email address, and payment details) in connection with your
                            registration to attend a Zumba<sup>®</sup> and/or STRONG Nation<sup>™</sup> Instructor
                            Training Course, and to notify you of the status of the training course and any details
                            related thereto. It is necessary to process your Personal Data in this way for the
                            performance of a contract between you and Zumba.</li>
                        <li><span style="text-decoration: underline"> Becoming a Licensed Zumba<sup>®</sup> and/or
                                STRONG Nation<sup>™</sup> Instructor: </span> by becoming a licensed Zumba<sup>®</sup>
                            and/or STRONG Nation<sup>™</sup> Instructor (including by joining our Zumba<sup>®</sup>
                            Instructor Network (ZIN<sup>™</sup>) and/or our STRONG Nation<sup>™</sup> SYNC Network
                            (SYNC)), we use your Personal Data to communicate with you from time-to-time by email, mail,
                            SMS, phone and/or other electronic means concerning your license(s), the status of your
                            license(s), and your benefits as a licensee. These communications include transactional
                            communications concerning your license, and/or products or services you may purchase;
                            offers, promotions and discounts available to you as a licensee or former licensee; and
                            information, offers, promotions, and discounts about our programs, products, services, and
                            events. It is necessary to process your Personal Data in this way for the performance of a
                            contract between you and Zumba.</li>
                        <li>Processing Your Order of Products/Services Through our Sites: we use relevant Personal Data
                            (including your name, address email address, and payment details) to process and deliver
                            your order, and to notify you of the status of your order. It is necessary to process your
                            Personal Data in this way for the performance of a contract between you and Zumba.</li>
                        <li><span style="text-decoration: underline"> Improving our Products, Programs and Services:
                            </span> we will use your Personal Data to build a profile on you in order to understand how
                            you use our products, programs and services, to develop more interesting and relevant
                            products, programs and services, to improve our Sites, as well as to personalize the
                            products, programs and services we offer you. We process your Personal Data in this way as
                            it is necessary for the purposes of our legitimate interests in providing better products
                            and services for you and our other customers.</li>
                        <li>To Provide Customer Support: if you contact our Customer Service (or vice versa), we will
                            use Personal Data to process your request and provide you with the best service possible. We
                            will process your Personal Data in this way if it is necessary for the performance of a
                            contract between you and Zumba or if it is required for us to comply with any legal
                            obligations. If it is not necessary to process your Personal Data for either of these
                            reasons we will process it as it is necessary for the purposes of our legitimate interests
                            in ensuring we can provide the best service possible.</li>
                        <li>Conducting Analytics: we will use the Personal Data we hold about you (as well as
                            pseudonymized or anonymized information generated from your Personal Data) to carry out
                            analysis and research. We carry out all such analysis and research on the basis that it is
                            necessary for the purposes of our legitimate interests in understanding our customers and
                            ensuring that our products, programs and services meet the needs of our customers and
                            licensees. We do this in order to (i) learn more about our customers and licensees and their
                            preferences; (ii) to identify patterns and trends amongst our customers and licensees; (iii)
                            to enhance user experience on our Sites; (iv) to provide information, content and offerings
                            tailored to our customers' and licensees’ needs; (v) for general research and statistical
                            purposes; (vi) to develop new products, programs and services; (vii) to monitor performance
                            of our products, programs and services and/or improve our technology; (viii) to be able to
                            send you personalized marketing messages; and (ix) to display online ads to you.</li>
                        <li>Analyzing our Business: we will use your Personal Data (including by anonymizing and
                            aggregating it with other data) for sales, supply chain, anti-fraud, and financial analysis
                            purposes, to determine how we are performing and where improvements can be made and where
                            necessary for financial reporting purposes. This is necessary for the purposes of our
                            legitimate interests in understanding how our business is performing, and considering how to
                            improve our business performance.</li>
                    </ol>

                    <p><b><u>Sharing Information</u></b> – We may provide your Personal Data (i) to our subsidiaries,
                        including Zumba Fitness - Europe B.V., which is located outside of the U.S.; (ii) to our service
                        providers who assist us in conducting our business, including third parties involved in credit
                        card processing, shipping, data management, email distribution, market research, information
                        analysis, and promotions management; (iii) upon governmental request, in response to a court
                        order, when required by law, to enforce our policies, or to protect our or others’ rights,
                        property or safety; (iv) to prevent illegal uses of our products or services or violations of
                        our Terms of Use, or to defend ourselves against third party claims; (v) to companies assisting
                        us in fraud protection or investigations; and (vi) to a third party as part of our business
                        assets in the event of a sale of all or a portion of our company.</p>

                    <p><b><u>Public Sharing</u></b> – While on the Sites, you may have the opportunity to opt-in to
                        receive information and/or marketing offers from someone else, or to otherwise consent to the
                        sharing of your information with a third party, including social networking sites such as
                        Facebook or Twitter. If you agree to have your Personal Data shared with a third party, your
                        Personal Data will be disclosed to the third party subject to the privacy policy and business
                        practices of that third party. You may also choose to share certain Personal Data on the Sites
                        including, but not limited to, your activities on other platforms, such as social media networks
                        like Facebook and Twitter. Please read the privacy policies of all such third party websites.
                        Any Personal Data that share publicly may be used by us for promotional purposes.</p>

                    <p>We may send you carefully selected offers from other businesses. When we do this, we do not give
                        that business your name and address. If you do not want to receive such offers, please indicate
                        that you are opting out of such notification in your user profile. Other than as set out above,
                        you will receive notice when information about you might go to third parties, and you will have
                        an opportunity to choose not to share the information.</p>

                    <h4>INTEREST-BASED ADS</h4>
                    <p>We may partner with third-party service providers to serve ads on our behalf on non-affiliated
                        third party websites. Some of those ads may be personalized, meaning that they are intended to
                        be relevant to you based on information we or the online advertising network serving the ad
                        collect about your online activities. For example, anonymous information collected across
                        multiple websites that you visit over time may enable the ad network to predict your preferences
                        and show you ads that are most likely to be of interest to you. Please note that we do not share
                        any information that identifies you personally with the third party service providers who serve
                        ads on our behalf.</p>

                        <p>To opt out of these types of ads, please visit the Network Advertising Initiative for information about how to opt-out of interest-based advertising by their members. See <a rel="noopener" target="_blank" href="http://www.networkadvertising.org" class="morado" style="color: #007bff;">http://www.networkadvertising.org</a> for general information about the NAI and <a rel="noopener" target="_blank" href="http://www.networkadvertising.org/managing/opt_out.asp" class="morado" style="color: #007bff;">http://www.networkadvertising.org/managing/opt_out.asp</a> for the opt-out page. You may also visit <a rel="noopener" target="_blank" href="http://www.aboutads.info/consumers/" class="morado" style="color: #007bff;">http://www.aboutads.info/consumers/</a> to learn about online behavioral advertising and how to opt-out from online behavioral ads served by some or all participating companies. Note that if you opt-out of these types of ads, you may still see our ads at other websites, but the ads will not be tailored using third party tracking technologies associated with our Sites.</p>

                    <h4 class="mb-2">MOBILE MESSAGING TERMS</h4>
                    <p>
                        When you provide us with your mobile phone number, you are expressly agreeing that we may send
                        you text messages (including SMS) to that phone number. You also agree to pay the charges to
                        your wireless bill or to have them deducted from your prepaid balance for this service. You
                        acknowledge that you are thirteen years of age or older and you have the consent of the wireless
                        account holder. We will send you up to ten messages per month. Message and data rates apply.
                    </p>

                    <p>Text STOP to the applicable code at any time to cancel and text HELP for help. You agree to
                        receive a final text message confirming your opt-out. Consent is not required as a condition of
                        purchasing any goods or services. You agree to notify us of any changes to your mobile number
                        and update your information for us. Your carrier may prohibit or restrict certain mobile
                        features and certain mobile features may be incompatible with your carrier or mobile device.
                        Contact your carrier with questions regarding these issues.</p>

                    <p>We reserve the right to modify these Mobile Messaging Terms by posting new versions on this
                        website as well as the right to provide additional notice of significant changes. Your continued
                        use of the mobile messaging service following such changes constitutes your acceptance of those
                        changes.</p>

                    <h4 class="mb-2">HOW WE STORE PERSONAL DATA</h4>
                    <p>We may store Personal Data itself or such information may be stored by third parties to whom we
                        have transferred it in accordance with this Privacy Policy. We take what we believe to be
                        reasonable steps to protect the Personal Data we collect from loss, misuse, unauthorized use,
                        access, inadvertent disclosure, alteration and destruction. However, no network, server,
                        database or Internet or e-mail transmission is ever fully secure or error free. Therefore, you
                        should take special care in deciding what information you send to us electronically. Please keep
                        this in mind when disclosing any Personal Data.</p>

                    <h4 class="mb-2">YOUR RIGHTS, CHOICE AND ACCESS</h4>
                    <p>You may direct us not to share your Personal Data with third parties, not to use your Personal
                        Data to provide you with information or offers, or not to send you communications by: (i)
                        contacting us at <a href="mailto:privacy@zumba.com" class="morado" style="color: #007bff;">privacy@zumba.com</a>; or (iii) following the removal instructions in the
                        communication you receive. Please understand that if you are a licensed Zumba® Instructor or if
                        you have purchased certain products or services from us through the Sites, we must be able to
                        communicate with you concerning your license, your licensed status, your benefits as a licensee,
                        and any purchases you made through our Sites.</p>

                    <p>You have the right to request access to the Personal Data we have of you. You can do this by
                        contacting us at <a href="mailto:privacy@zumba.com" class="morado" style="color: #007bff;">privacy@zumba.com</a>. In order to comply with your request, we may ask you to
                        verify your identity. If you wish to modify, verify, correct, or update any of your Personal
                        Data collected through the Sites, you may contact us at privacy@zumba.com. IF AT ANY POINT YOU
                        WISH FOR US TO DELETE PERSONAL DATA ABOUT YOU, YOU CAN CONTACT US AS <a href="mailto:privacy@zumba.com" class="morado" style="color: #007bff;">privacy@zumba.com</a>. Please
                        understand that if you are a licensed Zumba® and/or STRONG Nation™ Instructor and you request
                        that your Personal Data be deleted, this will require us to remove your license and you will no
                        longer be permitted to teach Zumba® and/or STRONG Nation™ classes.</p>

                    <p>In accordance with our routine record keeping, we may delete certain records that contain
                        Personal Data you have submitted through the Sites. We are under no obligation to store such
                        Personal Data indefinitely and disclaim any liability arising out of, or related to, the
                        destruction of such Personal Data.</p>

                    <h4 class="mb-2">INTERNATIONAL PRIVACY LAWS</h4>
                    <p>If you are visiting the Sites from outside the United States, please be aware that you are
                        sending information (including Personal Data) to the United States where our servers are
                        located. That information may then be transferred within the United States or back out of the
                        United States to other countries outside of your country of residence, depending on the type of
                        information and how it is stored by us. These countries (including the United States) may not
                        necessarily have data protection laws as comprehensive or protective as those in your country of
                        residence; however, our collection, storage and use of your Personal Data will at all times
                        continue to be governed by this Privacy Policy.</p>

                        <h4 class="mb-2">CALIFORNIA DO NOT TRACK NOTICE</h4>
                    <p>We do not track our customers over time and across third party websites to provide targeted
                    advertising and therefore do not respond to Do Not Track (DNT) signals. However, some third party
                    websites do keep track of your browsing activities when they serve you content, which enables them
                    to tailor what they present to you. If you are visiting such websites, Safari 5.1+, Internet
                    Explorer 9+, Firefox 5+ and Chrome allow you to set the DNT signal on your browser so that third
                    parties (particularly advertisers) know you do not want to be tracked.</p>

                    <h4 class="mb-2">WHAT IF I LINK TO A THIRD-PARTY WEBSITE FROM THE SITES?</h4>
                    <p>When you click links and/or banners that take you to third party websites, you will be subject to
                        the third parties' privacy policies. We urge you to read and understand the privacy policies of
                        such websites, as their practices may differ from ours.</p>

                    <h4 class="mb-2">HOW DO WE PROTECT PERSONAL DATA?</h4>
                    <p>We undertake reasonable measures, including using encryption technology, to keep your Personal
                        Data safe. However, no electronic data transmission or storage of information can be guaranteed
                        to be 100% secure. Please note that we cannot ensure or warrant the security of any information
                        you transmit to us. It is important for you to protect against unauthorized access to your
                        password and other personal information and to your computer. Be sure to sign off when finished
                        using a shared computer.</p>

                    <h4 class="mb-2">WHAT IF I HAVE QUESTIONS?</h4>
                    <p>If you have any questions regarding this Privacy Policy, please send us an email to
                        <a href="mailto:privacy@zumba.com" class="morado">privacy@zumba.com</a>.</p>
                </div>

              </div>
          </div>
      </section>
  
    
    
  
      
  
    </div>
  </template>
  
  <script>
  //import metaList from "../../../helpers/metaSocialNetworks";
  export default {
    name:'Politicas',
      /*metaInfo() {
          return {
              title: "Zumba Wear®",
              meta: metaList
          }
      },*/

      created(){
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);
      }
  }
  </script>
  
  <style>
    .alturaBase {
        margin-top: 10%
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .imagen_banner-02 {
        background-repeat: no-repeat;
        background-position: 50%;
        -ms-flex-line-pack: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
        -ms-flex-pack: center;
        align-content: center;
        display: flex;
    }
    .text-conten-img {
        
        left: 10%;
        padding: .25rem .75rem;
        color: #fff!important;
        height: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color_blanco {
        color: #fff !important;
    }
    .superbig {
        font-size: 3.8rem;
    }
    @media (max-width: 860px) {
        .alturaBase {
            margin-top: 20%
        }
    }

    @media (max-width: 540px) {
        .alturaBase {
            margin-top: 25%
        }
        
    }


    @media (max-width: 414px) {
        .alturaBase {
            margin-top: 25%
        }
    }
  </style>
  