<template>
    <div>
        <input type="hidden" :value="getRouteName">
        <NavLinks/>

        <transition style="display:none" name="fade" mode="out-in">
            <router-view :key="$route.fullPath"/>
        </transition>

        <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from './template/Footer.vue';
import NavLinks from './template/NavLinks.vue';

export default {
    name: 'HomeView',
    components: {
        Footer,
        NavLinks
    },
    data(){
        return {
           
           
        }
    },
    methods:{

        goHome() {
            if(this.$route.name !== 'home') {
                this.showUsrOpBar = false;
                this.$router.push({ name :'home',params:{}});
            }
        },

    },
    mounted() {
        if(localStorage.getItem('loadData') === 0){
          this.goHome();
        }
        localStorage.setItem('loadData',1);

    },
    computed: {
        getRouteName() 
        {
            if(this.$route.name === 'principal') {
              this.goHome();
            }

            if(this.$route.name === 'home'){
                this.Search     = '';
                this.showMenuP  = false;
            }

            var splitData = this.$router.currentRoute.path.split('/')

            if(splitData.length>0) 
            {
              if(splitData[1] !== 'profile') {
                //this.bus.$emit("show_Menu",false);
              }

            }else {
                this.showUsrOpBar = false;
            }

            return this.$route.name;
        },

        getRouteProdcut() {
            if(this.$route.name === 'products'){
               this.IsRouterProduct = true;
            }else{
               this.IsRouterProduct = false;
            }
            return '';
        },
    }
}
</script>
