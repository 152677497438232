<template>
    <nav aria-label="prodcuts navigation">         
        <b-pagination class="pagination grid-pagination" first-number last-number
            v-model="CurrentPage" :total-rows="Validate" :perPage="FiltroP.Entrada" size="sm">
        
            <template #prev-text><i class="far fa-arrow-left"></i></template>
            <template #next-text><i class="far fa-arrow-right"></i></template>

        </b-pagination>
    </nav>
</template>
<!-- 
    <nav aria-label="prodcuts navigation">
        <ul class="pagination grid-pagination">
            <li class="page-item"><a class="page-link" href="#"><i
                        class="far fa-arrow-left"></i></a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item active"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#"><i
                        class="far fa-arrow-right"></i></a></li>
        </ul>
    </nav> 
-->
<script>

export default {
    name: "CPagina",
    props: ['Filtro'],
    data() {
        return {
            CurrentPage: 1,
            FiltroP:{
                Pagina: 1,
                Entrada: 10,
                TotalItem: '',
            }
        }
    },
    methods: {
    },
    created() {
    },
    computed: {
        Validate() 
        {
            if (this.Filtro != undefined)
            {
                // CANTIDAD DE REGISTROS POR PAGINA
                if (this.Filtro.Entrada != undefined) {
                    this.FiltroP.Entrada = this.Filtro.Entrada;
                }
                // TOTAL DE REGISTROS
                if (this.Filtro.TotalItem != undefined) {
                    this.FiltroP.TotalItem = this.Filtro.TotalItem;
                }
                // NUMERO DE PAGINA ACTUAL
                if (this.Filtro.Pagina != undefined) {
                    this.CurrentPage = this.Filtro.Pagina;
                }
            }
            
            return this.FiltroP.TotalItem;
        }
    },
    watch: { 
        // RECIBE LA PAGINA ACTUAL CLICKEADA
        CurrentPage(newPage)
        {
            this.Filtro.Pagina = newPage;
            // EJECUTA EL EMIT DEL @PAGINA, QUE SE ENCUENTRA EN EL COMPONENTE CPAGINA EN EL CLIST PARA QUE REALICE EL FILTRADO
            this.$emit('Pagina');
        }
    }
}
</script>