<template>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-end">
            <input type="hidden" :value="NameBtnSave">
            <slot name="btnsaveextra"></slot>

            <template v-if="oBtnSave.ShowBtnCancel">
                <button type="button" class="btn btn-secondary me-1" :disabled="oBtnSave.DisableBtn" @click="Clean" v-if="oBtnSave.IsModal">
                    {{oBtnSave.TxtBtnCancel}}
                </button>
                
                <button type="button" class="btn btn-secondary me-1" :disabled="oBtnSave.DisableBtn" @click="Return" v-else >
                    {{oBtnSave.TxtBtnCancel}}
                </button>
            </template>

            
            <button v-if="oBtnSave.ShowBtnSave" :disabled="oBtnSave.DisableBtn" type="button" class="btn btn-primary" @click="Saved">
                <i v-show="oBtnSave.DisableBtn && oBtnSave.ShowSpinner" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                <i v-show="oBtnSave.DisableBtn !== true" :class="oBtnSave.IconoSave"></i>
                {{oBtnSave.TxtBtnSave}}
            </button>
        </div>
    </div>
</template>

<script>

export default {
  components: { },
    name:'CBtnSave',
    props:['poBtnSave'],
    data() {
        return {
            oBtnSave:{
                Toast: 0,
                IsModal: true,
                ReturnRoute: '',
                ShowBtnSave: true,
                ShowBtnCancel: true,
                DisableBtn: false,
                TxtBtnSave: 'Guardar',
                TxtBtnCancel: 'Cancelar',
                ToastMsgSuccess: 'Información guardada',
                ToastMsgWarning: 'Complete los campos',
                EmitSeccion: '',
                ShowSpinner: true,
                ActiveEmitReturn: false,
                IconoSave: 'fa fa-plus-circle',
            }
        }
    },
    methods:{
        Saved()
        {
            this.bus.$emit('Save_'+this.oBtnSave.EmitSeccion);
        },
        Return()
        {
            if(this.oBtnSave.ActiveEmitReturn){
                this.bus.$emit('EmitReturn');
            }
            else{
                this.$router.push({name:this.oBtnSave.ReturnRoute});
            }
        },
        Clean()
        {
            //this.bus.$emit('Limpiar_'+this.oBtnSave.EmitSeccion);
            this.bus.$emit('CloseModal_'+this.oBtnSave.EmitSeccion);
        },
        RunAlert()
        {
            if(this.oBtnSave.Toast==1){
                this.$toast.success(this.oBtnSave.ToastMsgSuccess);
            }
            else if(this.oBtnSave.Toast==2){
                this.$toast.warning(this.oBtnSave.ToastMsgWarning);
            }
            else if(this.oBtnSave.Toast==3){
                this.$toast.error(this.poBtnSave.toastmsg);
            }

            if(this.poBtnSave.Toast != undefined){
                this.poBtnSave.Toast = 0;
            }
        }
    },
    created() {
    },
    mounted(){
        this.bus.$off('RunAlerts_'+this.oBtnSave.EmitSeccion);
        this.bus.$on('RunAlerts_'+this.oBtnSave.EmitSeccion,(val)=>
        {
            this.oBtnSave.Toast = val;
            this.RunAlert();
        });
    },
    computed: {
        NameBtnSave()
        {
            if(this.poBtnSave!=undefined)
            {
                if(this.poBtnSave.IsModal != undefined){
                    this.oBtnSave.IsModal = this.poBtnSave.IsModal;
                }
                if(this.poBtnSave.ReturnRoute != undefined){
                    this.oBtnSave.ReturnRoute = this.poBtnSave.ReturnRoute;
                }
                if(this.poBtnSave.ShowBtnSave != undefined){
                    this.oBtnSave.ShowBtnSave = this.poBtnSave.ShowBtnSave;
                }
                if(this.poBtnSave.ShowBtnCancel != undefined){
                    this.oBtnSave.ShowBtnCancel = this.poBtnSave.ShowBtnCancel;
                }
                if(this.poBtnSave.DisableBtn != undefined){
                    this.oBtnSave.DisableBtn = this.poBtnSave.DisableBtn;
                }
                if(this.poBtnSave.TxtBtnSave != undefined){
                    this.oBtnSave.TxtBtnSave = this.poBtnSave.TxtBtnSave;
                }
                if(this.poBtnSave.TxtBtnCancel != undefined){
                    this.oBtnSave.TxtBtnCancel = this.poBtnSave.TxtBtnCancel;
                }
                if(this.poBtnSave.ToastMsgSuccess != undefined){
                    this.oBtnSave.ToastMsgSuccess = this.poBtnSave.ToastMsgSuccess;
                }
                if(this.poBtnSave.ToastMsgWarning != undefined){
                    this.oBtnSave.ToastMsgWarning = this.poBtnSave.ToastMsgWarning;
                }
                if(this.poBtnSave.EmitSeccion != undefined){
                    this.oBtnSave.EmitSeccion = this.poBtnSave.EmitSeccion;
                }
                if(this.poBtnSave.Toast != undefined){
                    this.oBtnSave.Toast = this.poBtnSave.Toast;
                }
                if(this.poBtnSave.ShowSpinner != undefined){
                    this.oBtnSave.ShowSpinner = this.poBtnSave.ShowSpinner;
                }
                if(this.poBtnSave.ActiveEmitReturn != undefined){
                    this.oBtnSave.ActiveEmitReturn = this.poBtnSave.ActiveEmitReturn;
                }
                if(this.poBtnSave.IconoSave != undefined){
                    this.oBtnSave.IconoSave = this.poBtnSave.IconoSave;
                }

                this.RunAlert();
            }
            
            return this.oBtnSave.TxtBtnSave;
        }  
    },
}

</script>