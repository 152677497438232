<template>
  <main style="margin-top: 76px;">
        <div class="container path pt-3 mb-4">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                            <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a class="text-decoration-none text-morado fw-medium" @click="goBolsa()" style="cursor: pointer;">Mi Bolsa</a>
                    </li>
                    <li class="breadcrumb-item active fw-medium" aria-current="page">Información de Envío</li>
                </ol>
            </nav>
        </div>
        <hr>
        <section class="container mb-4">
            <div class="row">
                <div class="col-lg-8 mb-4">
                    <h2>Elige tu dirección de envio</h2>
                    <div class="d-flex justify-content-between align-items-end">
                    </div>
                </div>

                <!-- AREA DE SELECCION-->
                <div v-if="!editInformacion" class="col-12 col-md-7 col-lg-8">
                    <CLoader :pConfigLoad="ConfigLoad">
                        <template slot="BodyFormLoad">
                            <div v-if="listDireccionesEnvio.length > 0" class="products-added-list">
                                <div v-for="(item,idex) in listDireccionesEnvio" :key="idex" class="product-added">
                                    <div class="me-4">
                                        <input type="radio" :id="'dir_'+item.IdCliDireccionEnvio" :value="item.IdCliDireccionEnvio" v-model="IdDireccionEnvio" />
                                    </div>
                                    <div class="details">
                                        <h3 class="name"> 
                                            <i v-if="item.TipoDireccion == 'Trabajo'" class="fal fa-suitcase fa-lg"></i>
                                            <i v-if="item.TipoDireccion == 'Casa'" class="fal fa-house fa-lg"></i>
                                            {{ item.Nombre }} </h3>
                                        <p class="size-color">Calle {{ item.Calle }}, NoExt. {{ item.NoExt }}, 
                                        <span v-if="item.NoInt != undefined && item.NoInt != null">No Int.{{ item.NoInt }}</span> Cruzamientos: {{ item.Cruzamiento1 }} 
                                        <span v-if="item.Cruzamiento2 != undefined && item.Cruzamiento2 != null">y {{ item.Cruzamiento2 }},</span> Colonia: {{ item.Colonia }},
                                        CP: {{ item.CodigoPostal }}, {{ item.Municipio }}, {{ item.Estado }}, {{ item.Pais }} 
                                        <br> Referencias: {{ item.Referencias }}
                                        <br>Telefono: {{ item.Telefono }}
                                        </p>
                                        
                                        <p class="price"></p>
                                        <div class="cantidad">
                                            <div class="d-flex align-items-center me-1">
                                                <a class="text-morado" style="cursor: pointer;" @click="recoveryDireccion(item)">Editar</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="d-flex align-items-center me-1 mb-4">
                                        <a class="text-morado" style="cursor: pointer;" @click="addDireccion()">Agregar una nueva dirección</a>
                                    </div>
                                </div>
                            </div>

                            <div v-if="listDireccionesEnvio.length == 0 && ConfigLoad.ShowLoader == false" class="products-added-list">
                                <br>
                                <div class="text-center">
                                    <h3 class="text-morado">No cuenta con direcciones para envio!</h3>
                                    <h6>¿Desea <span class="text-morado" style="cursor: pointer;" @click="addDireccion()"><u>agregar</u></span> una nueva dirección?</h6>
                                    
                                </div>
                                <br>
                            </div>
                        </template>
                    </CLoader>
                    
                </div>

                <div v-if="editInformacion" class="col-12 col-md-7 col-lg-8">
                    <CLoader :pConfigLoad="ConfigLoadForm">
                        <template slot="BodyFormLoad">
                            <div class="container-fluid">
                                <form id="FormClientedireccionenvio" role="form" method="post" autocomplete="off" onSubmit="return false">
                                    
                                    
                                    <div class="form-group row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label class="form-label" for="Nombre">Nombre</label>
                                            <input type="text" v-model="objClientedireccionenvio.Nombre" class="form-control" id="Nombre" placeholder="Ingresar Nombre" />
                                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label class="form-label" for="TipoDireccion">Tipo Direccion</label>
                                            <br>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Casa" v-model="objClientedireccionenvio.TipoDireccion">
                                                <label class="form-check-label" for="inlineRadio1"><i class="fal fa-house fa-lg"></i> Casa</label>
                                            </div>
                                            
                                    
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Trabajo" v-model="objClientedireccionenvio.TipoDireccion">
                                                <label class="form-check-label" for="inlineRadio2"><i class="fal fa-suitcase fa-lg"></i> Trabajo</label>
                                            </div>

                                            <CValidation v-if="this.errorvalidacion.TipoDireccion" :Mensaje="'*'+errorvalidacion.TipoDireccion[0]"/>
                                        </div>

                                    </div>
                                    
                                    <div class="form-group row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Calle">Calle</label>
                                            <input type="text" v-model="objClientedireccionenvio.Calle" class="form-control" id="Calle" placeholder="Ingresar Calle" />
                                            <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*'+errorvalidacion.Calle[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="NoExt">No Ext</label>
                                            <input type="text" v-model="objClientedireccionenvio.NoExt" class="form-control" id="NoExt" placeholder="Ingresar NoExt" />
                                            <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*'+errorvalidacion.NoExt[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="NoInt">No Int</label>
                                            <input type="text" v-model="objClientedireccionenvio.NoInt" class="form-control" id="NoInt" placeholder="Ingresar NoInt" />
                                            <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*'+errorvalidacion.NoInt[0]"/>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="form-group row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label class="form-label" for="Cruzamiento1">Cruzamiento 1</label>
                                            <input type="text" v-model="objClientedireccionenvio.Cruzamiento1" class="form-control" id="Cruzamiento1" placeholder="Ingresar Cruzamiento1" />
                                            <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*'+errorvalidacion.Cruzamiento1[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label class="form-label" for="Cruzamiento2">Cruzamiento 2</label>
                                            <input type="text" v-model="objClientedireccionenvio.Cruzamiento2" class="form-control" id="Cruzamiento2" placeholder="Ingresar Cruzamiento2" />
                                            <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*'+errorvalidacion.Cruzamiento2[0]"/>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="form-group row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Colonia">Colonia</label>
                                            <input type="text" v-model="objClientedireccionenvio.Colonia" class="form-control" id="Colonia" placeholder="Ingresar Colonia" />
                                            <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*'+errorvalidacion.Colonia[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="CodigoPostal">CodigoPostal</label>
                                            <input type="text" v-model="objClientedireccionenvio.CodigoPostal" class="form-control" id="CodigoPostal" placeholder="Ingresar CodigoPostal" />
                                            <CValidation v-if="this.errorvalidacion.CodigoPostal" :Mensaje="'*'+errorvalidacion.CodigoPostal[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Municipio">Municipio</label>
                                            <input type="text" v-model="objClientedireccionenvio.Municipio" class="form-control" id="Municipio" placeholder="Ingresar Municipio" />
                                            <CValidation v-if="this.errorvalidacion.Municipio" :Mensaje="'*'+errorvalidacion.Municipio[0]"/>
                                        </div>
                                    </div>
                                    
                                  
                                    
                                    <div class="form-group row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Estado">Estado</label>
                                            <input type="text" v-model="objClientedireccionenvio.Estado" class="form-control" id="Estado" placeholder="Ingresar Estado" />
                                            <CValidation v-if="this.errorvalidacion.Estado" :Mensaje="'*'+errorvalidacion.Estado[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Pais">Pais</label>
                                            <input type="text" v-model="objClientedireccionenvio.Pais" class="form-control" id="Pais" placeholder="Ingresar Pais" />
                                            <CValidation v-if="this.errorvalidacion.Pais" :Mensaje="'*'+errorvalidacion.Pais[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                            <label class="form-label" for="Telefono">Telefono</label>
                                            <input type="text" v-model="objClientedireccionenvio.Telefono" class="form-control" id="Telefono" placeholder="Ingresar Telefono" />
                                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*'+errorvalidacion.Telefono[0]"/>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="form-group row mt-2">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <label class="form-label" for="Referencias">Referencias</label>
                                            <textarea v-model="objClientedireccionenvio.Referencias" class="form-control" rows="3" id="Referencias" placeholder="Ingresar Referencias" />
                                            <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*'+errorvalidacion.Referencias[0]"/>
                                        </div>
                                    </div>
                                    
                                    <br>
                                    <CBtnSave :poBtnSave="oBtnSave" />

                                    <br>
                                </form>
                            </div>
                        </template>
                    </CLoader>
                </div>

                <!-- RESUMEN DEL PEDIDO -->
                <div class="col-12 col-md-5 col-lg-4">
                    <div class="summary">
                        <h5 class="text-center fs-4 mb-3">Pedido</h5>
                        
                        <div class="total-price-breakdown mt-2 mb-3">
                            <h5 class="mb-2">Detalle de compra</h5>

                            <div class="section">
                                <span class="title">Productos:</span>
                                <span>{{ $formatNumber(objPedido.Subtotal,'$') }}</span>
                            </div>
                            
                            <div class="section">
                                <span class="title">Descuentos:</span>
                                <span>{{ $formatNumber(objPedido.Descuento,'$') }}</span>
                            </div>

                            <div class="section">
                                <span class="title">Envío:</span>
                                <span>{{ $formatNumber(objPedido.CostoEnvio,'$') }}</span>
                            </div>
                            <div v-if="objPedido.labelEnvio != '' && objPedido.labelEnvio != undefined" class="section">
                                <small>{{ objPedido.labelEnvio }}</small>
                            </div>
                            <div class="total">
                                <span>TOTAL</span>
                                <span>{{ $formatNumber(objPedido.GranTotal,'$') }}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button @click="goPagarPedido()" class="btn btn-primary w-100">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import CLoader from "@/components/CLoader.vue";
import CBtnSave from "@/components/CBtnSave.vue";
import CValidation from '@/components/CValidation.vue';

const EmitSeccion = 'SeccionShopDirCliente';
export default {
    name: 'ModoEnvio',
    components:{
        CLoader,
        CBtnSave,
        CValidation
    },

    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },

            listDireccionesEnvio: [],
            objPedido:{
                IdMetodoEnvio: 0,
                IdCuponDescuento: 0,
                IdDescMayorista: 0,

                labelEnvio: '',
                Subtotal: 0,
                Descuento: 0, // VALOR DESCUENTO
                CostoEnvio: 0,
                GranTotal: 0
            },
            IdDireccionEnvio: 0,
            editInformacion: false,

            ConfigLoadForm:{
                ShowLoader: true,
                ClassLoad: false,
            },

            oBtnSave:{
                Toast: 0,
                IsModal: false,
                DisableBtn: false,
                ActiveEmitReturn: true,
                ReturnRoute: '',
                EmitSeccion: EmitSeccion,
            },

            objClientedireccionenvio: {
				IdCliDireccionEnvio: 0,
				IdCliente: '',
				TipoDireccion: '',
				Nombre: '',
				Calle: '',
				NoExt: '',
				NoInt: '',
				Cruzamiento1: '',
				Cruzamiento2: '',
				Colonia: '',
				CodigoPostal: '',
				Estado: '',
				Municipio: '',
				Pais: '',
				Telefono: '',
				Referencias: '',
				RfcCliente: '',
            },
            errorvalidacion: [],
        };
    },

    created() {
        this.bus.$emit('cambiaLogoNegro');
        this.bus.$off('Save_'+EmitSeccion);
        this.bus.$off('EmitReturn');

        let carrito = JSON.parse(localStorage.getItem('listCarrito'));
        if(carrito.length == 0){
            this.$router.push({name:'bolsa'});
        }

    },

    mounted() {
        window.scrollTo(0, 0);

        this.bus.$on('Save_'+EmitSeccion,()=>
        {
            this.Guardar();
        });

        this.bus.$on('EmitReturn',()=>
        {
            this.Regresar();
        });

        this.getDireccionesEnvio();


        // OBJETO PEDIDO
        if(localStorage.getItem('orden') != undefined && localStorage.getItem('orden') != null) {

            this.objPedido = JSON.parse(localStorage.getItem('orden'));

            if(this.objPedido.IdMetodoEnvio <= 0) {
                this.$router.push({name:'bolsa'});
            }

            if(this.objPedido.IdDireccionEnvio != undefined && this.objPedido.IdDireccionEnvio != null && parseInt(this.objPedido.IdDireccionEnvio) > 0) {
                this.IdDireccionEnvio = this.objPedido.IdDireccionEnvio;
            }
            
        }
    },

    methods: {
        goHome() {
            this.$router.push({name:'principal',params:{} });
        },

        goBolsa() {
            this.$router.push({name:'bolsa',params:{}});
        },

        goPagarPedido() {
            if(this.IdDireccionEnvio > 0) {
                this.objPedido.IdDireccionEnvio = this.IdDireccionEnvio;
                localStorage.setItem('orden', JSON.stringify(this.objPedido));
                this.$router.push({name:'pago',params:{}});
                
            }else {
                this.$toast.warning('Necesita seleccionar una direccion');
            }
        },

        addDireccion() {
            this.editInformacion = true;
            this.Limpiar();
            this.ConfigLoadForm.ShowLoader = false;
            window.scrollTo(0, 0);
        },

        Regresar() {
            this.Limpiar();
            this.editInformacion = false;
            window.scrollTo(0, 0);
            this.getDireccionesEnvio();
        },

        Limpiar()
        {
            this.objClientedireccionenvio = {
				IdCliDireccionEnvio: 0,
				IdCliente: '',
				TipoDireccion: '',
				Nombre: '',
				Calle: '',
				NoExt: '',
				NoInt: '',
				Cruzamiento1: '',
				Cruzamiento2: '',
				Colonia: '',
				CodigoPostal: '',
				Estado: '',
				Municipio: '',
				Pais: '',
				Telefono: '',
				Referencias: '',
				RfcCliente: '',
				
            }
            this.errorvalidacion = [];
        },

        recoveryDireccion(item) {
            this.editInformacion = true;
            this.ConfigLoadForm.ShowLoader = true;

            this.$http.get('clientesdireccionesenvios/'+item.IdCliDireccionEnvio)
            .then( (res) => {
                this.objClientedireccionenvio  = res.data.data;
               
            }).finally(()=>{
                this.ConfigLoadForm.ShowLoader = false;
            });
        },

        getDireccionesEnvio() {
            this.ConfigLoad.ShowLoader = true;
            this.$http.get('clientesdireccionesenvios', {
                params:{
                    Entrada: 8,
                    Pag: 1
                }

            }).then( (res) => {
                this.listDireccionesEnvio 			= res.data.data.data;

            }).catch( err => {
                if(err.response.data.errors){
                    this.errorvalidacion=err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            })
        },


        async Guardar()
        {
			this.errorvalidacion = [];
			this.oBtnSave.Toast = 0; 
			this.oBtnSave.DisableBtn = true;

			if(this.objClientedireccionenvio.IdCliDireccionEnvio == 0) {
                let temp =  JSON.parse(localStorage.getItem('user'));
                this.objClientedireccionenvio.IdCliente = temp.IdCliente;

				await this.$http.post(
					'clientesdireccionesenvios',this.objClientedireccionenvio
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'clientesdireccionesenvios/'+this.objClientedireccionenvio.IdCliDireccionEnvio,
					this.objClientedireccionenvio
				).then((res)=>{                 
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.oBtnSave.DisableBtn = false;  
            this.$toast.success("Información guardada");
            this.Regresar();
        },
        EjecutaConError(err)
        {
            this.oBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.Toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },


       
    }
}
</script>