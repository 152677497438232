//import es from 'vuejs-datepicker/dist/locale/translations/es';

const EstadosCiviles = [
    {id: 1, label:'Soltera (o)'} ,
    {id: 2, label:'Casada (o)'} ,
    {id: 3, label:'Divorciada (o)'} ,
    {id: 4, label:'Union Libre'} ,
    {id: 5, label:'Viuda (o)'} ,
    {id: 6, label:'Concubinato'} ,
];

const Genero = [
    {id: 1, label:'Femenino'} ,
    {id: 2, label:'Masculino'} ,
];

const Equipo = [
    {id: 1, label:'Vehículo'} ,
    {id: 2, label:'Teléfono'} ,
    {id: 3, label:'Linea Telefonica'} ,
];

const TiposCuponesDescuento = [
    {id: 1, label:'Descuento en porcentaje'} ,
    {id: 2, label:'Descuento fijo al pedido'} ,
];

const RegimenesFiscales = [
    {id: 1, label:'601 - General de Ley Personas Morales'},
    {id: 2, label:'603 - Personas Morales con Fines no Lucrativos'},
    {id: 3, label:'605 - Sueldos y Salarios e Ingresos Asimilados a Salarios'},
    {id: 4, label:'606 - Arrendamiento'},
    {id: 5, label:'607 - Régimen de Enajenación o Adquisición de Bienes'},
    {id: 6, label:'608 - Demás ingresos'},
    {id: 7, label:'609 - Consolidación'},
    {id: 8, label:'610 - Residentes en el Extranjero sin Establecimiento Permanente en México'},
    {id: 9, label:'611 - Ingresos por Dividendos (socios y accionistas)'},
    {id: 10, label:'612 - Personas Físicas con Actividades Empresariales y Profesionales'},
    {id: 11, label:'614 - Ingresos por intereses'},
    {id: 12, label:'615 - Régimen de los ingresos por obtención de premios'},
    {id: 13, label:'616 - Sin obligaciones fiscales'},
    {id: 14, label:'620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
    {id: 15, label:'621 - Incorporación Fiscal'},
    {id: 16, label:'622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
    {id: 17, label:'623 - Opcional para Grupos de Sociedades'},
    {id: 18, label:'624 - Coordinados'},
    {id: 19, label:'625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
    {id: 20, label:'626 - Régimen Simplificado de Confianza'},
    {id: 21, label:'628 - Hidrocarburos'},
    {id: 22, label:'629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'},
    {id: 23, label:'630 - Enajenación de acciones en bolsa de valores'},
];

/*const calendarSettings = {
    Style: 'form-control',
    Formato: 'yyyy-MM-dd',
    Idioma: es,
    DisabledDates: {
        to: new Date(Date.now() - 8640000)
    }
}*/


export default {
    EstadosCiviles,
    Genero,
    Equipo,
    TiposCuponesDescuento,
    RegimenesFiscales
};
