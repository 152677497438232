<template>
  <main class="" style="margin-top: 76px;">
        <div class="container path pt-3 mb-4">
            <nav  aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a class="ol.breadcrumb a" style="cursor: pointer;" @click="goHome()" >
                            <i class="fas fa-home-lg-alt"></i>
                            Inicio</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a class="ol.breadcrumb a" style="cursor: pointer;" @click="goListCatProductos()">{{NomCategoria}}</a>
                    </li>
                    <li class="breadcrumb-item active fw-medium" aria-current="page">
                        {{producto.Nombre}}
                    </li>
                </ol>
            </nav>
        </div>
        <hr />
        <section class="mb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-7 col-lg-4">
                        <!--<VueSlickCarousel v-if="!ConfigLoad.ShowLoader"
                            ref="c1"
                            :asNavFor="$refs.c2"
                            :focusOnSelect="true">
                            <div >
 
                                <div v-for="(item, index) in ListImagenes" :key="index">
                                    <img id="product-to-zoom0" :src="RutaFile+item.IdProducto+'/'+item.Imagen" width="50%" alt="producto"
                                        class="rounded-4" />
                                </div>
                            </div>
                            
                        </VueSlickCarousel>

                        <VueSlickCarousel v-if="!ConfigLoad.ShowLoader"
                        ref="c2"
                        :asNavFor="$refs.c1"
                        :slidesToShow="4"
                        :focusOnSelect="true">
                        <div>
                            <div v-for="(item, index) in ListImagenes" :key="index" class="product-slide">
                                <img :src="RutaFile+item.IdProducto+'/'+item.Imagen" width="50%" alt="producto" class="rounded-4" />
                            </div>
                            
                        </div>
                       
                        </VueSlickCarousel> -->
                        <div class="product-zoom mb-3">
                            <div v-for="(item, index) in ListImagenes" :key="index">
                                    <img :id="'product-to-zoom_'+index" :src="RutaFile+item.IdProducto+'/'+item.Imagen" width="100%" alt="producto"
                                        class="rounded-4" />
                                </div>
                            
                        </div>
                        <div class="product-slider mb-3 mb-md-0">
                            <div v-for="(item, index) in ListImagenes" :key="index" class="product-slide">
                                <img :src="RutaFile+item.IdProducto+'/'+item.Imagen" width="70%" alt="producto" class="rounded-4" />
                            </div>
                            
                        </div> 
                    </div>
                    <div class="col-12 col-md-5 col-lg-6">
                        <div class="product-info">
                            
                            <span v-if="isAgotado" class="badge bg-danger text-light fs-5">Agotado</span>
                            <span v-else-if="parseInt(producto.aplicaRebaja) == 1" class="badge bg-warning text-light fs-5">Oferta</span>

                            <h2>{{ producto.Nombre }}</h2>

                            <p v-if="producto.puntuaciones != undefined && producto.puntuaciones != null" class="my-1 mb-2">
                                <i v-for="n in producto.puntuaciones.enteros" :key="'sint_'+n" class="fas fa-star text-yellow"></i>
                                <i v-if="producto.puntuaciones.puntoMedio == 'S'" class="fas fa-star-half-alt text-yellow"></i>
                                <template v-if="producto.puntuaciones.faltantes > 0">
                                    <i  v-for="n in producto.puntuaciones.faltantes" :key="'smed_'+n" class="fas fa-star" style="color:#d1d1d1;"></i>
                                </template>

                                <template v-if="producto.puntuaciones.numPuntuaciones == 0">
                                    <i  v-for="n in 5" :key="'smed_'+n" class="fas fa-star" style="color:#d1d1d1;"></i>
                                </template>

                                <span class="text-body-tertiary fs-7"> ({{ producto.puntuaciones.numPuntuaciones }} Calificaciones)</span>
                            </p>
                            <div class="product-price d-flex fw-bold">
                                <span class="current text-morado display-5 fw-bolder">{{ $formatNumber(producto.PrecioFinal,'$') }}</span>
                                <div v-if="parseInt(producto.aplicaRebaja) == 1 || isZin == true" class="old-price ms-3">
                                    <span v-if="isZin" class="product-discount fs-7 text-yellow mb-0">
                                        20% OFF <small>(by ZIN Membership)</small> 
                                    </span> <br>
                                    <span class="text-decoration-line-through fs-4 text-body-tertiary mt-4">{{ $formatNumber(producto.Precio,'$')}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="product-customize">
                            <form>
                                <div class="color mb-3">
                                    <fieldset class="color-select">
                                        <label>Color:</label>
                                        <div class="d-flex">
                                            <div v-for="(item, index) in ListColoresUnicos" :key="index" class="form-check">
                                                <input class="form-check-input" :style=" item.Color == '#ffffff' ? 'border-color:#000; border-width: 1px; background-color:'+item.Color+';' : 'background-color:'+item.Color+';'" 
                                                    type="radio" name="color" :id="'color_'+item.IdColor" :value="item.IdColor" v-model="IdColorSelected"
                                                    v-b-tooltip.hover.Top
                                                    @change="get_tallas(item.IdColor); cambiarImagenSlider(item.IdColor)"
                                                    :title="item.Nombre" />
                                                <label class="form-check-label visually-hidden" for="color1">
                                                    {{ item.Nombre }}
                                                </label>
                                            </div>
                                            
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="d-flex align-items-end mb-3">
                                    <div class="talla me-3">
                                        <label for="product-size">Talla</label>
                                        <select @change="set_Codigo()" v-model="IdTallaSelected" class="form-select border border-1" id="product-size" required>
                                            <option :value="0">Selecciona una talla</option>
                                            <option v-for="(talla,indx) in ListTallasUnicas" :key="'stall_'+indx" :value="talla.code">{{ talla.label }}</option>
                                        </select> 
                                    </div>
                                    <div class="cantidad">
                                        <div class="d-flex align-items-center justify-content-center me-1">
                                            <label for="" hidden>Cantidad</label>
                                            <button @click="Minus()" type="button" class="btn qty-btn">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                            <input type="text" v-model="this.Cantidad" class="form-control input-qty" required  readonly/>
                                            <button @click="Plus()" type="button" class="btn qty-btn">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mb-3">
                                    <button @click="add_cart()" type="button" class="btn btn-primary" :disabled="Existencia === 0 && producto.PermitirReserva === 0" >
                                        <i class="far fa-shopping-bag me-2"></i>
                                        Agregar a bolsa
                                    </button>
                                </div>
                            </form>
                            <div class="share">
                                <div class="d-flex align-items-center">
                                    <div v-if="logueado" @click="addFavorito()" class="py-3 px-3 me-2 rounded-2 border border-1 border-light-subtle d-flex flex-column justify-content-center hover-share" title="Agregar a favoritos">
                                        <i class="fa-heart" v-bind:class="producto.isFavorit === 1 ? 'fas' : 'far' " :style="producto.isFavorit === 1 ? 'color:red;': '' "></i>
                                    </div>
                                    <div @click="copiarContenido()" class="py-3 px-3 rounded-2 border border-1 border-light-subtle d-flex flex-column justify-content-center hover-share" title="Compartir">
                                        <i class="far fa-share"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-details">
                            <div class="row mt-5 mb-3 fs-7">
                                <div class="col-6 col-md-6 col-lg-4">
                                    <p class="my-1 fs-7">
                                        Tags:
                                        <a class="text-morado text-decoration-none" style="cursor: pointer;" @click="goListCatProductos()">
                                            {{NomCategoria}}</a>
                                    </p>
                                    <p class="my-1 fs-7">
                                        SKU:
                                        <span class="text-morado">{{producto.Codigo}}</span>
                                    </p>
                                    <p class="my-1 fs-7">
                                        Modelo Seleccionado:
                                        <span class="text-morado">{{ Codigo }}</span>
                                    </p>
                                    <p v-if="Existencia >= 0" class="my-1 fs-7">En existencia: 
                                        <span class="text-morado">{{Existencia}} artículos</span> 
                                    </p>
                                    <p v-else class="my-1 fs-7">En existencia: 
                                        <span class="text-morado">0 artículos</span> 
                                    </p>
                                    
                                </div>
                                <div class="col-6 col-md-6 col-lg-4">
                                    <p v-show="producto.Garantia != undefined && producto.Garantia != null" class="my-1 fs-7">
                                        Garantía:
                                        <span class="text-morado">{{ producto.Garantia }}</span>
                                    </p>
                                    <p v-if="parseInt(Existencia) <= 0 && producto.PermitirReserva" class="my-1 fs-7">
                                        Nota:
                                        <span class="text-morado">Este producto admite compras de sobrepedido</span>
                                    </p>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <ul class="nav nav-pills" id="myTab" role="tablist">
                                    <li class="nav-item mx-1 mb-2 mb-lg-0" role="presentation">
                                        <button class="nav-link active" id="productDescription" data-bs-toggle="tab"
                                            data-bs-target="#productDescriptionPane" type="button" role="tab"
                                            aria-controls="productDescriptionPane" aria-selected="true">
                                            Descripción
                                        </button>
                                    </li>
                                    <li class="nav-item mx-1 mb-2 mb-lg-0" role="presentation">
                                        <button class="nav-link" id="productReviews" data-bs-toggle="tab"
                                            data-bs-target="#productReviewPane" type="button" role="tab"
                                            aria-controls="productReviewPane" aria-selected="false">
                                            Reseñas
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content mt-2 px-3" id="myTabContent">
                                    <div class="tab-pane fade show active" id="productDescriptionPane" role="tabpanel"
                                        aria-labelledby="description-tab" tabindex="0">
                                        <p>
                                            {{ producto.Descripcion }}
                                        </p>
                                        
                                        <h2 class="my-4">
                                            Opciones de envío
                                        </h2>
                                        <hr />
                                        <h5>Envío Asegurado</h5>
                                        <p>Envio asegurado hasta por $5,000.00 MXN. Costo: $400 MXN.</p>
                                        
                                        <h5>Envío Económico</h5>
                                        <p>Envío económico terrestre de 1 a 5 kg en un plazo de 5 a 7 días hábiles.
                                            Costo: $230 MXN.</p>
                                    </div>


                                    <div class="tab-pane fade" id="productReviewPane" role="tabpanel"
                                        aria-labelledby="review-tab" tabindex="1">
                                        <div v-if="producto.puntuaciones != undefined && producto.puntuaciones != null" class="d-flex mb-4 flex-wrap align-items-center justify-content-between">
                                            <div v-if="producto.puntuaciones.numPuntuaciones > 0" class="product-review">
                                                <span class="rate">{{ producto.puntuaciones.promedioGeneral }}<template v-if="producto.puntuaciones.puntoMedio == 'N'">.0</template></span>
                                                <div class="wrapper">
                                                    <p class="stars">
                                                        <i v-for="n in producto.puntuaciones.enteros" :key="'esintres_'+n" class="fas fa-star text-yellow"></i>
                                                        <i v-if="producto.puntuaciones.puntoMedio == 'S'" class="fas fa-star-half-alt text-yellow"></i>
                                                        <template v-if="producto.puntuaciones.faltantes > 0">
                                                            <i  v-for="n in producto.puntuaciones.faltantes" :key="'esmedres_'+n" class="fas fa-star" style="color:#d1d1d1;"></i>
                                                        </template>
                                                        
                                                    </p>
                                                    <p class="reviews-no">
                                                        <span>{{ producto.puntuaciones.numPuntuaciones }}</span>
                                                        Calificaciones
                                                    </p>
                                                </div>
                                            </div>
                                            <div v-else class="text-center">
                                                <h6>Aun no hay calificaciones para este producto.</h6>
                                            </div>
                                            <!-- Button trigger modal 
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#rateProductModal">
                                                Puntuar
                                            </button>-->

                                            <!-- Modal 
                                            <div class="modal fade" id="rateProductModal" tabindex="-1"
                                                aria-labelledby="Modal para puntuar producto" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h1 class="modal-title fs-5" id="">
                                                                ¿Qué te
                                                                pareció el
                                                                producto?
                                                            </h1>
                                                            <button type="button" class="btn-close"
                                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            
                                                            <form id="newReview">
                                                                <p>
                                                                    Puntuación:
                                                                </p>
                                                                <fieldset class="new-rating mb-3">
                                                                    <input type="radio" name="starRating" id="st5"
                                                                        value="5" required /><label for="st5"></label>
                                                                    <input type="radio" name="starRating" id="st4"
                                                                        value="4" /><label for="st4"></label>
                                                                    <input type="radio" name="starRating" id="st3"
                                                                        value="3" /><label for="st3"></label>
                                                                    <input type="radio" name="starRating" id="st2"
                                                                        value="2" /><label for="st2"></label>
                                                                    <input type="radio" name="starRating" id="st1"
                                                                        value="1" /><label for="st1"></label>
                                                                </fieldset>
                                                                <div class="mb-3">
                                                                    <label for="comment"
                                                                        class="form-label">Comentarios</label>
                                                                    <textarea name="review" class="form-control" id="comment" rows="3"
                                                                        required></textarea>
                                                                </div>
                                                                <div class="d-flex justify-content-end">
                                                                    <button type="button" class="btn btn-secondary me-2"
                                                                        data-bs-dismiss="modal">
                                                                        Cancelar
                                                                    </button>
                                                                    <button type="submit" onsubmit="showsomethin()"
                                                                        class="btn btn-primary">
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                        </div>
                                        



                                        <div v-for="(item,indx) in producto.puntuaciones.listPuntuaciones" :key="indx" class="user-review">
                                            <div class="d-flex justify-content-between">
                                                <h4 class="name">
                                                    {{ item.Nombre }}
                                                </h4>
                                                <span class="date">{{ item.FechaComentario }}</span>
                                            </div>
                                            <p class="model">
                                                <span>XS</span>/<span>Bold Black</span>
                                            </p>
                                            <p class="rate" title="4">
                                                <i v-for="n in item.Puntuacion" :key="'sintus_'+n" class="fas fa-star text-yellow"></i>
                                                <template v-if="item.Faltantes > 0">
                                                            <i  v-for="n in item.Faltantes" :key="'smedus_'+n" class="fas fa-star" style="color:#d1d1d1;"></i>
                                                        </template>
                                                
                                                
                                            </p>
                                            <div class="review">
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ProductosSugeridos v-if="!ConfigLoad.ShowLoader" :IdCategoria="IdCategoria" :pProducto="producto"/>


        <!--<section class="container container-width mb-4">
            <div class="position-relative newsletter bg-5 p-3 p-sm-5">
                <div class="row">
                    <div class="col-12 col-lg-9 col-xl-6">
                        <div class="row mb-4">
                            <div class="col-12">
                                <p class="fs-1 fw-bolder text-light">
                                    ¡Recibe las últimas ofertas y novedades
                                    directamente en tu correo!
                                </p>
                            </div>
                            <div class="col-12">
                                <p class="fs-6 text-light">
                                    Suscribte a nuestro
                                    <span class="text-morado">Newsletter</span>.
                                </p>
                            </div>
                        </div>
                        <form class="form" action="">
                            <div class="bg-white d-flex justify-content-between rounded-5 w-auto">
                                <div class="d-flex">
                                    <i class="far fa-paper-plane text-body-tertiary my-auto ps-4"></i>
                                    <input type="email" class="shadow-none form-control border-0 h-100 rounded-5"
                                        placeholder="ejemplo@email.com" />
                                </div>
                                <div class="text-end">
                                    <button class="btn fw-bold btn-primary rounded-5 px-3 py-3 fs-6">
                                        Suscribirse
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section> -->
    </main>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import ProductosSugeridos from './ProductosSugeridos.vue';

export default {
    name: 'viewProductoDetalle',
    components:{
        VueSlickCarousel,
        ProductosSugeridos
    },
    data() {
        return {
            Categoria: {
                IdCategoria: 0,
                Nombre: '',
                Imagen: '',
                Icono: '',
                subcategorias: []
            },
            producto:{
                garantiaDesc: '',
                Garantia: '',
                IdProducto: 0,
                Descripcion: "",
                IdGarantia: 0,
                Publicado: 1,
                Nombre: '',
                Codigo: "",
                EsInventario: 1,
                PermitirReserva: 0,
                CostoCompra: "",
                Precio: 0,
                FechaPublicacion: "",
                TieneRebaja: 0,
                PrecioReb: 0,
                isFavorit: 0,
                aplicaRebajaP: 0,
                PrecioFinal: 0,
                aplicaRebaja: 0,
                isPublicado: 0,
                imagenesUnicas: [],
                tallasUnicas : [],
                tallas:[],
                existencia:[],
                imagenes:[],
                colores:[],
                puntuaciones:{
                    promedioGeneral: 0,
                    puntoMedio: 'N',
                    numPuntuaciones: 0,
                    listPuntuaciones:[]
                },
                
            },
            ListTallasUnicas:[],

            ListImagenes:[],
            ListColoresUnicos:[],
            show:false,
            IdCategoria:0,
            colores:[],
            tallas:[],
            Existencia:0,
            Cantidad:0,
            IdTallaSelected: 0,
            nomTallaSelect:'',
            nomColorSelect:'',
            IdColorSelected:0,
            Codigo:'---',
            RutaFile:'',
            disabledBtnFav:false,
            logueado:false,
            isFavorit:0,

            NomCategoria: '',
            TmpDispoTallas:[],
            isFeatured: 0,

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
            isReady:false,
            isAgotado: false,
            isLogged: false,
            isZin: false,

            /**
            seoInfo: {
                metTitle: '',
                metaDescription: '',
                metaImgURL: '',
                metaSiteUrl:'',
                metaType:''
            },
             */
        }
    },

    methods: {
        async get_producto() {
            this.ConfigLoad.ShowLoader = true;

            if(this.producto.IdProducto===undefined){ 
                return false;
            }
            this.getTipoUsuario();

            await this.$http.get('productos/'+this.producto.IdProducto).then( (res) => {
                this.producto 			= res.data.data;
                
                this.ListImagenes       = res.data.data.imagenes;
                this.RutaFile           = res.data.rutaFile;
                this.colores            = res.data.data.colores;
                this.tallas             = res.data.data.tallas;
                this.Existencia         = this.producto.existencia[0].Existencia;
                this.show               = true;

                this.ListColoresUnicos 	= res.data.data.imagenesUnicas.map(item => ({
                    IdProductoImagen:item.IdProductoImagen,
                    IdProducto:item.IdProducto,
                    IdColor:item.IdColor,
                    Principal:item.Principal,
                    Imagen:item.Imagen,
                    UrlImg:item.UrlImg,
                    Disable:item.Disable,
                    Nombre:item.Nombre,
                    Clave:item.Clave,
                    Color:item.Color,
                    isActive:false,
                }));

                //localStorage.setItem("producto",JSON.stringify(this.producto));

                // REALIZAMOS UN MAP, PARA PODER CREAR EL ARREGLO PARA EL SELECT
                let arrTallaUnicRaw = res.data.data.tallasUnicas;
                
                if(arrTallaUnicRaw.length > 0){
                    this.ListTallasUnicas = arrTallaUnicRaw.map(item => ({
                        code: parseInt(item.IdTalla),
                        label: item.Clave,
                    }));

                    this.IdTallaSelected = this.ListTallasUnicas[0].code ;
                }else {
                    this.isAgotado = true;
                }
                

                if(this.ListColoresUnicos.length > 0){
                    let iColor = this.ListColoresUnicos[0].IdColor;
                    this.ListColoresUnicos[0].isActive = true;
                    this.get_tallas(iColor);
                    
                }

                this.set_Codigo()

                this.isReady = true;

            }).catch( err => {
                //console.log(err);
            }).finally(() => {
                
               
                this.ConfigLoad.ShowLoader = false;
                this.activaCarrousel();
            });
        },

        //cuando presiona click en las tallas
        getTipoUsuario() {
            this.$http.get('usrsrchme').then( (res) => {

                let user = res.data.data;

                if(user != undefined && user != '' && user != null){
                
                    if(parseInt(user.TipoUser) == 3 && user.EstatusMem == 'A'){
                        this.isZin = true;
                    }else {
                        this.isZin = false;
                    }
                }


            }).catch( err => {
                console.log(err);
                this.isZin = false;
            });
        },

        //cuando presiona click en los colores
        get_tallas(IdColor) {
            //si selecciono otro color reseteamos la cantidad
            if(this.IdColorSelected !== IdColor){
                this.Cantidad=0;
            }

            // FILTRAR EL ARRAY DE TALLAS PARA OBTENER SOLO LAS QUE CONTIENEN EL COLOR SELECCIONADO.
            this.IdColorSelected = IdColor;
            let listTallas = this.tallas.filter(function (e) {
                return e.IdColor === IdColor
            });

            let existTalla = 0;
        
            if(listTallas.length > 0) {
                


                listTallas.forEach(element => {
                    if(element.IdTalla === this.IdTallaSelected) {
                        existTalla = 1;
                    
                        if (element.Existencia > 0){
                            this.Cantidad = 1;
                        }else {
                            this.Cantidad = 0;
                        }
                    }
                });
            }

            // SI LA TALLA QUE SE HABIA SELECCIONADO NO ESTA EN EL ARRAY SE MANDA A SELECCIONAR UNA NUEVA TALLA
            if(existTalla == 0){
                this.IdTallaSelected = 0;
            }
            this.set_Codigo();
        },

        set_Codigo() {
            let TallaClave='';
            let ColorClave='';
            let IdT = this.IdTallaSelected;
            let IdC = this.IdColorSelected;

            
            let listTallas = this.tallas.filter(function (e) {
                return e.IdTalla == IdT;
            });

            if(listTallas.length>0){
                TallaClave = listTallas[0].Clave;
            }
          
            let listColores = this.colores.filter(function (e) {
                return e.IdColor == IdC;
            });

            if(listColores.length>0) {
                this.cambiarImagenSlider(listColores[0].Color);
                ColorClave = listColores[0].Clave;
            }
            
            this.nomTallaSelect = TallaClave;
            this.nomColorSelect = ColorClave;
            this.Codigo = this.producto.Codigo+'-'+TallaClave+'-'+ColorClave;

            //Actualizamos el numero de existencia de la combinacion de color y talla seleccionados
            let IdProductoTalla = this.get_IdProductoTalla(0);

            //buscamos imagenes de color specifico
            //this.get_ImagenesSelected();

        },

        get_ImagenesSelected() {
            //this.ListImagenes=[];
            if(this.IdColorSelected>0){//filtramos imagenes de color seleccionado
                let IdColor=this.IdColorSelected;
                let listImg = this.producto.imagenes.filter(function (e) {
                    return e.IdColor ==IdColor;
                });
                this.ListImagenes=listImg;

            }else{//mostramos todas la imagnes
                this.ListImagenes=this.producto.imagenes;
            }

            if(this.ListImagenes.length>0) {
                var contador=0;
                this.ListImagenes.forEach(element => {
                    //if(document.getElementById('divCaruse'+contador)!=undefined){
                        //document.getElementById('divCaruse'+contador).className="carousel-item"
                    //}
                    contador ++;
                });
                //document.getElementById('divCaruse0').className="carousel-item active";
            }
        },

        Plus(){
            var result=this.validSelected();
            if(!result.status){
                this.$toast.warning(result.msg);
                return false;
            }

            if(this.Cantidad === this.Existencia && this.producto.PermitirReserva === 0){
                return false;
            }

            this.Cantidad ++;
        },

        Minus(){
            var result=this.validSelected();
            if(!result.status){
                this.$toast.warning(result.msg);
                return false;
            }
            if(this.Cantidad === 0){
                return false
            }
            this.Cantidad --;
        },

        //valida si existe talla y color seleccionado
        validSelected(op = 0) {
            if(this.IdTallaSelected <= 0 && op === 1) {
                console.log('Seleccione una talla');
                return {status:false,msg: "Seleccione una talla"};

            }else if(this.IdColorSelected <= 0 && op === 1) {
                console.log('Seleccione un color');
                return {status:false,msg: "Seleccione un color"};

            }else{
                console.log('Ok!');
                return {status:true,msge:"ok"};
            }
        },

        //Devuelve IdProductoTalla
        get_IdProductoTalla(op) {
           
            let IdT     = parseInt(this.IdTallaSelected);
            let IdC     = parseInt(this.IdColorSelected);
            let result  = this.validSelected(op);

            if(!result.status) {
                this.$toast.warning(result.msg);
                return 0;
            }

            if(IdT<=0 && IdC <= 0) {
                return 0;
            }

            let listColoresxtallas = this.tallas.filter(function (e) {
                return e.IdColor == IdC && e.IdTalla == IdT;
            });

            //console.log(listColoresxtallas);
            if(listColoresxtallas.length>0) 
            {
                this.Existencia = listColoresxtallas[0].Existencia;
                if(op === 0) {
                    this.Cantidad = (this.Existencia > 0) ? 1 : 0;
                }
                return listColoresxtallas[0].IdProductoTalla;

            }else{
                this.Existencia=this.producto.existencia[0].Existencia;
                if(op === 0) {
                    this.Cantidad = (this.Existencia > 0) ? 1 : 0;
                }
                return 0;
            }
        },


        /************************** ADD_CART *********************************/

        add_cart(){
            if(this.Cantidad > 0) 
            {  
                

                // VALIDA LA EXISTENCIA DEL PRODUCTO Y RETORNA SI ID PRODUCTO TALLA
                let IdProductoTalla = this.get_IdProductoTalla(1); 
               

                if(parseInt(IdProductoTalla) <= 0) {
                    this.$toast.warning("No se ha prodido agregar el producto a la bolsa");
                    return false;
                }

                console.log('agregado de productos...');
                let Precio              = this.$replaceAll(this.producto.PrecioFinal,',','');
                let Importe             = (parseFloat(Precio) * parseFloat(this.Cantidad));
                let Imagen              = this.RutaFile+this.producto.IdProducto+'/'+this.ListImagenes[0].Imagen;

                let obj = {
                    IdProducto:         this.producto.IdProducto,
                    EsInventario:       this.producto.EsInventario,
                    PermitirReserva:    this.producto.PermitirReserva,
                    Imagen:             Imagen,
                    Nombre:             this.producto.Nombre,
                    Precio:             Precio,
                    Importe:            Importe,
                    Cantidad:           this.Cantidad,
                    IdProductoTalla:    IdProductoTalla,
                    Codigo:             this.Codigo,
                    Existencia:         this.Existencia,
                    IdCategoria:        this.IdCategoria,
                    NomCategoria:       this.NomCategoria,
                    NomTalla:           this.nomTallaSelect,
                    NomColor:           this.nomColorSelect
                };

                //se agregan datos al carrito
                console.log(obj);
                let result = this.$addItemCart(obj);

                if(result.status) {
                    this.$toast.success(result.msg);
                }else{
                    this.$toast.error(result.msg);
                }

            }else{
                this.$toast.warning("No hay productos seleccionados");
            }
        },


        /*********************** FAVORITOS *************************/

        
        async addFavorito() {
            await this.$http.post('favoritos', {
                IdProducto: this.producto.IdProducto
            }).then( (res) => {
                if (res.data.status) {
                    if(res.data.data.selector == 'add') {
                        this.producto.isFavorit = 1;
                        this.$toast.success('se ha agregado a tus favoritos')
                    }else{
                        this.producto.isFavorit = 0;
                    }
                }
            }).catch( (err) => {
                console.log(err.response.data.message);
            });
        },

        getCartegoria(id){
            this.$http.get('categorias/'+id).then( (res) => {
                this.Categoria            = res.data.data;
                this.NomCategoria 			= res.data.data.Nombre;
            }).catch( err => {
                this.NomCategoria = 'Productos';
            });
        },


        goHome(){
            this.$router.push({name:'principal',params:{} });
        },

        goListCatProductos(){
            this.$router.push({name:'productos', 
                params: { 
                    pIdCategoria: this.IdCategoria, 
                    pIsTop: this.isFeatured, 
                    pTextBusqueda: '' 
                } 
            });
        },

        cambiarImagenSlider(pColor) {

            const index = this.ListImagenes.findIndex((imagen) => imagen.IdColor === pColor);
            if(index != -1){
                console.log('entro en if');
                $(".product-slider").slick("slickGoTo", index);
            }

        },

        activaCarrousel(){
            let zoom = true;
            $(".product-zoom").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: ".product-slider",
            });
            $(".product-slider").slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: ".product-zoom",
                dots: false,
                centerMode: false,
                focusOnSelect: true,
            });
            /*$("#product-to-zoom0").elevateZoom({
                responsive: false,
                zoomType: "inner",
                cursor: "crosshair",
                zoomWindowFadeIn: 500,
                disableZoom: true,
                zoomWindowFadeOut: 750,
            });
            $(".product-zoom").on(
                "beforeChange",
                function (event, slick, currentSlide, nextSlide) {
                    // Desactiva ElevateZoom en la imagen actual
                    $("#product-to-zoom" + currentSlide).elevateZoom({
                        disableZoom: true,
                    });
                    // Inicializa ElevateZoom en la nueva imagen
                    $("#product-to-zoom" + nextSlide).elevateZoom({
                        responsive: false,
                        zoomType: "inner",
                        cursor: "crosshair",
                        zoomWindowFadeIn: 500,
                        disableZoom: true,
                        zoomWindowFadeOut: 750,
                    });
                }
            );*/
        },

        async copiarContenido() {
            try {
                await navigator.clipboard.writeText(window.location);
                this.$toast.success("Copiado!","Link copiado al portapapeles");
                /* Resuelto - texto copiado al portapapeles con éxito */
            } catch (err) {
                console.error('Error al copiar: ', err);
                /* Rechazado - fallo al copiar el texto al portapapeles */
            }
        }
    },

    created() {
        this.bus.$off('get_Detalle');
        this.logueado = this.$store.getters.isLoggedIn;
    },
    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        // path: '/detalle/:pIdCategoria/:pProducto/:isFeature/:nomCat/:NomProducto',
      
        /*this.MasterKey = this.$getParameterByName('cat');
        this.MasterId = this.$getParameterByName('prod');
        this.MasterId = this.$getParameterByName('feat');.
        
        this.MasterId = this.$getParameterByName('nomcat');
        this.MasterId = this.$getParameterByName('nomp');*/

        if(this.$getParameterByName('cat') != null && this.$getParameterByName('cat') != undefined){
            localStorage.setItem("IdCategoria",this.$getParameterByName('cat'));
        }

        if(this.$getParameterByName('prod') != null && this.$getParameterByName('prod') != undefined){
            this.producto.IdProducto = this.$getParameterByName('prod');
            //this.get_producto()
        }

        if(this.$getParameterByName('feat') != null && this.$getParameterByName('feat') != undefined){
            this.isFeatured = this.$getParameterByName('feat');
        }


        if(localStorage.getItem("producto") === undefined){
            this.$router.push({name:'index'});
            return false;

        }

        this.IdCategoria = localStorage.getItem("IdCategoria");

        this.getCartegoria(this.IdCategoria)


        
        this.bus.$on('get_Detalle',()=> {
            console.log('Se activo Emit get_detalle')
            window.scrollTo(0, 0);
            this.show=false;
            this.get_producto();

        });


        window.scrollTo(0, 0);
        this.get_producto();
    }
}
</script>

<style>

</style>