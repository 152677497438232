<template>
  <main style="margin-top: 76px;">
    <input type="hidden" id="seconds" value="6">
    <input type="hidden" :value="verificaFactura">
        <div class="container path pt-3 mb-4">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                            <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a class="text-decoration-none text-morado fw-medium" @click="goBolsa()" style="cursor: pointer;">Mi Bolsa</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a class="text-decoration-none text-morado fw-medium" @click="goEnvios()" style="cursor: pointer;">Información de Envío</a>
                    </li>
                    <li class="breadcrumb-item active fw-medium" aria-current="page">Metodo de Pago</li>
                </ol>
            </nav>
        </div>
        <hr>
        
        <section class="container mb-4">
            <div class="row">
                <div class="col-lg-8 mb-4">
                    <h2>Elige como deseas pagar</h2>
                    <div class="d-flex justify-content-between align-items-end">
                    </div>
                </div>

                
                <!-- AREA DE SELECCION-->
                <div class="col-12 col-md-7 col-lg-8">
                    <CLoader :pConfigLoad="ConfigLoad">
                        <template slot="BodyFormLoad">
                            <div v-if="listMetodosPago.length > 0" class="products-added-list">
                                <div v-for="(item,idex) in listMetodosPago" :key="idex" class="product-added">
                                    <div class="me-4">
                                        <input type="radio" :id="'dir_'+item.IdMetodoPago" :value="item.IdMetodoPago" v-model="IdMetodoPago" />
                                    </div>
                                    <figure class="img-thumbnail" style="border:none;">
                                       
                                        <i v-if="item.Tipo == 1" class="fad fa-credit-card fa-lg" style="font-size: 3em !important;"></i>
                                        <i v-if="item.Tipo == 3" class="fad fa-money-bill fa-lg" style="font-size: 3em !important;"></i>
                                        <i v-if="item.Tipo == 4" class="fad fa-university fa-lg" style="font-size: 3em !important;"></i>
                                    </figure>
                                    
                                    <div class="details">
                                        <h3 class="name"> {{ item.Nombre }} </h3>
                                        <p class="size-color">{{ item.Descripcion }}</p>
                                        <div class="cantidad"></div>
                                    </div>

                                    <img  v-if="item.Tipo == 1" width="15%" src="https://images.openpay.mx/logo_new.png">
                                 
                                </div>
                                
                            </div>

                            <div v-if="listMetodosPago.length == 0 && ConfigLoad.ShowLoader == false" class="products-added-list">
                                <br>
                                <div class="text-center">
                                    <h3 class="text-morado">No se cuenta con formas de pago por el momento!</h3>
                                </div>
                                <br>
                            </div>
                            
                            <br>
                            <br>
                            <br>
                        </template>
                    </CLoader>
                    
                </div>

                <!-- RESUMEN DEL PEDIDO -->
                <div class="col-12 col-md-5 col-lg-4">
                    <div class="summary">
                        <h5 class="text-center fs-4 mb-3">Pedido</h5>
                        
                        <div class="total-price-breakdown mt-2 mb-3">
                            <h5 class="mb-2">Detalle de compra</h5>

                            <div class="section">
                                <span class="title">Productos:</span>
                                <span>{{ $formatNumber(objPedido.Subtotal,'$') }}</span>
                            </div>
                            
                            <div class="section">
                                <span class="title">Descuentos:</span>
                                <span>{{ $formatNumber(objPedido.Descuento,'$') }}</span>
                            </div>

                            <div class="section">
                                <span class="title">Envío:</span>
                                <span>{{ $formatNumber(objPedido.CostoEnvio,'$') }}</span>
                            </div>
                            <div v-if="objPedido.labelEnvio != '' && objPedido.labelEnvio != undefined" class="section">
                                <small>{{ objPedido.labelEnvio }}</small>
                            </div>
                            <div class="total">
                                <span>TOTAL</span>
                                <span>{{ $formatNumber(objPedido.GranTotal,'$') }}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" v-model="objPedido.isFactura" type="checkbox" role="switch" id="isFactura" :disabled="blockBtn">
                                <label class="form-check-label" for="isFactura" style="color:black;">Deseo facturar esta compra</label>
                            </div>
                        </div>
                        <div class="text-start mb-3">
                            <small v-if="tryFacturar">Configura tus datos fiscales en tu cuenta para poder solictar tus facturas</small>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button @click="goPagarPedido()" class="btn btn-primary w-100" :disabled="loadingBtn">
                                <span v-if="loadingBtn" class="text-white spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {{ textBtn }} <template v-if="cuentaRegresiva">{{ contadorRegresivo }}</template>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </main>
</template>

<script>
import CLoader from "@/components/CLoader.vue";
const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = (MILLISECONDS_OF_A_SECOND * 60);

export default {
    name: 'MetodoPago',
    components: {
        CLoader,
    },

    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },

            listMetodosPago: [],
            finalDate: null,
            objPedido:{
                IdMetodoEnvio: 0,
                IdCuponDescuento: 0,
                IdDescMayorista: 0,
                IdDireccionEnvio: 0,
                isFactura: false,
                
                detallePedido:[],
                Subtotal: 0,
                Descuento: 0, // VALOR DESCUENTO
                CostoEnvio: 0,
                GranTotal: 0
            },

            IdMetodoPago: 0,
            loadingBtn: false,
            errorvalidacion:[],
            cuentaRegresiva: false,
            contadorRegresivo: 0,
            textBtn: 'Pagar',
            tmpResponse:{},
            blockBtn: false,
            existDatosFiscales: false,
            tryFacturar: false
        };
    },

    created() {
        this.bus.$emit('cambiaLogoNegro');


        let carrito = JSON.parse(localStorage.getItem('listCarrito'));
        if(carrito.length == 0){
            this.$router.push({name:'bolsa'});
        }

    },

    mounted() {
        window.scrollTo(0, 0);


        // OBJETO PEDIDO
        if(localStorage.getItem('orden') != undefined && localStorage.getItem('orden') != null) {

            this.objPedido = JSON.parse(localStorage.getItem('orden'));

            // VERIFICACIÓN DE EXISTENCIA DE METODO DE ENVIO SELECCIONADO
            if(this.objPedido.IdDireccionEnvio != undefined && this.objPedido.IdDireccionEnvio != null && parseInt(this.objPedido.IdDireccionEnvio) <= 0) {
                this.$router.push({name:'envios'});
            }

        }

        // VERIFICACIÓN DE EXISTENCIA DE ELEMENTOS EN EL CARRITO
        let carrito = JSON.parse(localStorage.getItem('listCarrito'));
        if(carrito.length == 0){
            this.$router.push({name:'bolsa'});
        }

        this.getMetodosPago();
    },

    methods: {
        goHome() {
            this.$router.push({name:'principal',params:{} });
        },

        goBolsa() {
            this.$router.push({name:'bolsa',params:{}});
        },

        goEnvios() {
            this.$router.push({name:'envios',params:{}});
        },

        getMetodosPago() {
            this.ConfigLoad.ShowLoader = true;
            this.$http.get('metodospagos', {
                params:{
                    Entrada: 8,
                    Pag: 1,
                    Disponible: 1
                }

            }).then( (res) => {
                this.listMetodosPago 			= res.data.data.data;
                
            }).catch( err => {
                if(err.response.data.errors){
                    this.errorvalidacion=err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            })
        },


        goPagarPedido() {
            this.loadingBtn                 = true;
            this.blockBtn                   = true;
            this.errorvalidacion		    = [];
            this.objPedido.IdPedido         = 0;
            this.objPedido.Origen           = 'WEB';
            this.objPedido.IdMetodoPago     = this.IdMetodoPago;
            this.objPedido.detallePedido    = JSON.parse(localStorage.getItem('listCarrito'));
			//this.pedido.paymentId		= this.$route.query.paymentId;
			//this.pedido.token			= this.$route.query.token;
			//this.pedido.payerId	    = this.$route.query.PayerID;
			//this.pedido.isFactura 	= this.isFactura;

			if( this.objPedido.IdMetodoPago <= 0 ) {
				this.$toast.warning("Seleccione una forma de pago");
                this.loadingBtn = false;
                return false;

			}else if( this.objPedido.GranTotal <= 0 ) {
				this.$toast.warning("No hay productos seleccionados");
                this.loadingBtn = false;
                return false;
			}

            // SI ES PAYPAL
			if( this.IdMetodoPago === 2 ) {
				if( this.objPedido.paymentId === undefined || this.objPedido.token === undefined || this.objPedido.payerId === undefined ){
					this.$toast.warning("Presione el botón de paypal para continuar");
                    this.loadingBtn = false;
					return false;
				}
			}

            if(this.objPedido.isFactura && this.existDatosFiscales == false) {
                this.$toast.info("Aun no tienes establecido tus datos fiscales");
                this.objPedido.isFactura = false;
                this.loadingBtn = false;
                return false;
            }

            console.log(this.objPedido);

			this.$http.post('neworder', this.objPedido)
			.then( (res) => {
                console.log(res.data);
				if (res.data.status) {

					
                    
                    // TARJETAS
					if(this.IdMetodoPago === 1) {

                        this.$toast.info("Procediendo al pago");
                        this.textBtn = 'Enviando al portal de pago ';
                        this.finalDate = new Date();
                        this.finalDate.setSeconds(this.finalDate.getSeconds() + 6);
                        
                        clearInterval(this.$store.state.timeGlobal);
                        this.$store.state.timeGlobal = setInterval(() => {this.updateCountdown('tarjeta',res.data.data);}, 900);
						

					}else{
                        // EFECTIVO Y TRANSFERENCIA
                        if(this.IdMetodoPago === 3 || this.IdMetodoPago === 4) {
						    this.$toast.success("Pedido Guardado.");
						    this.$toast.info("En unos segundos seras redireccionado.");
                            this.textBtn = 'Verificando ';
                            this.finalDate = new Date();
                            this.finalDate.setSeconds(this.finalDate.getSeconds() + 6);
                            
                            clearInterval(this.$store.state.timeGlobal);
                            this.$store.state.timeGlobal = setInterval(() => {this.updateCountdown('orden','');}, 1000);
					    }

					}
                    
				}

			}).catch( (err) => {
				console.log(err);
                this.loadingBtn = false;
                this.blockBtn   = false;
				this.$toast.error(err.response.data.message);
                
			}).finally(() => {
                
			});
        },

        updateCountdown(tp,data) {
            this.cuentaRegresiva = true;
            const CURRENT_DATE = new Date();
            //const SPAN_SECONDS = document.getElementById("seconds");
        
            const DURATION = (this.finalDate - CURRENT_DATE);
            const REMAINING_SECONDS = Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND);
            
            console.log(REMAINING_SECONDS);
            
            if(REMAINING_SECONDS <= 0){
                clearInterval(this.$store.state.timeGlobal);
                if(tp == 'orden') {
                    this.$emptyCart();
                    this.loadingBtn = false;
                    this.$router.push({name:'profile',params:{pOrigen:'detPedido'} });
                    

                }else if(tp == 'tarjeta') {
                    this.getLinkPago(data);
                }
                
            }

            this.contadorRegresivo = REMAINING_SECONDS;

        },

        async getLinkPago(data) {
            console.log('SOLICITANDO LINK...');
            this.cuentaRegresiva    = false;
            this.loadingBtn         = true;
            this.textBtn            = 'En espera de confirmacion';

            let newRequest = {
				IdPedido: data.orden.IdPedido,
			}

			await this.$http.post('paycardshop', newRequest)
			.then( (res) => {

				if(res.data.status && parseInt(res.data.ECode) == 42) {  
					// REDIRECCION PARA OPENPAY

					if(res.data.data.linkPasarela && res.data.data.linkPasarela != undefined) {
						//console.log(res.data.data.linkPasarela);
						window.location.replace(res.data.data.linkPasarela);
						
					}else {
						this.$toast.error('Ah ocurrido un error, no se ha podido iniciar el proceso de pago..');
					}

				}else { 
					// DEFAULT
					this.$toast.error('Ah ocurrido un error, no se ha podido iniciar el proceso de pago..');
				}
				
			}).catch(err => {
				if(err.response.data.errors) {
					this.errorvalidacion = err.response.data.errors;
					this.$toast.warning('Verifica tu informacion');
				}else {
					console.log(err.response.data.errors);
					this.$toast.error('Ah ocurrido un error, no se ha podido realizar el pago');
				}
				
				
			}).finally(()=>{
                this.$emptyCart();
				this.ConfigLoad.ShowLoader = false;
                this.loadingBtn = false;

			});
        },

        async recoveryDatosFiscales() {
            this.blockBtn = true;
            this.tryFacturar = false;

            await this.$http.get("clientefiscales")
            .then((res) => {
            
                if(res.data.status) {
                    this.existDatosFiscales = true;
                    //console.log('Tiene datos fiscales');
                }else {
                    this.existDatosFiscales     = false;
                    this.tryFacturar            = true;
                    this.objPedido.isFactura    = false;
                    this.$toast.info("Aun no tienes establecido tus datos fiscales");
                    //console.log('No existen datos fiscales');
                }

            }).finally(()=>{
                this.blockBtn = false;
            });
        },
    },
    computed:{
        verificaFactura(){
            if(this.objPedido.isFactura) {
                console.log('consultando facturacion...');
                this.recoveryDatosFiscales();
            }
        },
    }
};
</script>