<template>
  <div>
    <main style="margin-top: 92px;">
        <section class="mt-4">
            <div class="container container-width">
                <!-- HEADER DE CATEGORIA -->
                <div class="bg-wearwall rounded-5 p-5">
                    <div class="row align-items-center">
                        <div class="col-xl-3">
                            <h1 class="text-white">Eventos</h1>
                            <div class="d-inline-block p-0 text-capitalize m-0">
                                <nav aria-label="breadcrumb"
                                    style="--bs-breadcrumb-divider: '>'; font-family: 'Lato', sans-serif;">
                                    <ul class="breadcrumb fs-7">
                                        <li class="breadcrumb-item"><a class="text-white fw-bold"
                                            @click="$router.push({ name :'principal',params:{}});" style="cursor: pointer;">
                                            <i class=" text-white far fa-house me-2"></i>Home</a>
                                        </li>
                                        <li class="breadcrumb-item active fw-bold text-white">Eventos</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-xl-9 text-end d-none d-xl-block"></div>
                    </div>
                </div>
                <!-- TERMINA HEADER DE CATEGORIA -->



                <!-- CONTENEDOR DE PAGINA -->
                <div class="row flex-row-reverse my-5">
                    <div class="col-12 col-lg-9 col-xl">
                        <div class="d-flex flex-wrap justify-content-between align-items-baseline mb-3">
                            <p class="ff-lato">
                                Se encontraron
                                <span class="text-morado">{{ Filtro.TotalItem }}</span>
                                articulos!
                            </p>
                            <div class="sortby d-flex align-items-center fs-7">
                                <div class="show-elements py-1 px-3 border border-1 rounded-3 d-flex me-2">
                                    <label class="d-flex align-items-center"><i
                                            class="far fa-th-large me-2"></i>Elementos:</label>
                                    <select @change="changeFiltersPage()" class="form-select border-0 fs-7" aria-label="" v-model="Filtro.Entrada">
                                        <option :value="16">16</option>
                                        <option :value="32">32</option>
                                        <option :value="64">64</option>
                                        <option :value="112">112</option>
                                    </select>
                                </div>
                                <div class="sort-by py-1 px-3 border border-1 rounded-3 d-flex">
                                    <label class="d-flex align-items-center"><i
                                            class="far fa-sort-amount-up me-2"></i>Ordernar:</label>
                                    <select @change="changeFiltersPage()" class="form-select border-0 fs-7" aria-label="" v-model="Filtro.TipoOrden">
                                        <option value="all">Todos</option>
                                        <!-- <option value="prdnew">Producto Nuevo</option> -->
                                        <option value="priceasc">Precio de menor a mayor</option>
                                        <option value="pricedesc">Precio de mayor a menor</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row justify-content-center">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4">
                        <div class="card blog_radio_01 hover-up">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <figure class="blog_mar">
                                      <img src="images/blog/1.jpg" alt="" class="img-fluid blog_radio_02">
                                    </figure>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 position-relative">
                                    <article class="blog_midle p-5">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="mb-4">Con Zumba te vistes y entrenas mejor</h2>
                                            </div>
                                            <div class="col-12">
                                                <p>La parte más importante de nuestra comunidad eres tú, diviértete, baila, ejercitate y muéstrale a todos lo bien que te sientes con Zumba® y lo cómodo que es usar Zumba Wear®.</p>
                                            </div>
                                            <div class="col-12 text-end mt-3">
                                                <a href="#">Leer más <i class="fas fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4">
                        <div class="card blog_radio_01 hover-up">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <figure class="blog_mar">
                                      <img src="images/blog/2.jpg" alt="" class="img-fluid blog_radio_02">
                                    </figure>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 position-relative">
                                    <article class="blog_midle p-5">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="mb-4">Con Zumba te vistes y entrenas mejor</h2>
                                            </div>
                                            <div class="col-12">
                                                <p>La parte más importante de nuestra comunidad eres tú, diviértete, baila, ejercitate y muéstrale a todos lo bien que te sientes con Zumba® y lo cómodo que es usar Zumba Wear®.</p>
                                            </div>
                                            <div class="col-12 text-end mt-3">
                                                <a href="#">Leer más <i class="fas fa-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <nav aria-label="prodcuts navigation">
                        <ul class="pagination grid-pagination">
                            <li class="page-item"><a class="page-link" href="#"><i
                                        class="far fa-arrow-left"></i></a></li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#"><i
                                        class="far fa-arrow-right"></i></a></li>
                        </ul>
                    </nav>
                </div>


            </div>
        </section>
    </main>

    <ProductosSugeridos v-if="!ConfigLoad.ShowLoader" :IdCategoria="5" :pProducto="1" :pTituloSeccion="'También Podrían Interesarte'"/>
  </div>
</template>

<script>
import ProductosSugeridos from '@/views/catalogos/productos/ProductosSugeridos.vue';

export default {
    name: 'ListadoEventos',
    components:{
        ProductosSugeridos
    },
    data() {
        return {
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },

            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 16,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                TipoOrden: 'all',

                RangoPrecio:{
                    min: 100,
                    max: 5000,
                    selected:5000
                },
            },
        };
    },

    created() {
        this.bus.$emit('cambiaLogoNegro');
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>