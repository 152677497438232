<template>
    <main class="main-content mt-0">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                            <template v-if="!bndRegistro">
                                <div class="card card-plain">
                                    <div class="card-header pb-0 text-start">
                                        <h4 class="font-weight-bolder">Iniciar sesión</h4>
                                        <p class="mb-0">Ingrese su correo electrónico y contraseña para iniciar sesión</p>
                                    </div>
                                    <div class="card-body">
                                        <form role="form">
                                            <div class="mb-3">
                                                <input @keyup.enter="keyEnter(0)" v-model="Usuario.email" type="text" placeholder="Nombre del usuario" class="form-control form-control-lg" ref="user">
                                            </div>
                                            <div class="mb-3">
                                            <div class="eye cursor-pointer">
                                                <input @keyup.enter="keyEnter(1)" v-model="Usuario.password" :type="type" class="form-control form-control-lg" placeholder="Contraseña" aria-label="Password" ref="pass">
                                                            
                                                <button v-if="Usuario.password !== ''" @click="ToggleShow" class="button btn-password" type="button" id="button-addon2">
                                                    <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                </button>
                                                <p class="text-end">
                                                    <a @click="recuperarPassword()" class="text-primary text-gradient font-weight-bold" style="cursor: pointer;">Olvidaste tu contraseña?</a>
                                                </p>
                                                
                                            </div>
                                            </div>
                                            <div class="text-center">
                                                <button :disabled="disabled" @click="GetLogin" type="button" class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">
                                                    <i v-if="disabled" class="fad fa-spinner-third fa-spin"></i>
                                                    Iniciar sesión
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="card-footer text-center pt-0 px-lg-2 px-1">
                                        <p class="mb-4 text-sm mx-auto">
                                            ¿No tiene cuenta?
                                            <a @click="verRegistro()" class="text-primary text-gradient font-weight-bold" style="cursor: pointer;">Regístrate</a>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="card card-plain">
                                    <div class="card-header pb-0 text-start">
                                        <h4 class="font-weight-bolder">Registro</h4>
                                        <p class="mb-0">Estas un paso mas cerca de ser parte de la gran comunidad Zumba. Ingresa tus datos para continuar</p>
                                    </div>
                                    <div class="card-body">
                                        <form role="form">
                                        
                                            <div class="mb-3">
                                                <input v-model="objCliente.Nombre" type="text" placeholder="Nombre(s)" class="form-control form-control-lg" ref="nom_cliente">
                                                <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                            </div>
                                            <div class="mb-3">
                                                <input v-model="objCliente.Apellido" type="text" placeholder="Apellidos" class="form-control form-control-lg" ref="apellido_cliente">
                                                <CValidation v-if="this.errorvalidacion.Apellido" :Mensaje="'*'+errorvalidacion.Apellido[0]"/>
                                            </div>
                                            <div class="mb-3">
                                                <input v-model="objCliente.email" type="text" placeholder="Correo Electronico" class="form-control form-control-lg" ref="email_cliente">
                                                <CValidation v-if="this.errorvalidacion.email" :Mensaje="'*'+errorvalidacion.email[0]"/>
                                            </div>

                                            <div class="mb-3">
                                                <div class="cursor-pointer">
                                                    <input v-model="objCliente.password" :type="type" class="form-control form-control-lg" placeholder="Contraseña" aria-label="Password" ref="pass_cliente">
                                                    <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>

                                                    <button v-if="objCliente.password !== ''" @click="ToggleShow" class="button btn-password" type="button" id="button-addon2">
                                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <div class="mb-3">
                                                <div class="cursor-pointer">
                                                    <input v-model="objCliente.password_confirmation" :type="type" class="form-control form-control-lg" placeholder="Confirma tu Contraseña" aria-label="password_confirmation" ref="pass_confirm_cliente">
                                                    <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                                </div>
                                            </div>

                                            <div class="text-center">
                                                <button :disabled="disabled" @click="setRegistro()" type="button" class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">
                                                    <i v-if="disabled" class="fad fa-spinner-third fa-spin"></i>
                                                    Continuar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="card-footer text-center pt-0 px-lg-2 px-1">
                                        <p class="mb-4 text-sm mx-auto">
                                            ¿Ya tienes una cuenta?
                                            <a @click="closeRegistro()" class="text-primary text-gradient font-weight-bold" style="cursor: pointer;">Inicia Sesión</a>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden" :style="cssProps">
                                <span class="mask bg-gradient-primary opacity-6"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
            <template slot="Form">
                <FormRecuperaPassword :poBtnSave="oBtnSave" />
            </template>
        </CModal>

    </main> 
</template>

<script>
    import CModal from '@/components/CModal.vue';
    import CValidation from '@/components/CValidation.vue';
    import FormRecuperaPassword from './componentes/FormRecuperaPassword.vue';

    const EmitEjecuta = 'SeccionLogin';
    export default {
        name:       'Login',
        props:      [""],
        components: {
            CValidation,
            CModal,
            FormRecuperaPassword
        },
        data() {
            return {                
                type:            'password',
                showPassword:    false,
                password:        null,
                disabled:        false,
                errorvalidacion: [],
                Usuario: {
                    email: '',
                    password: '',
                },
                cssProps: {
                    backgroundImage: `url(${require('@/assets/style/images/promo-banner.jpg')})`,
                    backgroundSize: 'cover'
                    
                },
                Icono: 'eye cursor-pointer',

                bndRegistro: false,
                objCliente:{
                    Nombre: '',
                    Apellido: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                },

                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  true,
                },

                ConfigModal:{
                    ModalTitle: "Recuperar contraseña",
                    ModalNameId: 'ModalForm',
                    EmitSeccion: EmitEjecuta,
                    ModalSize: 'md',
                    ShowFooter: false,
                },

                oBtnSave: {
                    Toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitEjecuta,
                    TxtBtnSave: 'Enviar', 
                    IconoSave: 'fas fa-paper-plane'
                },
            }
        },
        methods: {
            async GetLogin() {

                if (this.Usuario.email !== '' && this.Usuario.password !== '') {

                    this.disabled = true;

                    this.$http.post('login', this.Usuario).then((res) =>  {

                        this.disabled = false;

                        if (res.data.status) {
                            this.$store.dispatch('login',      res.data);

                            let fromUrl = localStorage.getItem('fromUrl');

                            if(fromUrl != undefined && fromUrl != null && fromUrl != ''){
                                this.$router.push({name: fromUrl,params:{}});
                            }else{
                                this.$router.push({name: "home", params: {}});
                            }
                            
                            
                        } else {
                            this.$toast.warning('Usuario o Contraseña Incorrectos');
                        }

                    })
                    .catch((err) => {
                        this.disabled = false;
                        this.$toast.error(err.response.data.message, 'Error');
                        this.$store.commit('auth_error');
                        this.$store.localStorage.removeItem('user_token');
                        this.$store.reject(err);
                    });
                } else {
                    this.$toast.error('Ingrese los campos faltantes', 'Campos Incompletos,');
                }

            },

            keyEnter(num) {

                if (this.Usuario.email !== '' && this.Usuario.password !== '') {
                    this.GetLogin();
                } else if (num === 0  && this.Usuario.password === ''){
                    this.$refs.pass.focus();
                } else if (num === 1 && this.Usuario.email === ''){
                    this.$refs.user.focus();
                }
            },

            ToggleShow() {

                if (this.showPassword = !this.showPassword) {                    
                    this.type = 'text';
                    this.Icono = 'eyeClose cursor-pointer';
                } else {
                    this.type = 'password';
                    this.Icono = 'eye cursor-pointer';
                }
                
            },

            verRegistro() {
                this.bndRegistro = true;
            },

            closeRegistro() {
                this.bndRegistro = false;
                this.limpiarCliente();
            },

            setRegistro() {
                this.errorvalidacion    = [];
                this.disabled           = true; 

                this.$http.post('createaccount', this.objCliente)
                .then((res) =>  {
                    this.EjecutaConExito(res)
                })
                .catch((err) => {
                    this.EjecutaConError(err);
                });
            },

            EjecutaConExito(res)
            {      
                this.disabled = false; 
                if(res.data.data.mailStatus == "Unsend") {
                    this.$toast.info(res.data.message,'¡Oh no!');
                }else {
                    this.$toast.success(res.data.message,'¡Exito!');
                    this.$router.push({name:'principal',params:{} });
                }
                
                this.ConfigLoad.ShowLoader = false;
            },

            EjecutaConError(err)
            {
                this.disabled = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.$toast.warning('Complete los campos');
                }
                else{
                    this.$toast.error(err.response.data.message);
                }

                this.ConfigLoad.ShowLoader = false;
            },

            limpiarCliente() {
                this.objCliente = {
                    Nombre: '',
                    Apellido: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                };
            },

            recuperarPassword(){
                this.bus.$emit('NewModal_'+EmitEjecuta);
                //this.bus.$emit('HideFooter_'+EmitEjecuta,false);
            },
        },
        created() {
            // SI EXISTE SESSIÓN REGRESAMOS A LA VISTA PRINCIPAL (HOME)
            var datos = JSON.parse( localStorage.getItem('user'));

            if(datos != null){
                this.$router.push({name: "home", params: {}});
            }

        },
        mounted() {
            this.$refs.user.focus();
        },
    }

</script>
<style>
.eye {
    position: relative
  }
  .eye:after {
    font-family: 'Font Awesome 5 Pro' !important;
    content: '\f06e';
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 900;
    color:#e0e0e0;
}
</style>
