<template>
    <div>
        <section class="section-01 alturaBase">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h2 class="color_azul big-tirulo bolt mb-4">Politicas para cambios y devoluciones</h2>
                                <ul class="list-icon-01 list-01">
                                    <li>
                                        Los cambios y devoluciones podrán aplicarse únicamente en productos adquiridos en nuestra pagina <a class="color_azul-02" href="https://www.zumbashop.mx/">www.zumbashop.mx.</a> Para hacer valido tu cambio o devolución deberás
                                        regresar tu producto en las mismas condiciones que lo recibiste, junto con las etiquetas, empaque original y numero de pedido.
                                    </li>
                                    <li>
                                        La mercancía nueva, en su empaque original, con etiquetas puestas, que no muestre rastros de uso tales como manchas, huella de desodorante, grasa o aromas, podrá ser devuelta dentro de los primeros 10 días a partir de la fecha de envío, por crédito en
                                        la tienda.
                                    </li>
                                    <li>
                                        La mercancía devuelta, que no cumpla con los requisitos anteriores, podrá ser devuelta al cliente, siempre y cuando este pague los gastos de envío dentro de las primeras 48 horas que se le notifique.
                                    </li>
                                    <li>
                                        Si el cliente no cubre los gastos de envío en el tiempo solicitado, la mercancía será desechada en la manera que <b class="bold">Zumbashop.mx</b> lo decida.
                                    </li>
                                    <li>
                                        No hay devolución en mercancía de descuento.
                                    </li>
                                    <li>
                                        Las transacciones serán efectuadas mediante la pasarela de Openpay
                                    </li>
                                    <li>
                                        No se hacen devoluciones de efectivo ni cargos a tarjeta de crédito.
                                    </li>
                                    <li>
                                        Para realizar una devolución deberá presentar su recibo de compra.
                                    </li>
                                    <li>
                                        El crédito será aplicado en su cuenta, una vez que la mercancía ha sido recibida y se encuentre en perfecto estado.
                                    </li>
                                    <li>
                                        Los gastos de devolución corren por cuenta del cliente.
                                    </li>
                                    <li>
                                        En caso de que la mercancía cuente con un defecto de fábrica, la mercancía será aceptada y revisada. En este caso el cliente recibirá una devolución de acuerdo a su forma de pago.
                                    </li>
                                    <li>
                                        La mercancía adquirida en entrenamientos, clases o a través de terceras personas, no puede ser devuelta en <b class="bold">Zumbashop.mx</b>
                                    </li>
                                    <li>
                                        Para hacer una devolución, envíanos un correo a <a class="color_azul-02" href="mailto:devoluciones@zumbashop.mx">devoluciones@zumbashop.mx</a> y te haremos llegar instrucciones.
                                    </li>
                                </ul>
                            </div>
                </div>
          </div>
      </section>
  
    
      <br>
      <br>
  
      
  
    </div>
  </template>
  
  <script>
  //import metaList from "../../../helpers/metaSocialNetworks";
  export default {
    name:'Terminos',
      /*metaInfo() {
          return {
              title: "Zumba Wear®",
              meta: metaList
          }
      },*/

      created(){
        window.scrollTo(0, 0);
        this.bus.$emit('cambiaLogoNegro');
        
      }
  }
  </script>
  
  <style>
    .alturaBase {
        margin-top: 10%
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .imagen_banner-02 {
        background-repeat: no-repeat;
        background-position: 50%;
        -ms-flex-line-pack: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
        -ms-flex-pack: center;
        align-content: center;
        display: flex;
    }
    .text-conten-img {
        
        left: 10%;
        padding: .25rem .75rem;
        color: #fff!important;
        height: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color_blanco {
        color: #fff !important;
    }
    .superbig {
        font-size: 3.8rem;
    }
    @media (max-width: 860px) {
        .alturaBase {
            margin-top: 20%
        }
    }

    @media (max-width: 540px) {
        .alturaBase {
            margin-top: 25%
        }
        
    }


    @media (max-width: 414px) {
        .alturaBase {
            margin-top: 25%
        }
    }
  </style>
  