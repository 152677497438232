<template>
  <div class="container-fluid alturaBase">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="email-container mb-4">
                    <div class="row justify-content-center mb-4">
                        <div class="col-11 text-center mt-2">
                            <img src="@/assets/images/logo_zumba.png" alt="zumbashop" class="img-fluid" width="40%">
                        </div>
                        <div class="col-11 mt-4">
                            <div class="card card-produc position-relative">
                                <div class="card-body pt-3 text-center">

                                        <div v-if="usuarioAccount.visible">
                                            <div class="row mt-2">
                                                <div class="col-md-12">
                                                    <h4>Restablecer Contraseña</h4>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="form-group col-md-12 text-start">
                                                    <label class="mb-2">Nueva Contraseña</label>
                                                    <div class="eye cursor-pointer">
                                                        <input v-model="usuarioAccount.password" :type="type" class="form-control form-control-lg" placeholder="Ingresa la nueva contraseña" aria-label="Password" ref="pass_cliente">
                                                        <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>

                                                        <button v-if="usuarioAccount.password !== ''" @click="ToggleShow" class="button btn-password" type="button" id="button-addon2">
                                                            <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                        </button>
                                                    </div>

                                        
                                                </div>
                                            </div>
                                            
                                            <div class="row mt-2">
                                                <div class="form-group col-md-12 text-start">
                                                    <label class="mb-2">Confirmar Contraseña</label>
                                                    <div class="eye cursor-pointer">
                                                        <input v-model="usuarioAccount.password_confirmation" :type="type" class="form-control form-control-lg" placeholder="Confirma la Contraseña" aria-label="password_confirmation" ref="pass_confirm_cliente">
                                                        <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="row mt-2">
                                                <div class="col-md-12 text-start">
                                                    <p class="mt2">
                                                        Elige muy bien tus credenciales, No utilices las mismas contraseñas en todos lados, recuerda que <i>"una contraseña es tan importante como los datos que protege".</i>  
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="text-center mt-4">
                                                <button type="button" @click="Regresar()" class="btn btn-secondary ms-2 btn-w">Ir a Inicio</button>
                                                <button type="button" @click="Guardar()" :disabled="oBoton.Disablebtn" class="btn btn-primary ms-2 btn-w">
                                                    <i v-if="oBoton.Disablebtn" class="fad fa-spinner-third fa-spin"></i>
                                                    {{oBoton.NameOk}}</button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="text-center">
                                                <h4>{{textDefault}}</h4>
                                                <br>
                                                <button type="button" @click="Regresar()" class="btn btn-primary mb-4">Ir a Inicio</button>
                                            </div>
                                        </div>
                                  
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CValidation from '@/components/CValidation.vue';
export default {
    name: 'RecuperaCuenta',
    components:{
        CValidation
    },
    data() {
        return {
            MasterKey: '',
            MasterId: 0,
            MasterNum: 0,

            Disablebtn:false,
            showPassword: false,
            type: 'password',
            usuarioAccount:{
                Key: this.MasterKey,
                Id: this.MasterId,
                Num: this.MasterNum,
                password: '',
                password_confirmation: '',
                visible: false,
            },
            textDefault: 'Lo sentimos pero el link no es válido o ya expiro, por favor vuelva a intentarlo de nueva cuenta.',
            errorvalidacion:[],
            oBoton:{
                Disablebtn:false,
                NameOk:'Restablecer'
            }
        };
    },

    created() {
        this.MasterKey  = this.$route.params.key;
        this.MasterId   = this.$route.params.id;
        this.MasterNum  = this.$route.params.num;

        this.validateTokenPass();
    },

    mounted() {
        
    },

    methods: {
        validateTokenPass() {
            this.usuarioAccount.visible = false;
            let resquest = {
                Key: this.MasterKey,
                Id: this.MasterId,
                Num: this.MasterNum
            }
            this.$http.post('usrvalidreset',resquest).then((res) => {
                this.usuarioAccount.visible = true;
                this.usuarioAccount.Key = this.MasterKey;
                this.usuarioAccount.Id = this.MasterId;
                this.usuarioAccount.Num = this.MasterNum;
            }).catch( err => {
                this.usuarioAccount.visible = false;
            });
        },

        Guardar() {
            this.BloquearBotones(0);
            this.$http.post('usrchangecredentias', this.usuarioAccount,).then( (res) => {
                this.BloquearBotones(1);

            }).catch( err => {
                
                this.BloquearBotones(2);
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }
            });
        },

        Regresar () {
            this.$router.push({name:'login', params:{}})
        },

        BloquearBotones(acc){
            this.disable_buttons(false);
            if(acc===1){
                this.$swal({
                    title: '¡Exito!',
                    text: '¡Su contraseña ha sido reestablecida, ahora podra ingresar de nueva cuenta.!',
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonText: '¡Aceptar!',
                    showCloseButton: false,
                    showLoaderOnConfirm: true,
                    icon:'success'
                }).then((result) => {
                    this.usuarioAccount.password = '';
                    this.usuarioAccount.password_confirmation = '';
                    this.usuarioAccount.visible = false;
                    this.textDefault = 'Contraseña Actualizada, Gracias.'
                });
            }
            else if(acc===2){
                this.$toast.warning('Complete los campos');
            }
            else{
                this.disable_buttons(true);
            }
        },

        disable_buttons(bnd){
            this.oBoton.Disablebtn = false;
            this.oBoton.NameOk = 'Restablecer';
            if(bnd){
                this.oBoton.Disablebtn = true;
                this.oBoton.NameOk=' Espere...';
            }
        },

        goHome() {
            this.$router.push({ name :'principal',params:{}});
        },

        ToggleShow() {

            if (this.showPassword = !this.showPassword) {                    
                this.type = 'text';
                this.Icono = 'eyeClose cursor-pointer';
            } else {
                this.type = 'password';
                this.Icono = 'eye cursor-pointer';
            }

        },
    },  
};
</script>