// AQUI VAN LAS IMPORTACIONES DE COMPONENTS
import vue              from 'vue';
//import CAvatar          from '@/components/CAvatar.vue';
//import CBtnAccion       from '@/components/CBtnAccion.vue';
//import CBtnAccionForm   from '@/components/CBtnAccionForm.vue';
import CBtnSave         from '@/components/CBtnSave.vue';
//import CList            from '@/components/CList.vue';
//import CLoader          from '@/components/CLoader.vue';
//import CMapa            from '@/components/CMapa.vue';
import CModal           from '@/components/CModal.vue';
//import CPagina          from '@/components/CPagina.vue';
//import CPdf             from '@/components/CPdf.vue';
//import CSinRegistro     from '@/components/CSinRegistros.vue';
//import CTabla           from '@/components/CTabla.vue';
//import CTablita         from '@/components/CTablita.vue';
//import CValidation      from '@/components/CValidation.vue';
//import Template         from '@/views/template/Template.vue';
//import CNavLinks        from '@/components/CNavLinksMenu.vue';
//import CMoreButtons     from '@/components/CMoreButtons.vue';
//import Datepicker       from 'vuejs-datepicker/dist/vuejs-datepicker';
//import CFooter          from '@/components/CFooter.vue';
//import CMoreButtonsList from '@/components/CMoreButtonsList.vue';


// AQUI VAN LOS COMPONENTS DE LAS IMPORTACIONES
//vue.component('CAvatar',        CAvatar);
//vue.component('CBtnAccion',     CBtnAccion);
//vue.component('CBtnAccionForm', CBtnAccionForm);
vue.component('CBtnSave',       CBtnSave);
//vue.component('CList',          CList);
//vue.component('CLoader',        CLoader);
//vue.component('CMapa',          CMapa);
vue.component('CModal',         CModal);
//vue.component('CPagina',        CPagina);
//vue.component('CPdf',           CPdf);
//vue.component('CSinRegistros',   CSinRegistro);
//vue.component('CTabla',         CTabla);
//vue.component('CTablita',       CTablita);
//vue.component('CValidation',    CValidation);
//vue.component('Template',       Template);
//vue.component('CNavLinks',      CNavLinks);
//vue.component('CMoreButtons',   CMoreButtons);
//vue.component('Datepicker',     Datepicker);
//vue.component('CFooter',        CFooter);
//vue.component('CMoreButtonsL',  CMoreButtonsList);