<template>
    <div class="sidebar-banner d-none d-lg-block">
        <div class="promo-banner rounded-3 d-flex align-items-center px-4" 
        v-bind:style="{ 'background-image': 'url(' + RutaFlyer+obFlyer.IdFlyerPromocion+'/'+obFlyer.UrlImagen + ')' }">
            
            <p @click="redirectFlyer(obFlyer)" style="cursor: pointer;" class="fs-5 fw-bold text-center bg-white text-morado rounded-3 px-1 py-2">
                {{ obFlyer.Titulo }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlyerPromVertical',

    data() {
        return {
            obFlyer: {
                IdFlyerPromocion: 0,
                Titulo:'',
                TipoUso: 0,
                TipoFlyer: 0,
                IdCategoria: 0,
                IdSubCategoria: 0,
                IdProducto: 0,
                NomProducto: '',
                IdEvento: 0,
                UrlImagen: '',
            },
            RutaFlyer: ''
        };
    },

    mounted() {
        this.listFlyersProm();
    },

    methods: {
        async listFlyersProm() {

            await this.$http.get("flyersprom",{
                params: {
                    Entrada:1,
                    TipoUso: 2
                }

            }).then((res) => {
                //console.log(res.data);
                this.obFlyer      = res.data.data;
                this.RutaFlyer     = res.data.rutaFile;
                

            }).finally(()=> {
                
            });
        },

        redirectFlyer(item) {
            
            switch ( parseInt(item.TipoFlyer) ) {
                case 1:
                    if(item.IdSubCategoria != null && item.IdSubCategoria != undefined && parseInt(item.IdSubCategoria) > 0){
                        this.viewListProducto(item.IdSubCategoria,0,'');
                    }else {
                        this.viewListProducto(item.IdCategoria,0,'');
                    }
                    break;
                case 2:
                    this.$router.push({name:'viewproducto',
                        query:{
                            'cat':item.IdCategoria,
                            'prod':item.IdProducto,
                            'feat':0,
                            'nomcat':'',
                            'nomp':item.NomProducto.replace(/\s+/g, '-')
                        }
                    });
                    
                    break;

                case 3:
                    this.$router.push({name: 'viewevento', params:{Id:item.IdEvento}});
                    break;
            
            }
            
        }

    },
};
</script>