<template>
    <div>
        <main style="margin-top: 92px;">
            <section class="mt-3">
                    <div class="container container-width">
                        <div class="bg-wearwall rounded-5 p-5">
                            <div class="row align-items-center">
                                <div class="col-xl-3">
                                    <h1 class="text-white">{{ WearWall.Titulo }}</h1>
                                    <div class="d-inline-block p-0 text-capitalize m-0">
                                        <nav aria-label="breadcrumb"
                                            style="--bs-breadcrumb-divider: '>'; font-family: 'Lato', sans-serif;">
                                            <ul class="breadcrumb fs-7">
                                                <li class="breadcrumb-item"><a class="text-white fw-bold"
                                                    @click="$router.push({ name :'principal',params:{}});" style="cursor: pointer;">
                                                    <i class=" text-white far fa-house me-2"></i>Home</a>
                                                </li>
                                                <li class="breadcrumb-item active fw-bold text-white">WearWall</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div class="col-xl-9 text-end d-none d-xl-block">
                                    
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row flex-row-reverse my-5">
                                <div class="col-12 col-md-4 col-lg-12 col-xl">
                    
                                    <div class="product-grid">
                                        <div class="row">
                                            <div v-for="(item, index) in WearWall.detalle" :key="index" class="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
                                                <div class="card card-produc position-relative h-100">
                        
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <figure class="m-0">
                                                                    <img :src="RutaWearwall+WearWall.IdWearwall+'/'+item.Imagen" alt="" class="img-fluid rounded" />
                                                                </figure>
                                                                <p class="mt-0">
                                                                    <span class="text-body-tertiary fs-8">Eventos</span>
                                                                </p>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="fs-6 mb-1">
                                                                    <a href="./product_view.html" class="text-darker hover-purple-text">
                                                                        {{ item.PieImagen }}
                                                                    </a>
                                                                </h2>
                                                            </div>
                                                            
                                                            <div class="col-12">
                                                                <p v-if="item.Participantes != null && item.Participantes != undefined" class="mt-0 mb-2 fs-7">
                                                                    By
                                                                    <a class="text-morado">{{ item.Participantes }}</a>
                                                                </p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ProductosSugeridos v-if="!loaderSeccion" :IdCategoria="0" :pProducto="0" :pTituloSeccion="'Recomendaciones'"/>
        </main>
    </div>
  
</template>

<script>
import ProductosSugeridos from "@/views/catalogos/productos/ProductosSugeridos.vue";

export default {
    name: 'ViewWearWall',
    components:{
        ProductosSugeridos
    },

    data() {
        return { 
            loaderSeccion: true,  
            RutaWearwall: '',
            WearWall:{
                IdWearWall: 0,
                Activo: 0,
                ImgCover: '',
                Titulo: '',
                detalle: []
            },

            IdCategoria: 0,
            producto:{
                IdProducto:0
            }
        };
    },

    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0,0);

        if(sessionStorage.getItem("IdWall") != undefined){
            this.WearWall.IdWearWall = sessionStorage.getItem("IdWall");
        }
        
        this.recoveryWearwall();
        
    },

    methods: {
        async recoveryWearwall() {
            this.loaderSeccion = true;
            await this.$http.get("wearwallview",{params:{}})
            .then((res) => {
                console.log(res.data);
                this.WearWall      = res.data.data;
                this.RutaWearwall  = res.data.rutaFile;
                sessionStorage.setItem("IdWall",this.WearWall.IdWearWall);

            }).finally(()=> {
                this.loaderSeccion = false;
            });
        },

        
    },
};
</script>