<template>
  <section v-if="listPrdOfertas.length > 0" class="container mb-5">
        <div class="row">
            <div class="col-lg-12 section-title mb-4">
                <div class="row">
                    <div class="col-12 col-lg">
                        <h3>{{ TituloSeccion }}</h3>
                    </div>
                    <div class="col-12 col-lg mt-2">
                        <ul class="d-flex list-unstyled justify-content-lg-end">
                            <li>
                                <a @click="viewListProducto(8,0,'')" style="cursor: pointer;"
                                    class="text-decoration-none text-darker fw-medium me-2 ps-0 hover-purple-text">
                                    Ver todos
                                    <i class="mx-1 fa fa-chevron-right fs-7"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-for="(item,indx) in listPrdOfertas" :key="indx" 
                :class="(indx+1) < listPrdOfertas.length ? 'col-12 col-md-6 col-lg-4 col-xl-3' : 'col-12 col-md-6 d-lg-none d-xl-block col-xl-3' " >
                <div class="row h-100">
                    <div class="col-12 limited-deal">
                        <figure>
                            <img :src="RutaProducto+item.IdProducto+'/'+item.imagenescolor[0].Imagen" alt="" class="img-fluid rounded-3" />
                        </figure>
                        <div class="row limited-deal_desc">
                            <div class="card-info col-11 p-3 mx-auto position-relative">
                                <div class="countdown">
                                    <countdown v-if="getTime(item) > 0" :time="getTime(item)" :interval="100" v-slot="{ days, hours, minutes, seconds}">
                                                
                                        <div class="row mb-2">
                                            <div class="simple-timer syotimer">
                                                <div class="syotimer__head"></div>
                                                <div class="syotimer__body">
                                                    <div class="syotimer-cell syotimer-cell_type_day">
                                                        <div class="syotimer-cell__value" style="opacity: 1;">{{ days }}</div>
                                                        <div class="syotimer-cell__unit">dias</div>
                                                    </div>
                                                    <div class="syotimer-cell syotimer-cell_type_hour">
                                                        <div class="syotimer-cell__value" style="opacity: 1;">{{ hours }}</div>
                                                        <div class="syotimer-cell__unit">horas</div>
                                                    </div>
                                                    <div class="syotimer-cell syotimer-cell_type_minute">
                                                        <div class="syotimer-cell__value" style="opacity: 1;">{{ minutes }}</div>
                                                        <div class="syotimer-cell__unit">min</div>
                                                    </div>
                                                    <div class="syotimer-cell syotimer-cell_type_second">
                                                        <div class="syotimer-cell__value" style="opacity: 1;">{{ seconds }}</div>
                                                        <div class="syotimer-cell__unit">seg</div>
                                                    </div>
                                                </div>
                                                <div class="syotimer__footer"></div>
                                            </div>
                                        </div>

                                        
                                        
                                    </countdown>

                                    <h5 v-if="getTime(item) == 0" style="font-size: 1.2em; color:#bbd540;">La oferta ha terminado!</h5>
                                    
                                </div>
                                <div class="produc-info bg-white rounded-4 row p-3 light-boxshadow">
                                    <div class="col-12">
                                        <h2 class="fs-6 mb-1">
                                            <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;" class="text-darker hover-purple-text">
                                                {{ item.Nombre }}
                                            </a>
                                        </h2>
                                    </div>
                                    
                                    <div  class="col-12">
                                        <p class="mt-0 mb-2 fs-7 fw-medium" v-if="item.categoriaprincipal != null && item.categoriaprincipal != undefined">
                                            By
                                            <a @click="viewListProducto(item.categoriaprincipal.IdCategoria,0,'')" style="cursor:pointer" class="text-morado">
                                                {{ item.categoriaprincipal.Nombre }}
                                            </a>
                                        </p>
                                       
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                
                                                <template v-if="parseInt(item.TieneRebaja) > 0">
                                                    <span class="fw-bolder w-auto fs-6">{{ $formatNumber(item.PrecioFinal,'$') }} </span>
                                                    <span class="text-decoration-line-through fs-8"> {{ $formatNumber(item.Precio,'$') }}</span>
                                                </template>
                                                <template v-else>
                                                    <span class="fw-bolder w-auto fs-6">{{ $formatNumber(item.Precio,'$') }}</span>
                                                    
                                                </template>


                                            </div>
                                            <div v-if="item.categoriaprincipal != null && item.categoriaprincipal != undefined && getTime(item) > 0" class="ms-2">
                                                <button @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" class="btn btn-primary-2 fs-7 text-nowrap pasarela">
                                                    <i class="far fa-shopping-bag"></i>
                                                    Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    name: 'ProductosOfertas',
    props:['pTituloSeccion'],

    data() {
        return {
            TituloSeccion: 'Productos en Oferta',
            showLoadingOfertas: true,
            listPrdOfertas:[],
            RutaProducto: ''
        };
    },

  

    methods: {
        async listProductosOferta() {
            this.showLoadingOfertas = true;

            let newRequest = {
                txtBusqueda: '',
                Entrada: 4,
                Pag: 1,

                isOulet: 1,
            }

            await this.$http.post("productos",newRequest)
            .then((res) => {
                this.listPrdOfertas = res.data.data.data;
                this.RutaProducto   = res.data.rutaFile;

            }).finally(()=>{
                this.showLoadingOfertas = false;
            });
        },

        getTime(item){
            const actual = new Date();
            const final = new Date(item.FinRebaja);

            let tiempoFaltante = (final - actual);
            //console.log(tiempoFaltante);
            if(tiempoFaltante < 0){
                return 0;
            }else{
                return tiempoFaltante;
            }
        },

        viewDetalle(idcategoria,nomcategoria,producto) {
            this.$router.push({name:'viewproducto',
                query:{
                    'cat':idcategoria,
                    'prod':producto.IdProducto,
                    'feat':0,
                    'nomcat':nomcategoria.replace(/\s+/g, '-'),
                    'nomp':producto.Nombre.replace(/\s+/g, '-')
                }
            });
        },

        viewListProducto(idCat = 0,isTop = 0,textbusqueda = '') {
            if(this.$route.name == "productos"){

                let parametros = {
                        pIdCategoria: idCat, 
                        pTextBusqueda: textbusqueda,
                        pIsTop: isTop,
                    };

                this.bus.$emit('CambiarCategoria2',parametros);

            }else{

                this.$router.push({ name :'productos',params:{
                        pIdCategoria: idCat, 
                        pTextBusqueda: textbusqueda,
                        pIsTop: isTop, 
                        
                    }
                });
            }
        }
    },

    created() {
        if(this.pTituloSeccion != undefined && this.pTituloSeccion != null && this.pTituloSeccion != '') {
            this.TituloSeccion = this.pTituloSeccion;
        }
    },

    mounted() {
        this.listProductosOferta();
    },
};
</script>