<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <div class="card" style="border-color:#fff;">
                <div class="card-body">
                    <p class="card-text">
                        <strong>Pedido# </strong>{{ objPedido.Folio }}<br>
                        <strong>Metodo de Pago:</strong> {{ objPedido.forma_pago.Nombre }} <br>
                        <strong>Monto Total:</strong> {{ $formatNumber(objPedido.GranTotal,'$') }} <br>
                    </p>

                    <div class="form-group row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="avatar-upload">
                                <div class="avatar-edit">
                                    <input type="file" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview'))" id="file" name="file" ref="fileImg" accept=".png, .jpg, .jpeg">
                                    <label for="file"></label>
                                </div>
                                <div class="avatar-preview">
                                    <div id="imagePreview" :style="'background-image: url('+Img+')'" :src="Img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CLoader>
</template>

<script>
import CLoader from '@/components/CLoader.vue';
import VarConfig from '@/helpers/VarConfig';

export default {
    name: 'FormEvidenciaPago',
    props:['poBtnSave','pPedido'],
    components: {
        CLoader
    },

    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },

            objPedido: {
                IdPedido: 0,
                Folio: '',
                Cliente: '',
                forma_pago:{
                    Nombre:''
                }
            },

            objEvidencia:{
                IdEvidenciaPago: 0,
                IdPedido: 0,
                Archivo: ''
            },

            Emit: this.poBtnSave.EmitSeccion,
            ValidElement: VarConfig.validImage3m,
            Img: '',
            rutaFile:'',
            errorvalidacion:[],
        };
    }, 

    created() {
		this.poBtnSave.Toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id, obj)=>
        {   
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            
            this.Limpiar();
                
            if (obj != undefined && obj != null) 
            {
                this.objPedido = obj;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }

        });
    },

    mounted() {
        if(this.pPedido != undefined && this.pPedido != null) {
            this.objPedido = this.pPedido;
        }
    },

    methods: {
        Recuperar() {
            //console.log('Recuperando');

            this.$http.get("evidenciaspagos/"+this.objPedido.IdPedido)
            .then((res) => {

                if(res.data.status) {
                    this.objEvidencia = res.data.data;

                    if(this.objEvidencia.Archivo === '') {
                        this.Img  = res.data.rutaFile+'vacio.jpg';
                    }else {
                        this.Img  = res.data.rutaFile+this.objEvidencia.IdPedido+'/'+this.objEvidencia.Archivo;
                    }
                }
                
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },

        async Guardar() {
            console.log('Guardando');
            let formData = new FormData();
            let Imagen = this.$refs.fileImg.files[0];

            if(Imagen === undefined && this.objEvidencia.IdEvidenciaPago == 0){
                this.$toast.warning('Debe agregar una imagen');
                return false;
            }

			formData.set('IdEvidenciaPago', this.objEvidencia.IdEvidenciaPago);
			formData.set('IdPedido',        this.objPedido.IdPedido);

			formData.append('Archivo',Imagen);
			
			this.errorvalidacion = [];
            this.poBtnSave.Toast = 0; 
            this.poBtnSave.DisableBtn = true;

			
            await this.$http.post('evidenciaspagos',formData,{
                headers: {
					'Content-Type': 'multipart/form-data'
				}
            })
            .then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
        },

        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.Toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.Toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },


        Limpiar() {
            this.objPedido = {
                IdPedido: 0,
                Folio: '',
                Cliente: '',
                forma_pago:{
                    Nombre:''
                }
            };

            this.objEvidencia = {
                IdEvidenciaPago: 0,
                IdPedido: 0,
                Archivo: ''
            };
        }
    },
};
</script>