<template>
    <div>
    <section class="section-01 alturaBase">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h1 class="bolt-02 mb-4">Contacto</h1>
                    <div class="imagen_banner-03" :style="{'background-image': 'url(' + require('@/assets/style/images/banner-wearwall.png') + ')'}" >
                        <div class="text-conten-img-02">
                            <div class="row justify-content-center">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 mt-3">
                                    <h1 class="color_blanco titulo-contacto-01 bolt-03 womby-regular text-center">
                                        EN QUE PODEMOS
                                    </h1>
                                    <h1 class="color_blanco titulo-contacto-02 bolt-03 womby-regular text-center">
                                        AYUDARTE?
                                    </h1>
                                </div>
                                <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                    <div class="form-group">
                                        <label>Nombre</label>
                                        <input type="text" class="form-control" v-model="Contacto.Nombre" placeholder="Nombre">
                                    </div>
                                    <div class="form-group">
                                        <label>Teléfono</label>
                                        <input type="text" class="form-control" v-model="Contacto.Telefono" placeholder="Telefono">
                                    </div>
                                    <div class="form-group">
                                        <label>Correo</label>
                                        <input type="text" class="form-control" v-model="Contacto.Correo" placeholder="Correo">
                                        
                                    </div>
                                    <div class="form-group">
                                        <label>Mensaje</label>
                                        <textarea name="" id="" v-model="Contacto.Mensaje" class="form-control textarea" rows="8"></textarea>
                                    </div>
                                    <div class="form-group text-end mt-4 mb-3">
                                        <button type="button" :disabled="loadingSave" @click="SendContac()" class="btn btn-verde col-2">
                                            Enviar Mensaje
                                            <template v-if="loadingSave">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span class="sr-only"></span>
                                            </template>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-01 mt-5">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                    <h3 class="bolt-02 mb-4">Nuestra ubicación</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.7343605238502!2d-89.55416624935894!3d21.003282793954483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjHCsDAwJzExLjgiTiA4OcKwMzMnMDcuMSJX!5e0!3m2!1ses!2smx!4v1622701966534!5m2!1ses!2smx"
                        class="mapa" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                </div>
            </div>
        </div>
    </section>

    <section class="section-01 mt-5">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
                    <h3 class="bolt-02 mb-3">Garantías</h3>
                    <p class="color_cafe big mb-5">
                        Los productos que distribuimos cuentan con una garantía de 30 días naturales que podrás hacer válida en nuestras oficinas ubicadas en la dirección de la oficina
                    </p>
                    <h3 class="bolt-02 mb-3">Pagos</h3>
                    <p class="color_cafe big mb-5">
                        Podrás realizar el pago por tus productos a través de diversos medios como tarjetas de crédito o débito, transferencia bancaria, PayPal o mediante una tienda de conveniencia, si tienes alguna duda comúnicate a nuestro teléfono o utiliza nuestro formulario
                        de contacto.
                    </p>
                </div>
            </div>
        </div>
    </section>

  </div>
</template>
  
  <script>

  export default {
    name:'Terminos',
    
    data() {
        return {
            Contacto:{
                Nombre:'',
                Telefono:'',
                Correo:'',
                Mensaje:''
            },

            errorvalidacion:[],
            loadingSave:false
        }
    },
    methods: {

        SendContac(){

            this.loadingSave = true;
            this.errorvalidacion=[];


            this.$http.post('sendmailcontact',
                this.Contacto
            ).then( (res) => {

                if (res.data.status) {

                    this.toast.success("Su solicitud de información ha sido enviada con éxito !!!");

                    this.loadingSave = false;

                }

            }).catch( (err) => {

                this.loadingSave = false;
                if(err.response.data.typemsj==2){
                    this.errorvalidacion=err.response.data.errors;
                }else{
                    this.$toast.error(err.response.data.message);
                }
            });
        },
    },


    created(){
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);
    }

  }
  </script>
  
  <style>
    .alturaBase {
        margin-top: 10%
    }

    .mapa {
        width: 100%;
        height: 776px;
    }

    .text-conten-img-02, .text-conten-img-03 {
        
        left: 6%;
        padding: .25rem .75rem;
        color: #fff !important;
    }
    .imagen_banner-03 {
        background-repeat: no-repeat;
        background-position: 50%;
        -ms-flex-line-pack: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
        -ms-flex-pack: center;
        align-content: center;
        display: flex;
    }

    .titulo-contacto-01 {
        font-size: 80px;
        text-shadow: .1em 0 .1em #333;
    }

    .titulo-contacto-02 {
        font-size: 139px;
        text-shadow: .1em 0 .1em #333;
    }
    .womby-regular {
    font-family: womby-regular, sans-serif;
}

    .text-conten-img {
        
        left: 10%;
        padding: .25rem .75rem;
        color: #fff!important;
        height: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color_blanco {
        color: #fff !important;
    }
    .superbig {
        font-size: 3.8rem;
    }
    @media (max-width: 860px) {
        .alturaBase {
            margin-top: 20%
        }
    }

    @media (max-width: 540px) {
        .alturaBase {
            margin-top: 25%
        }
        
    }


    @media (max-width: 414px) {
        .alturaBase {
            margin-top: 25%
        }
    }
  </style>
  