<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">

            <div class="card">
                <div class="card-header">
                    <h5>Tus Datos Personales</h5>
                    <div class="card-body">
                        <div class="form-group row mt-2">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input type="file" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview'))" id="file" name="file" ref="fileImg" accept=".png, .jpg, .jpeg">
                                        <label for="file"></label>
                                    </div>
                                    <div class="avatar-preview">
                                        <div id="imagePreview" :style="'background-image: url('+Img+')'" :src="Img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-group col-md-6">
                                <label>Nombre(s)<span class="required">*</span></label>
                                <input v-model="objCliente.Nombre" type="text" class="form-control"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Apellidos<span class="required">*</span></label>
                                <input v-model="objCliente.Apellido" type="text" class="form-control"/>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="form-group col-md-6">
                                <label>Número telefónico<span class="required"></span></label>
                                <input v-model="objCliente.Telefono" type="text" class="form-control"/>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Correo Electrónico<span class="required"></span></label>
                                <input v-model="objCliente.email" type="text" class="form-control" disabled/>
                            </div>
                        </div>

                        <div class="row mt-4 text-end">
                            <div class="col-md-12">
                                <button @click="Guardar()" type="button" class="btn btn-primary me-2" :disabled="DisableBtn">
                                    <span v-if="loadingBtnSave" class="text-white spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Actualizar Datos
                                </button>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </template>
    </CLoader>
</template>

<script>
import CLoader from "@/components/CLoader.vue";
import VarConfig from '@/helpers/VarConfig';

export default {
    name: 'FormDatosCuenta',
    props:[],
    components: {
        CLoader
    },
    
    data() {
        return {
            // LOADERS
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            loadingBtnSave: false,
            DisableBtn: false,

            // OBJETO CLIENTE
            objCliente: {
                IdUsuario: 0,
                Nombre: '',
                Apellido: '',
                Telefono: '',
                email: '',
                Imagen: ''
            },

            ValidElement: VarConfig.validImage3m,
            Img: '',
            rutaFile:'',
            errorvalidacion:[],
        };
    },

    mounted() {
        this.limpiar();

        if(localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
            this.objCliente = JSON.parse(localStorage.getItem('user'));

            
            if(sessionStorage.getItem('ruta') != undefined && sessionStorage.getItem('ruta') != null ) {
                this.rutaFile = sessionStorage.getItem('ruta');
            }
            
            if(this.objCliente.Imagen === '' || this.objCliente.Imagen == null) {
                this.Img  = this.rutaFile+'vacio.jpg';

            }else {
                this.Img = this.rutaFile+this.objCliente.Imagen;
            }

        }

        this.ConfigLoad.ShowLoader = false
    },

    methods: {
        async Guardar()
        {
			this.errorvalidacion = [];
			this.DisableBtn = true;
            this.ConfigLoad.ShowLoader = true;
            this.loadingBtnSave = true;

            let formData = new FormData();
            let Imagen = this.$refs.fileImg.files[0];

            formData.set('IdUsuario', this.objCliente.IdUsuario);
			formData.set('Nombre',    this.objCliente.Nombre);
			formData.set('Apellido',  this.objCliente.Apellido);
			formData.set('Telefono',  this.objCliente.Telefono);

			formData.append('Imagen',Imagen);


			await this.$http.post('updateprofile', formData,{
                headers: {
					'Content-Type': 'multipart/form-data'
				}
            })
            .then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });

			
        },

		EjecutaConExito(res)
        {      
            this.objCliente = {};
            this.objCliente = res.data.data;

            this.Img = '';
            this.Img = this.rutaFile+this.objCliente.Imagen;

            let tmpUser = JSON.parse(localStorage.getItem('user'));
            localStorage.removeItem('user');
            tmpUser.Nombre    = this.objCliente.Nombre;
            tmpUser.Apellido  = this.objCliente.Apellido;
            tmpUser.Telefono  = this.objCliente.Telefono;
            tmpUser.Imagen    = this.objCliente.Imagen;

            localStorage.setItem('user',JSON.stringify(tmpUser));

            
            if(this.objCliente.Imagen === '' || this.objCliente.Imagen == null) {
                this.Img  = this.rutaFile+'vacio.jpg';

            }else {
                this.Img = this.rutaFile+this.objCliente.Imagen;
            }

            this.DisableBtn     = false;  
            this.loadingBtnSave = false;
            this.$toast.success('Datos Guardados','¡Exito!');
            this.ConfigLoad.ShowLoader = false;
        },

        EjecutaConError(err)
        {
            this.DisableBtn = false;
            this.loadingBtnSave = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.$toast.warning('Complete los campos');
            }
            else{
                this.$toast.error(err.response.data.message);
            }

            this.ConfigLoad.ShowLoader = false;
        },

        limpiar(){
            this.objCliente = {
                Nombre: '',
                Apellido: '',
                Telefono: '',
                email: '',
                Imagen: ''
            };
        }
    },
};
</script>