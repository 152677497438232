<template>
    <div>
        <section class="section-01 alturaBase">
            <div class="container">
              <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                      <img src="@/assets/style/images/nosotrosProd.jpg" class="border-radius-15 img-fluid mb-5" width="80%">
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="pl-25">
                            <h2 class="mb-30">Zumbashop Mx</h2>
                            <p class="mb-25">Somos distribuidores directos de Zumba® Fitness por lo que puedes estar seguro de recibir productos originales y confiar en su calidad.</p>
                            <p class="mb-50">Nuestro sitio web tiene como objetivo brindarte las últimas novedades para que disfrutes al máximo el estilo de vida de Zumba® Fitness. <br> Explóralo y accede a lo 
                                último en de pantalones cargo, camisetas sin mangas y sujetadores, zapatos, chaquetas, bolsos y accesorios geniales que complementan tu rutina de ejercicios diaria.</p>
                            <p class="mb-50">
                                Nunca ha sido más divertido y fácil ejercitarse, sentirse bien es de lo que se trata. Así que no esperes, no lo dudes.
                            </p>

                            <p class="mb-50">
                                ¡Unete a nuestra fiesta de fitness Zumba® ahora!
                            </p>

                            
                        </div>
                        <div class="row justify-content-center mt-4">
                                <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <img src="@/assets/images/logo_zumba_dark.png" class="img-fluid mb-5" alt="">
                                </div>
                            </div>
                  </div>
                  
              </div>
          </div>
      </section>
  
      <section class="imagen_banner-02" :style="{'background-image': 'url(' + require('@/assets/style/images/banner-wearwall.png') + ')'}" >
          <div class="text-conten-img">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <h1 class="color_blanco superbig bolt-02 text-center">SENTIRSE BIEN ES DE LO QUE SE TRATA</h1>
              </div>
          </div>
      </section>

      <br>
      <br>
      <br>
      <br>
    
  
      
  
    </div>
  </template>
  
  <script>
  //import metaList from "../../../helpers/metaSocialNetworks";
  export default {
    name:'Nosotros',
      /*metaInfo() {
          return {
              title: "Zumba Wear®",
              meta: metaList
          }
      },*/

      created(){
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);
      }
  }
  </script>
  
  <style>
    .alturaBase {
        margin-top: 10%
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .imagen_banner-02 {
        background-repeat: no-repeat;
        background-position: 50%;
        -ms-flex-line-pack: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
        -ms-flex-pack: center;
        align-content: center;
        display: flex;
    }
    .text-conten-img {
        
        left: 10%;
        padding: .25rem .75rem;
        color: #fff!important;
        height: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color_blanco {
        color: #fff !important;
    }
    .superbig {
        font-size: 3.8rem;
    }
    @media (max-width: 860px) {
        .alturaBase {
            margin-top: 20%
        }
    }

    @media (max-width: 540px) {
        .alturaBase {
            margin-top: 25%
        }
        
    }


    @media (max-width: 414px) {
        .alturaBase {
            margin-top: 25%
        }
    }
  </style>
  