<template>
  <div>
    <p v-html="metodoPago.DatosBanco"></p>
  </div>
</template>

<script>
export default {
    name: 'ViewTicketPago',
    props:['pMetodoPago'],

    data() {
        return {
            metodoPago:{
                IdMetodoPago: 0,
                Nombre: "",
                Descripcion: "",
                Tipo: 0,
                Disponible: 0,
                DatosBanco: ""
            },
        };
    },

    mounted() {
        if(this.pMetodoPago != undefined && this.pMetodoPago != null) {
            this.metodoPago = this.pMetodoPago;
        }
    },

    methods: {
        
    },
};
</script>