<template>
    <div class="card">
       
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center">
                    <h2>ZUMBA<sup>®</sup> INSTRUCTOR NETWORK</h2>
                </div>

                <div class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12">
                    <p>Zumba<sup>®</sup> Instructor Network (ZIN<sup>TM</sup>) es un programa de membresía mensual creado para ofrecerte el apoyo y las herramientas necesarias para que tengas éxito. 
                        Con ZIN<sup>TM</sup>, estarás dando clases más rápido, tendrás confianza en poco tiempo y nunca te sentirás solo en tu viaje por el universo de Zumba<sup>®</sup></p>
                </div>

                

                <template v-if="checksolicitud">
                    <CLoader :pConfigLoad="ConfigLoad">
                        <template slot="BodyFormLoad">

                            <div v-if="ConfigLoad.ShowLoader" class="placeholder-glow text-center">
                                <p aria-hidden="true">
                                    <span class="placeholder col-10"></span>
                                </p>
                                <a  tabindex="-1" class="btn btn-primary disabled placeholder col-6" aria-hidden="true"></a>
                            </div>
                            
                            <div v-if="zinVerification && !ConfigLoad.ShowLoader" class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center mt-4">
                                <h6 class="mb-3">¡FELICIDADES! YA FORMAS PARTE DE ZIN<sup>®</sup>, TU MEMBRESIA SE ENCUENTRA:</h6>
                                <button type="button" class="btn btn-success btn-block col-6" style="opacity: 1 !important;" disabled>
                                        ACTIVA
                                </button>
                            </div>

                            <div v-if="!zinVerification && !ConfigLoad.ShowLoader" class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center mt-4">
                                <h6 class="mb-3">LO SENTIMOS PERO NO CUENTAS CON UNA MEMBRESIA ZIN<sup>®</sup>, VIGENTE</h6>
                                <button type="button" class="btn btn-danger btn-block col-6" style="opacity: 1 !important;" disabled>
                                        MEMBRESIA DESACTIVADA
                                </button>
                            </div>
                        </template>
                    </CLoader>
                    

                </template>
                <template v-if="!checksolicitud">

                    <template v-if="!verificada">
                        <div v-if="parseInt(objUser.TipoUser) == 3 && objUser.EstatusMem == 'B' " class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center mt-4">
                            <h6 class="mb-3">LO SENTIMOS PERO NO CUENTAS CON UNA MEMBRESIA ZIN<sup>®</sup>, VIGENTE</h6>
                            <button type="button" class="btn btn-danger btn-block col-6" style="opacity: 1 !important;" disabled>
                                    MEMBRESIA DESACTIVADA
                            </button>

                            <br>
                            <br>
                            <br>
                            <span>Si ya has renovado tu membresia y aun no se ve aplicada puedes verificarla <a class="text-morado" @click="getVerification()" style="cursor: pointer;">aqui</a></span>
                        </div>

                        <div v-if="parseInt(objUser.TipoUser) == 2 && objUser.EstatusMem == 'B' " class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center mt-4">
                            <h6 class="mb-3">INDENTIFÍCATE COMO PARTE DE ZIN<sup>®</sup> Y OBTÉN GRANDES BENEFICIOS</h6>
                            <button @click="getVerification()" type="button" class="btn btn-primary btn-block col-6">Verifica tu ZIN<sup>TM</sup>
                                <span v-if="this.load" class="spinner-border spinner-border-sm mb-01" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </template>
                    


                    <div v-if="verificada" class="col-12 col-sm-12 col-md-5 col-lg-12 col-xl-12 text-center mt-4">
                        <h6 class="mb-3">¡FELICIDADES! YA FORMAS PARTE DE ZIN<sup>®</sup>, TU MEMBRESIA SE ENCUENTRA:</h6>
                        <button type="button" class="btn btn-success btn-block col-6" style="opacity: 1 !important;" disabled>
                                ACTIVA
                        </button>
                    </div>
                </template>

            </div>

           

        </div>
    </div>
</template>

<script>
import CLoader from '@/components/CLoader.vue';

const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = (MILLISECONDS_OF_A_SECOND * 60);

export default {
    name: 'FormConectZinAccount',
    props:['pVerificacion'],
    components:{
        CLoader
    },

    data() {
        return {
            checksolicitud: false,
            verificada: false,

            load: false,
            disabled: false,

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  false,
            },

            zinVerification:false,
            cuentaRegresiva: false,
            interval: null,
            finalDate: null,

            infoVerificacion:{
                codeZin:'',
                stateCode:''
            },

            objUser: {
                IdUsuario: 0,
                IdPerfil: 0,
                IdPuesto: 0,
                TipoUser: 0,
                NumCliente: 0,
                Nombre: "",
                Apellido: "",
                NombreCompleto: "",
                Telefono: "",
                email: "",
                Imagen: "",
                EstatusMem: ""
            }
        };
    },

    created() {

        if(this.pVerificacion.codeZin !== '' && this.pVerificacion.codeZin != undefined) {

            this.infoVerificacion.codeZin = this.pVerificacion.codeZin;
            this.infoVerificacion.stateCode = this.pVerificacion.stateCode;
            this.verificaCredenciales();
            console.log('Verificando...');
        }
        
    },

    mounted() {
        this.recoveryInfo();
    },

    methods: {

        recoveryInfo() {

            this.$http.get('usrsrchme').then( (res) => {
                
                let user = res.data.data;

                if(user != undefined && user != '' && user != null){
                    this.objUser = user;

                    if(parseInt(this.objUser.TipoUser) == 3 && this.objUser.EstatusMem == 'A'){
                        this.verificada = true;
                    }else {
                        this.verificada = false;
                    }
                }


            }).catch( err => {
                console.log(err);
                this.$toast.error('Ocurrio un error al momento de verificar');
                this.verificada = false;
            });

           

        },

        getVerification(){
            this.load       = true;
            this.disabled   = true;

            this.$http.get('getlinkmember').then( (res) => {
                console.log(res.data.link);
                //window.open(res.data.link,'_blank');
                window.location.replace(res.data.link);
                this.load       = false;
                this.disabled   = false;
                
                //this.cleanData();

            }).catch( err => {
                console.log(err);
                this.$toast.error('Ocurrio un error al momento de iniciar el proceso');
            });
        },

        verificaCredenciales() {

            this.ConfigLoad.ShowLoader = true;
            this.checksolicitud = true;

            this.$http.post('zinverificateaccount',this.infoVerificacion)
            .then( (res) => {
                
                let basicInfoZin = res.data.data.basicInfo;
            

                if(res.data.status && res.data.data.isMemberZim ) {
                    this.zinVerification    = res.data.data.isMemberZim;
                    this.verificada = true;
                    //sessionStorage.setItem('zininfo',JSON.stringify(basicInfoZin));

                }else {
                    this.zinVerification = res.data.data.isValidate;
                    //sessionStorage.removeItem('zininfo');
                }

            }).catch( err => {
                
                console.log(err);
                this.zinVerification = false;
                this.$toast.error('Ocurrio un error al momento de iniciar el proceso');

            }).finally(() => {
                
                
                this.finalDate = new Date();
                this.finalDate.setSeconds(this.finalDate.getSeconds() + 6);
                this.ConfigLoad.ShowLoader = false;
                clearInterval(this.$store.state.timeGlobal);
                this.$store.state.timeGlobal = setInterval(() => {this.updateCountdown();}, 1000);
                
                
            });
        },


        updateCountdown() {
            this.cuentaRegresiva = true;
            const CURRENT_DATE = new Date();
            //const SPAN_SECONDS = document.getElementById("seconds");
        
            const DURATION = (this.finalDate - CURRENT_DATE);
            const REMAINING_SECONDS = Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND);
            
            console.log(REMAINING_SECONDS);
            
            if(REMAINING_SECONDS <= 0){
                clearInterval(this.$store.state.timeGlobal);
                this.checksolicitud = false;
            }

            //SPAN_SECONDS.innerHTML = REMAINING_SECONDS;
            console.log(REMAINING_SECONDS);

        },

    },
};
</script>