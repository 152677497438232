<template>
    <div>
        <section v-if="showloading" class="mb-5">
            <div class="container">
                <h1 class="my-5 title-01">{{ TituloSeccion }}</h1>
                <div class="row justify-content-center">
                    <div v-for="n in 5" :key="n" class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                        <div class="card card-produc position-relative" aria-hidden="true">
                            <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                <title>Placeholder</title>
                                <rect width="100%" height="100%" fill="#868e96"></rect>
                            </svg>
                            <div class="card-body">
                                <h5 class="card-title placeholder-glow">
                                <span class="placeholder col-6"></span>
                                </h5>
                                <p class="card-text placeholder-glow">
                                <span class="placeholder col-7"></span>
                                <span class="placeholder col-4"></span>
                                <span class="placeholder col-4"></span>
                                <span class="placeholder col-6"></span>
                                <span class="placeholder col-8"></span>
                                </p>
                                <p class="placeholder-glow">
                                    <span class="placeholder col-4"></span>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="!showloading" class="mb-5">
            <div class="container">
                <h1 class="my-5 title-01">{{ TituloSeccion }}</h1>
                <div class="row justify-content-center">
                    <div v-for="(item,index) in listaProductos" :key="index" class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                        <div class="card card-produc position-relative" id="p1">
                            <div v-if="parseInt(item.aplicaRebaja) == 1" class="tag px-2 py-1 bg-danger fw-bold">
                                OFERTA
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;">
                                            <figure class="m-0 card-img">
                                                <img :src="get_ImagePreview(item.imagenescolor,0)" alt="Producto" :id="'imgPrSug_'+item.IdProducto"
                                                    class="img-fluid show-img c1" />
                                            </figure>
                                        </a>
                                        <p class="mb-2">
                                            <span v-if="item.categoriaprincipal != undefined && item.categoriaprincipal != null" class="text-body-tertiary fs-7">{{ item.categoriaprincipal.Nombre }}</span>
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <div class="colors">
                                            <div v-for="(color,indx) in item.imagenescolor" :key="indx" 
                                                @mouseover="cambiarImgColor(color)"
                                                @click="cambiarImgColor(color)"
                                                v-b-tooltip.hover.Top :title="color.Nombre"
                                                class="color-available" :style=" color.Color == '#ffffff' ? 'border-color:#000; border-width: 1px; background-color:'+color.Color+';' : 'background-color:'+color.Color+';'">
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h2 class="fs-6 mb-1">
                                            <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;" class="text-darker hover-purple-text">
                                                {{ item.Nombre }}
                                            </a>
                                        </h2>
                                    </div>
                                    
                                    <div class="col-12">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div>
                                                <span class="fw-bolder w-auto fs-5">
                                                    {{ $formatNumber(item.PrecioFinal,'$') }}
                                                </span>
                                                <span v-if="parseInt(item.aplicaRebaja) == 1" class="text-decoration-line-through fs-6">{{ $formatNumber(item.Precio,'$')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
    
</template>
  
<script>
    export default {
        name:'fproductosSug',
        props:["IdCategoria","pProducto","pTituloSeccion"],
        components:{},
        data() {
            return {
                listaProductos:[],
                RutaFile:'',
                showloading: true,
                TituloSeccion: 'Productos Relacionados'
            }
        },
        methods: {
            getProductos() {
                this.showloading = true;
                this.$http.get('getprodsugeridos', {
                    params:{
                        Entrada: 5,
                        Pag:1,
                        IdProductoNot:this.pProducto.IdProducto,
                        IdCategoria:this.IdCategoria
                    }

                }).then( (res) => {
                
                    this.listaProductos     = res.data.data.data;
                    this.RutaFile           = res.data.rutaFile;
                    
                }).catch( err => {
                    console.log(err);
                }).finally( () =>{
                    this.showloading = false;
                });
            },
  
            viewDetalle(idcategoria,nomcategoria,producto) {
                this.$router.push({name:'viewproducto',
                    query:{
                        'cat':idcategoria,
                        'prod':producto.IdProducto,
                        'feat':0,
                        'nomcat':nomcategoria.replace(/\s+/g, '-'),
                        'nomp':producto.Nombre.replace(/\s+/g, '-')
                    }
                });
                //this.bus.$emit('cambiaLogoNegro');
            },
  
            get_ImagePreview(array,index) {
                var nameimagen="";
                if(array != undefined && array != null & array.length > 0) {
                    if(array[index]) {
                        nameimagen=array[index].Imagen
                    }
                    return this.RutaFile+array[index].IdProducto+'/'+nameimagen;
                
                }else{
                    return this.RutaFile+'noimagen.jpg';
                }
                
            },


            cambiarImgColor(arrayImgs) {
                var imagenname = "";
                if(arrayImgs.Imagen !== undefined) {
                    imagenname=arrayImgs.Imagen;
                }
                document.getElementById("imgPrSug_"+arrayImgs.IdProducto).src = this.RutaFile+arrayImgs.IdProducto+'/'+imagenname;
            },
  
        },
        created() {
            window.scrollTo(0, 0);
    
        },
        mounted() {
            if(this.pTituloSeccion != undefined){
                this.TituloSeccion = this.pTituloSeccion;
            }
            this.getProductos();
        },
    }
</script>
