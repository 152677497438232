<template>
    <div class="rounded-3 p-3 border border-1 light-boxshadow mb-3">
        <template v-if="showloading">
            <h4 class="title-01"><span class="placeholder placeholder-lg col-12"></span></h4>
            <div v-for="n in 3" :key="'pnv'+n" class="row new-product justify-content-start align-items-center my-3">
                <div class="col-5">
                    <svg class="img-fluid rounded-3" width="100%" height="94" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#868e96"></rect>
                    </svg>
                </div>
                <div class="col-7">
                        <h5 class="text-morado fs-6 text-truncate placeholder-glow">
                            <span class="placeholder col-10"></span>
                        </h5>
                    
                    <div class="price">
                        <p class="my-1 fs-6 align-middle placeholder-glow">
                            <span class="placeholder col-8"></span>
                        </p>
                    </div>
                    <p class="my-1 fs-9 placeholder-glow">
                        <span class="placeholder col-6"></span>
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <h4 class="title-01">{{ Titulo }}</h4>
            <div v-for="(item,indx) in listaProductos" :key="'prdRecom_'+indx" class="row new-product justify-content-start align-items-center my-3">
                <div class="col-5">
                    <img :src="get_ImagePreview(item.imagenescolor,0)" class="img-fluid rounded-3"
                        alt="producto" />
                </div>
                <div class="col-7">
                    <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;">
                        <h5 class="text-morado fs-6 text-truncate">
                            {{ item.Nombre }}
                        </h5>
                    </a>
                    <div class="price">
                        <p v-if="parseInt(item.aplicaRebaja) == 1" class="my-1 fs-6 align-middle">
                            {{ $formatNumber(item.Precio,'$')}}
                        </p>
                        <p v-else>
                            {{ $formatNumber(item.PrecioFinal,'$') }}
                        </p>
                    </div>
                    
                </div>
            </div>
        </template>
        
        
    </div>
</template>

<script>
export default {
    name: 'ProdRecomenVertical',
    props:['pTitulo'],
    data() {
        return {
            Titulo: 'Recomendaciones',
            showloading:true,
            listaProductos: [],
            RutaFile: ''
        };
    },

    mounted() {
        this.getProductos();
    },

    methods: {
        getProductos() {
            this.showloading = true;
            this.$http.get('getprodsugeridos', {
                params:{
                    Entrada: 3,
                    Pag:1,
                    IdProductoNot: 0,
                    IdCategoria: 0
                }

            }).then( (res) => {
            
                this.listaProductos     = res.data.data.data;
                this.RutaFile           = res.data.rutaFile;
                
            }).catch( err => {
                console.log(err);
            }).finally( () =>{
                this.showloading = false;
            });
        },
  
        viewDetalle(idcategoria,nomcategoria,producto) {
            this.$router.push({name:'viewproducto',
                query:{
                    'cat':idcategoria,
                    'prod':producto.IdProducto,
                    'feat':0,
                    'nomcat':nomcategoria.replace(/\s+/g, '-'),
                    'nomp':producto.Nombre.replace(/\s+/g, '-')
                }
            });
            //this.bus.$emit('cambiaLogoNegro');
        },
        get_ImagePreview(array,index) {
                var nameimagen="";
                if(array != undefined && array != null & array.length > 0) {
                    if(array[index]) {
                        nameimagen=array[index].Imagen
                    }
                    return this.RutaFile+array[index].IdProducto+'/'+nameimagen;
                
                }else{
                    return this.RutaFile+'noimagen.jpg';
                }
                
            },
    },
};
</script>