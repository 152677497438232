<template>
  <div class="text-center">
        <div class="px-lg-5 pt-lg-4 pb-lg-3 p-4 text-center w-100">
            <img :src="require('@/assets/images/logo_zumba_dark.png')" class="img-fluid" alt="Zumba Wear" width="60%">
        </div>
        <div  class="form-group">                  
            <input v-model="email" type="text" class="form-control"  placeholder="Ingresa un Correo"/>
            <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*' + errorvalidacion.Correo[0]" />
        </div>
        <p class="text-left mt-4">Introduce tu correo de usuario y recibiras un correo para recuperar tu contraseña</p>
    </div>
</template>

<script>
import CValidation from '@/components/CValidation.vue';
export default {
    name: 'FormRecuperaPassword',
    props:['poBtnSave'],
    components:{
        CValidation
    },

    data() {
        return {
            email:'',
            errorvalidacion: [],
        };
    },

    created() {
        this.bus.$off('Save_'+this.poBtnSave.EmitSeccion);
    },
    mounted() {
        this.bus.$on('Save_'+this.poBtnSave.EmitSeccion,(val)=>
        {
            this.send_mail();
        });
    },

    methods: {
        send_mail() {

            this.errorvalidacion.Correo = [];
            this.errorvalidacion = {};
   
            if(this.email.trim() ==='') {
                this.$toast.warning('Introduzca el correo');
                this.errorvalidacion.Correo = ['Tienes que escribir un correo electrónico.'];
                return false;
            }

            let resquest = {
                email:this.email
            }

            this.$http.post('usresetacount', resquest)
            .then( (res) => {
                this.$toast.info('Se ha enviado un correo para restablecer su contraseña');
                this.bus.$emit('CloseModal_'+this.poBtnSave.EmitSeccion);

            }).catch( (err) => {
                this.EjecutaConError(err)
            });

        },

        EjecutaConError(err)
            {
                if(err.response.data.errors) {
                    this.errorvalidacion = err.response.data.errors;
                    this.$toast.info(JSON.stringify(err.response.data.errors.email[0]));

                }else {
                    this.$toast.warning(err.response.data.message);
                }
            },

      
    },
};
</script>