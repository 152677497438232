<template>
    <div>
        <input type="hidden" :value="ShowProps">
        <b-modal class="modal-dialog modal-dialog-centered" :ref="ConfigModal.ModalNameId" :id="ConfigModal.ModalNameId" 
            @hidden="GetClose" @shown="GetData" :size="ConfigModal.ModalSize"
            hide-header
            hide-footer 
            header-bg-variant="dark"
            header-text-variant="dark"
            body-bg-variant="dark"
            body-text-variant="dark"
            :dialog-class="(ConfigModal.FullScreen) ? 'modal-fullscreen' : ''" 
            scrollable no-close-on-esc no-close-on-backdrop>

            

            <div class="modal-content search-modal">
                
                <div class="modal-body">
                    
                    <div class="mt-3 mt-lg-0 text-center" role="search">
                        <div class="border-0 justify-content-end">
                            <button type="button" class="close-search" @click="HideModal()">
                                <i class="far fa-times"></i>
                            </button>
                        </div>
                        <input @keyup.enter="GoSearch()" v-model="textBusqueda" class="display-5 input-search text-center mb-3" type="search" placeholder="Ingresa tu busqueda..." aria-label="buscar" />
                            <button @click="GoSearch()" aria-label="search" class="btn btn-primary" type="button">
                                <i class="fas fa-search"></i>
                            </button>
                        <span class="d-block fs-5 text-light ff-lato">Busca entre toda nuestra colección de productos.</span>
                    </div>
                </div>
            </div>

            
              
            

            
        </b-modal>
    </div>
</template>

<script>

export default {
  components: {},
    name: "CModalBuscador",
    props: ['pConfigModal','poBtnSave'],
    data() {
        return {
            ConfigModal:{
                IdElement: 0,
                ModalNameId: 'ModalForm',
                ModalTitle: 'Formulario',
                ModalSize: 'md',
                ShowTittle: true,
                ShowFooter: false,
                TypeModal: 1,
                CloseModal: true,
                EmitSeccion: '',
                FullScreen: false,
                Obj:{},
            },
            textBusqueda:'',
        }
    },
    methods: {
        ShowModal(Id,Objeto)
        {
            // CUANDO ABRE EL MODAL EJECUTA ESTA ACCION
            this.$refs[this.ConfigModal.ModalNameId].show();
            this.ConfigModal.IdElement = Id;
            this.ConfigModal.Obj = Objeto;
        },
        HideModal()
        {
            this.$refs[this.ConfigModal.ModalNameId].hide()
            this.GetClose();
        },
        ToggleModal()
        {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs[this.ConfigModal.ModalNameId].toggle('#toggle-btn');  
        },
        GetData()
        {
            // CUANDO ABRE EL MODAL SE EJECUTA ESTA ACCION, PARA EL FORM
            this.bus.$emit('Recovery_'+this.ConfigModal.EmitSeccion,this.ConfigModal.IdElement,this.ConfigModal.Obj);
        },
        GetClose()
        {
            /* this.bus.$off('List');
            this.bus.$emit('List',objList); */
        },
        HideFooter(Ocultar)
        {
            // SI RECIBE LA BANDERA DESDE EL LIST OCULTA EL PIE DEL MODAL
            if(Ocultar === true){
                this.ConfigModal.ShowFooter = false;
            }
            else{
                this.ConfigModal.ShowFooter = true;
            }
        },
        HideSave(Ocultar)
        {
            // SI RECIBE LA BANDERA DESDE EL LIST OCULTA EL BOTON SAVE DEL MODAL
            if(Ocultar === true){
                this.poBtnSave.ShowBtnSave = false;
            }
            else{
                this.poBtnSave.ShowBtnSave = true;
            }
        },

        GoSearch() {

            sessionStorage.setItem('PositionProduct','');
            sessionStorage.setItem('currentPage','1');
            sessionStorage.setItem('IdCategoria','0');
            console.log('activando busqueda');

            if(this.$route.name == "productos"){

                let parametros = {
                        pIdCategoria: 0, 
                        pTextBusqueda: this.textBusqueda,
                        pIsTop: 0,
                    };

                this.bus.$emit('CambiarCategoria2',parametros);

            }else{

                this.$router.push({ name :'productos',params:{
                        pIdCategoria: 0, 
                        pTextBusqueda: this.textBusqueda,
                        pIsTop: 0, 
                        
                    }
                });
            }

            //this.textBusqueda = '';
            this.HideModal();
        
        }
    },
    created()
    {
    },
    mounted()
    {
        this.bus.$off('CloseModal_'+this.ConfigModal.EmitSeccion);
        this.bus.$off('NewModal_'+this.ConfigModal.EmitSeccion);
        this.bus.$off('HideFooter_'+this.ConfigModal.EmitSeccion);

        this.bus.$on('HideFooter_'+this.ConfigModal.EmitSeccion,(Ocultar)=> 
        {
            this.HideFooter(Ocultar);
        });

        this.bus.$on('CloseModal_'+this.ConfigModal.EmitSeccion,(data,Id)=>
        {
            this.HideModal();
        });

        this.bus.$on('NewModal_'+this.ConfigModal.EmitSeccion,(Id,obj)=> 
        {
            this.ShowModal(Id,obj);

            this.bus.$off('HideSave_'+this.ConfigModal.EmitSeccion);
            this.bus.$on('HideSave_'+this.ConfigModal.EmitSeccion,(Ocultar)=> 
            {
                this.HideSave(Ocultar);
            });

           
        });
    },
    computed: {
        ShowProps()
        {
            if(this.pConfigModal != undefined)
            {
                if(this.pConfigModal.ModalNameId == undefined){
                    this.ConfigModal.ModalNameId = "ModalForm";
                }
                else
                {
                    this.ConfigModal.ModalNameId = this.pConfigModal.ModalNameId;
                }
                if(this.pConfigModal.ModalTitle != undefined){
                    this.ConfigModal.ModalTitle = this.pConfigModal.ModalTitle;
                }
                if(this.pConfigModal.ModalSize != undefined){
                    this.ConfigModal.ModalSize = this.pConfigModal.ModalSize;
                }
                if(this.pConfigModal.ShowFooter != undefined){
                    this.ConfigModal.ShowFooter = this.pConfigModal.ShowFooter;
                }
                if(this.pConfigModal.TypeModal != undefined){
                    this.ConfigModal.TypeModal = this.pConfigModal.TypeModal;
                }
                if(this.pConfigModal.CloseModal != undefined){
                    this.ConfigModal.CloseModal = this.pConfigModal.CloseModal;
                }
                if(this.pConfigModal.EmitSeccion != undefined){
                    this.ConfigModal.EmitSeccion = this.pConfigModal.EmitSeccion;
                }
                if(this.pConfigModal.EmitSeccion != undefined){
                    this.ConfigModal.EmitSeccion = this.pConfigModal.EmitSeccion;
                }
                if(this.pConfigModal.FullScreen != undefined){
                    this.ConfigModal.FullScreen = this.pConfigModal.FullScreen;
                }
                if(this.pConfigModal.ShowTittle != undefined){
                    this.ConfigModal.ShowTittle = this.pConfigModal.ShowTittle;
                }
            }

            return this.ConfigModal.ModalTitle;
        }
    },
}
</script>