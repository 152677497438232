import Vue from "vue";
import Vuex from "vuex";
import HttpConfig from "@/config/HttpConfig";

Vue.use(Vuex)

export default new Vuex.Store({
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },
    state: {
        status: '',
        token: localStorage.getItem('token_user') || '',
        user: JSON.parse(localStorage.getItem('user')) || {},
        ruta: sessionStorage.getItem('ruta') || '',
        timeGlobal: ''
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, { token, user, ruta }) {
            state.status = 'success'
            state.token = token
            state.user = user
            state.ruta = ruta
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.ruta = ''
        },
        stopTimerGlobal(state) {
            state.timeGlobal = ''
        }
    },
    actions: {
        login({ commit }, usuario) {
            let token = usuario.token;
            const user = usuario.usuario;
            const ruta = usuario.RutaFile;

            Vue.prototype.$http = HttpConfig(token);
            localStorage.setItem('token_user', token);
            localStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('ruta', ruta);
            commit('auth_success', { token, user, ruta });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token_user');
                localStorage.removeItem('user');
                sessionStorage.removeItem('ruta');
                sessionStorage.removeItem('MetodoEnvio');
                sessionStorage.setItem('PositionProduct','');
                sessionStorage.setItem('currentPage','1');

                localStorage.removeItem('fromUrl');
                localStorage.removeItem('IdCategoria');
                localStorage.removeItem('orden');
                commit('stopTimerGlobal');
                resolve();
            });
        }
    },
    modules: {}
})