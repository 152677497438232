<template>
    <div class="container-fluid alturaBase">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="email-container mb-4">
                    <div class="row justify-content-center mb-4">
                        <div class="col-11 text-center mt-2">
                            <img src="@/assets/images/logo_zumba.png" alt="zumbashop" class="img-fluid" width="40%">
                        </div>
                        <div class="col-11 mt-4">
                            <div class="card card-produc position-relative">
                                <div class="card-body pt-3 text-center">
                                    
                                    <template v-if="verificationLink">
                                        <h4 class="titulo mb-02 text-success">¡Felicidades!</h4>
                                        <p class="texto-contentido-02">Tu cuenta ha sido activada con exito, si no eres redirigido al login automaticamente en 5s, pulsa el boton</p>
                                        <p v-if="cuentaRegresiva">Seras redireccionado para acceder en: <span id="seconds">{{ contador }}</span> </p>
                                        <button type="button" @click="goLogin()" class="btn btn-primary mb-4">
                                            Ingresar
                                        </button>
                                    </template>
                                    <template v-else>
                                        <h4 class="titulo mb-02 text-danger">Oops! algo a salido mal</h4>
                                        <p class="texto-contentido-02"  style="text-transform: none !important;">Reintentalo de nuevo o ponte en contacto con el equipo de zumbawear</p>
                                    
                                        <button type="button" @click="goHome()" class="btn btn-primary mb-4">
                                            Regresar
                                        </button>
                                    </template>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</template>

<script>

const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = (MILLISECONDS_OF_A_SECOND * 60);

export default {
    name: 'ActivarCuenta',

    data() {
        return {
            MasterKey: '',
            MasterId: 0,
            textDefault: '',
            contador:0,

            verificationLink:false,
            cuentaRegresiva: false,
            interval: null,
            finalDate: null,
        };
    },

    created() {
        this.MasterKey = this.$getParameterByName('key');
        this.MasterId = this.$getParameterByName('code');

        this.validateTokenPass();
    },

    mounted() {
        
    },

    methods: {
        validateTokenPass() {

            let resquest = {
                Key: this.MasterKey,
                Id: this.MasterId,
            }
            this.$http.post('activateaccount',resquest)
            .then((res) => {
                this.textDefault = '¡Felicidades! Tu cuenta ha sido activada con exito, si no eres redirigido al login automaticamente en 5s, pulsa el boton';
                this.verificationLink = true;
                
            }).catch( err => {
                this.verificationLink = false;
                this.textDefault = 'Oops! algo a salido mal, reintentalo de nuevo o ponte en contacto con el equipo de zumbawear'

            }).finally(() => {
                
                if(this.verificationLink) {
                    this.finalDate = new Date();
                    this.finalDate.setSeconds(this.finalDate.getSeconds() + 6);
                    
                    clearInterval(this.$store.state.timeGlobal);
                    this.$store.state.timeGlobal = setInterval(() => {this.updateCountdown();}, 1000);
                }
                
            });
        },

        updateCountdown() {
            this.cuentaRegresiva = true;
            const CURRENT_DATE = new Date();

            const DURATION = (this.finalDate - CURRENT_DATE);
            const REMAINING_SECONDS = Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND);

            console.log(REMAINING_SECONDS);

            if(REMAINING_SECONDS <= 0){
                clearInterval(this.$store.state.timeGlobal);
                this.goHome();
            }
            this.contador = REMAINING_SECONDS;

        },

        goHome(){
            this.$router.push({ name:'login'});
        }

    },
};
</script>

<style>
.email-container {
    width: 36% !important;
    border-radius: 5px;
    padding: 2% 1% 2% 1%;
    margin: 0 auto;
    background: -prefix-linear-gradient(top, #4042b2, #000);
    background: -webkit-gradient(linear, left top, left bottom, from(#8c00ed), to(#4628dc));

}

.alturaBase {
    margin-top: 10%
}


@media (max-width: 860px) {
    .alturaBase {
        margin-top: 40%
    }
    .email-container {
        width: 95% !important;
    }
}

@media (max-width: 540px) {
    .alturaBase {
        margin-top: 50%
    }

    .email-container {
        width: 95% !important;
    }
}


@media (max-width: 414px) {
    .alturaBase {
        margin-top: 45%
    }

    .email-container {
        width: 100% !important;
    }
}



</style>