<template>
  <main style="margin-top: 76px;">
    <input type="hidden" :value="cleanCupon">

        <div class="container path pt-3 mb-4">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                            <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                    </li>
                    <li class="breadcrumb-item active fw-medium" aria-current="page">Bolsa</li>
                </ol>
            </nav>
        </div>
        <hr>
        <section class="container mb-4">
            <div class="row">
                <div class="col-lg-8 mb-4">
                    <h2>Mi bolsa</h2>
                    <div class="d-flex justify-content-between align-items-end">
                        <h6 class="mb-0">Hay <span class="text-morado">{{ listCarrito.length }}</span> articulos en tu bolsa</h6>
                        <button v-if="listCarrito.length > 0"  @click="limpiarBolsa()" class="btn btn-primary-2">Vaciar bolsa</button>
                    </div>
                </div>
                <div class="col-12 col-md-7 col-lg-8">
                    <div v-if="listCarrito.length > 0" class="products-added-list">
                        <div v-for="(item,idex) in listCarrito" :key="idex" class="product-added">
                            <figure class="img-thumbnail">
                                <img :src="item.Imagen" alt="producto" class="img-fluid">
                            </figure>
                            <div class="details">
                                <h3 class="name">{{ item.Nombre }} </h3>
                                <p class="size-color">Talla: <span>{{ item.NomTalla }}</span> / Color: <span>{{ item.NomColor }}</span></p>
                                
                                <p class="price">${{$formatNumber(item.Precio,"")}}</p>
                                <div class="cantidad">
                                    <div class="d-flex align-items-center me-1">
                                        <!-- SET MIN -->
                                        <button @click="setMinus(idex)" type="button" class="btn qty-btn">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        
                                        <!-- INPUT CANTIDAD -->
                                        <input type="text" v-model="item.Cantidad" class="form-control input-qty" aria-label="Cantidad" readonly/>

                                        <!-- SET PLUS -->
                                        <button @click="setPlus(idex)" type="button" class="btn qty-btn">
                                            <i class="fas fa-plus"></i> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button @click="removeItem(idex)" type="button" class="btn btn-primary px-2 py-1" title="Eliminar producto"><i
                                    class="fas fa-times"></i></button>
                        </div>
                    </div>

                    <div v-if="listCarrito.length == 0 " class="products-added-list">
                        <br>
                        <div class="text-center">
                            <h3 class="text-morado">Su bolsa se encuentra vacia.</h3>
                            <h6>¿No sabe qué comprar? ¡Cientos de productos le esperan!</h6>
                            
                        </div>
                           
                    </div>
                </div>

                <!-- RESUMEN DEL PEDIDO -->
                <div class="col-12 col-md-5 col-lg-4">
                    <div class="summary">
                        <h5 class="text-center fs-4 mb-3">Pedido</h5>
                        <div class="envio d-flex align-content-center justify-content-between mb-3">
                            <label for="envio" class="text-dark fs-6">Envio:</label>
                            <select id="envio" class="form-select form-select-sm w-auto" aria-label="Tipo de envio" @change="selectEnvio()" v-model="objPedido.IdMetodoEnvio">
                                <option :value="0">Seleccionar</option>
                                <option v-for="(envio, indx) in listMetodoEnvio" :key="'env_'+indx"  :value="envio.IdMetodoEnvio" v-b-tooltip.hover.Top :title="envio.Descripcion">
                                    {{envio.Nombre}}
                                </option>
                               
                            </select>
                        </div>
                        <div class="coupon mb-4">
                            <div v-if="logueado" class="d-flex justify-content-between">
                                <input type="text" v-model="objCuponDescuento.KeyCupon" class="form-control" id="coupon" placeholder="Ingresa tu cupón de descuento" aria-label="Cupon" />
                                <button type="button" @click="checkCupon()" class="btn btn-primary-2 ms-2">
                                    <span v-if="loadCupon" class="text-white spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <template v-else>Aplicar</template>
                                </button>
                            </div>
                        </div>
                        <div class="total-price-breakdown mb-3">
                            <h5 class="mb-2">Detalle de compra</h5>

                            <div class="section">
                                <span class="title">Productos:</span>
                                <span>{{ $formatNumber(objPedido.Subtotal,'$') }}</span>
                            </div>
                            
                            <div class="section">
                                <span class="title">Descuentos:</span>
                                <span>{{ $formatNumber(objPedido.Descuento,'$') }}</span>
                            </div>

                            <div class="section">
                                <span class="title">Envío:</span>
                                <span>{{ $formatNumber(objPedido.CostoEnvio,'$') }}</span>
                            </div>
                            <div v-if="objPedido.labelEnvio != '' && objPedido.labelEnvio != undefined" class="section">
                                <small>{{ objPedido.labelEnvio }}</small>
                            </div>
                            <div class="total">
                                <span>TOTAL</span>
                                <span>{{ $formatNumber(objPedido.GranTotal,'$') }}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button @click="goToInformation()" class="btn btn-primary w-100">Continuar compra</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Configs from '@/helpers/VarConfig.js';

export default {
    name: 'Bolsa',

    data() {
        return {
            logueado: false,
            cliente: {
                IdUsuario:0,
            },

            listCarrito: [],
            
            objPedido:{
                IdMetodoEnvio: 0,
                IdCuponDescuento: 0,
                IdDescMayorista: 0,
                labelEnvio: '',

                Subtotal: 0,
                Descuento: 0, // VALOR DESCUENTO
                CostoEnvio: 0,
                GranTotal: 0
            },

            objCuponDescuento: {
                IdCuponDescuento: 0,
                ConsumoMinimo: 0,
                ConsumoMaximo: 0,
                Subtotal: 0,
                ValorDescuento: 0,
                isKeyClean: true,
                KeyCupon: ''
            },
            IdDescMayorista: 0,
            DescMayorista: 0,
            loadCupon: false,

            listMetodoEnvio:[],
        };
    },

    methods: {

        goHome(){
            this.$router.push({name:'principal',params:{} });
        },

        // ******************* CUPONES DE DESCUENTO *******************
        checkCupon() {
            console.log(this.objCuponDescuento.KeyCupon);
            if( this.objCuponDescuento.KeyCupon !== '' && this.objCuponDescuento.KeyCupon !== undefined ) 
            {   
                this.loadCupon = true;
                let newRequest = {
                    Key: this.objCuponDescuento.KeyCupon,
                    listaCarrito: this.listCarrito
                }

                sessionStorage.setItem('KeyCupon', this.objCuponDescuento.KeyCupon);
                this.$http.post('checkcupon', newRequest)
                .then((res) => {

                    if (res.data.status && parseInt(res.data.ECode) === 3) {

                        this.objCuponDescuento.IdCuponDescuento = res.data.data.IdCupon;
                        this.objCuponDescuento.ConsumoMinimo    = res.data.data.ConsumoMinimo;
                        this.objCuponDescuento.ConsumoMaximo    = res.data.data.ConsumoMaximo;
                        this.objCuponDescuento.Subtotal         = res.data.data.Subtotal;
                        this.objCuponDescuento.ValorDescuento   = parseFloat(res.data.data.ValorDescuento); //DescCupon
                        this.objCuponDescuento.isKeyClean       = false;
                            
                        this.$toast.success('Cupón aplicado con exito.','Felicidades!','');

                    }else if(!res.data.status && res.data.ECode === 1){
                        this.$toast.warning(res.data.message,'Alerta!','');

                    }else if(!res.data.status && res.data.ECode === 2) {
                        this.$toast.warning('Cupón Vencido','Alerta!','');
                    }

                    //this.operaciones();

                }).catch(err => {
                    console.log(err);
                    this.$toast.error(err.response.data.message,'Error!','');

                    this.objCuponDescuento.IdCuponDescuento = 0;
                    this.objCuponDescuento.ValorDescuento   = 0;

                }).finally (() => {
                    this.loadCupon = false;
                    this.operaciones();
                });

            }else{
                this.objCuponDescuento.IdCuponDescuento = 0;
                this.objCuponDescuento.ValorDescuento   = 0;
                this.loadCupon = false;
                this.operaciones();
            }
        },

        // ******************* METODO DE ENVIO *******************
        getMetodosEnvio() {
            this.listMetodoEnvio = [];

            this.$http.get('metodosenvios')
            .then((res) => {
                //console.log(res.data);
                this.listMetodoEnvio = res.data.data;
                this.selectEnvio();
            }).catch(err => {
                console.log(err);
            });
        },

        selectEnvio(){
            

            if(this.objPedido.IdMetodoEnvio > 0){
                sessionStorage.setItem('MetodoEnvio',this.objPedido.IdMetodoEnvio);

                let data = this.listMetodoEnvio.filter(item => this.objPedido.IdMetodoEnvio == item.IdMetodoEnvio);

                if(data.length>0){
                    this.objPedido.CostoEnvio = data[0].Costo;
                    this.objPedido.labelEnvio = data[0].Descripcion;
                }

            }else{
                this.objPedido.CostoEnvio = 0;
                this.objPedido.labelEnvio = '';
            }

            this.operaciones();
        },

        // ****************** ACCIONES DENTRO DE LA BOLSA ***************/
        setMinus(index) {
            //listCarrito

            let Cantidad    = ( this.listCarrito[index].Cantidad -1 );
            let Precio      = this.listCarrito[index].Precio;

            if( Cantidad <= 0 ) {

                this.removeItem(index);

            }else{

                this.listCarrito[index].Cantidad = Cantidad;
                this.listCarrito[index].Importe  = parseFloat( Cantidad * Precio );
                
                this.$updateCart(this.listCarrito);
                this.getTotales();
            }

        },

        setPlus(index) {
            let Existencia      = this.listCarrito[index].Existencia;
            let Precio          = this.listCarrito[index].Precio;
            let Cantidad        = ( this.listCarrito[index].Cantidad + 1 );
            let PermitirReserva = this.listCarrito[index].PermitirReserva;

            if( Cantidad > Existencia && PermitirReserva == 0 ) {
                return false;

            }else{

                this.listCarrito[index].Cantidad = Cantidad;
                this.listCarrito[index].Importe  = parseFloat(Cantidad * Precio);
                this.$updateCart(this.listCarrito);

                this.getTotales();

            }
        },

        removeItem(index) {
          
            this.$swal(Configs.configEliminarProducto)
            .then((result) => {
                if(result.value)
                {   
                    if(this.$deleteItemCart(index)){
                        this.$swal(Configs.configEliminarProductoConfirm);
                    }
                } 
            }).finally(() => {
                this.obtenCarrito();
                this.getTotales();
            });

        },

        limpiarBolsa() {
            this.$swal(Configs.configVaciarBolsa).then((result) =>
            {
                if(result.value)
                {
                    this.$emptyCart();
                    this.$swal(Configs.configVaciarBolsaConfirm);
                    
                } 
            }).finally(() => {
                this.obtenCarrito();
                this.getTotales();
            });
        },

        obtenCarrito(){
            this.listCarrito = [];
            let Carrito = [];
            if (localStorage.getItem("listCarrito") != undefined) {
                Carrito = JSON.parse(localStorage.getItem("listCarrito"));
            }

            this.listCarrito = Carrito;
        },

        
        // ******************** OPERACIONES GENERALES **********************/
        getTotales(){
            console.log('Totales...');
            
            this.operaciones();
            //this.Mayorista();
            this.checkCupon();

        },

        operaciones() {
            let nSubtotal  = 0;
            this.SubTotal  = 0;
            this.listCarrito.forEach(element => {
                let costo = (parseFloat(element.Precio) * parseInt(element.Cantidad));
                nSubtotal = costo + nSubtotal;

            });

            this.objPedido.Subtotal      = nSubtotal;
            this.objPedido.Descuento     = 0;
            this.objPedido.Descuento     = (this.DescMayorista + this.objCuponDescuento.ValorDescuento);

            let tmpCost = (parseFloat(this.objPedido.Subtotal) - parseFloat(this.objPedido.Descuento));
            this.objPedido.GranTotal  = (tmpCost + parseFloat(this.objPedido.CostoEnvio));

            if(this.objPedido.GranTotal < 0) {
                this.objPedido.GranTotal = 0;
            }

            this.objPedido.IdCuponDescuento = this.objCuponDescuento.IdCuponDescuento;
            localStorage.setItem('orden',JSON.stringify(this.objPedido));
            
        },

        goToInformation(){

            if(this.objPedido.IdMetodoEnvio <= 0) {

                this.$toast.warning("Seleccione el tipo de envio");
                return false;

            }else if(parseFloat(this.objPedido.GranTotal) <= 0) {

                this.$toast.warning("No hay productos seleccionados");
                return false;
            }

            if (this.listCarrito.length == 0) {
                this.$toast.warning("No cuenta con productos seleccionados");
                return false;
            }

            localStorage.setItem('fromUrl','bolsa');
            this.$router.push({name:'envios',params:{}});
        },

        
        
    },

    created() { 
        
    },

    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);

        this.getMetodosEnvio();


        //CLIENTE
        this.logueado   = this.$store.getters.isLoggedIn;
        this.cliente    = JSON.parse(localStorage.getItem('user'));
        console.log(this.logueado);

        // CUPON DESCUENTO
        if(sessionStorage.getItem('KeyCupon') && sessionStorage.getItem('KeyCupon') !== '' && sessionStorage.getItem('KeyCupon') !== undefined){
            this.objCuponDescuento.KeyCupon = sessionStorage.getItem('KeyCupon');
        }

        this.objCuponDescuento.isKeyClean = true;

        // METODO DE ENVIO
        if(sessionStorage.getItem('MetodoEnvio') != undefined && sessionStorage.getItem('MetodoEnvio') != null) {
            if(sessionStorage.getItem('MetodoEnvio') > 0) {
                this.objPedido.IdMetodoEnvio = sessionStorage.getItem('MetodoEnvio');
            }
        }
        
        // CARRITO COMPRAS
        this.obtenCarrito();
        this.getTotales();
        
    },

    computed: {
        cleanCupon: function (){
            if(this.objCuponDescuento.KeyCupon === '' && this.objCuponDescuento.isKeyClean === false) 
            {
                this.objCuponDescuento.isKeyClean       = true;
                this.objCuponDescuento.IdCuponDescuento = 0;
                sessionStorage.setItem('KeyCupon', this.objCuponDescuento.KeyCupon);
                this.checkCupon();
                console.log('cleanCuponActivate');
            }
        },
    },
};
</script>