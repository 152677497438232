
<template>
    <div class="container">
        <section v-show="!showLoadingCardsVnd" class="my-5 container">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="row">
                            <div class="col-12 col-lg">
                                <h3 class="fw-bold">Los más vendidos</h3>
                            </div>
                            <div class="col-12 col-lg mt-2">
                                <ul class="d-flex list-unstyled justify-content-lg-end">
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 ps-0 hover-purple-text">
                                            Destacados
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 hover-purple-text">
                                            Populares
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Nuevos
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row px-md-0 px-4">
                    <div class="col-12 col-md-5 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <div class="mx-auto banner d-flex flex-column align-items-start p-5 bg-img-2">
                            <h3 class="fs-1 text-light fw-bolder mb-4">
                                Zumba WearWall
                            </h3>
                            <button class="btn btn-primary mt-4">
                                Ir al WearWall
                                <i class="far fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-8 col-xl-9 px-3">
                            <VueSlickCarousel v-if="!showLoadingCardsVnd" class="best-sellers-slider" v-bind="settings">
                                <div class="bs-card" v-for="(item,index) in listPrdMsVendidos" :key="index">
                                    <div class="card card-produc position-relative">
                                        <div v-if="parseInt(item.TieneRebaja) > 0" class="tag px-2 py-1 bg-danger fw-bold">
                                        OFERTA
                                    </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <figure class="m-0">
                                                        <img :src="RutaProducto+item.IdProducto+'/'+item.ModeloPrincipal.Imagen"  class="img-fluid rounded" />
                                                    </figure>
                                                    <p class="mt-0">
                                                        <span v-if="item.Categoria != undefined && item.Categoria != null" class="text-body-tertiary fs-8">{{ item.Categoria.Nombre }}</span>
                                                    </p>
                                                </div>
                                                <div class="col-12">
                                                    <h2 class="fs-6 mb-1">
                                                        <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" class="text-darker hover-purple-text">
                                                            {{ item.Nombre }}
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div class="col-12">
                                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                                            <span class="text-decoration-line-through fs-8">{{ $formatNumber(item.Precio,'$') }}</span>
                                                        </p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.Precio,'$') }}
                                                        </p>
                                                    </template>
                                                    
                                                </div>
                                                <!--<div class="col-12 mb-3">
                                                    <div class="progress" role="progressbar" aria-label="Example 7px high"
                                                        aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success"></div>
                                                    </div>
                                                    <p class="fs-8 mb-0 mt-1">
                                                        Vendido 90/120
                                                    </p>
                                                </div> -->
                                                <div class="col-12">
                                                    <div class="d-grid gap-2">
                                                        <button class="btn btn-primary pasarela">
                                                            <i class="far fa-shopping-bag"></i>
                                                            Agregar a la Bolsa
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VueSlickCarousel>
                    </div>
                </div>
            </section>
        
    </div>
  </template>
  
  <script>
    import VueSlickCarousel from 'vue-slick-carousel'
    // optional style for arrows & dots
    import '@/assets/style/css/styles.css'
  
    export default {
      name: 'MyComponent',
      components: { VueSlickCarousel },
      data() {
        return {
            //Los Mas Vendidos (Mes)
            showLoadingCardsVnd: true,
            listPrdMsVendidos: [],

            RutaProducto: '',
            settings:{
                "dots": false,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "autoplay": true,
                "autoplaySpeed": 3000,
                "pauseOnFocus": false,       
                "responsive": [
                    {
                        "breakpoint": 1299,
                        "settings": {
                            "slidesToShow": 3,
                        },
                    },
                    {
                        "breakpoint": 991,
                        "settings": {
                            "slidesToShow": 2,
                        },
                    },
                    {
                        "breakpoint": 575,
                        "settings": {
                            "slidesToShow": 1,
                        },
                    },
                ],
                
            },
        }
      },

      methods:{
        async listProdMsVendidos() {
            this.showLoadingCardsVnd = true;

            await this.$http.get("getprodtopvendidos",{
                params:{
                    /*TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,*/
                }
            }).then((res) => {
                console.log(res.data.productos);
                this.RutaProducto = res.data.rutaFile;
                this.listPrdMsVendidos = res.data.productos;


            }).finally(()=>{
                this.showLoadingCardsVnd = false;
               // this.listProdFavoritos();
            });
        },
      },
      created(){
        this.listProdMsVendidos();
      }
    }
  </script>