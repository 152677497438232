<template>
    <div>
        <section class="section-01 alturaBase">
            <div class="container">
              <div class="row mb-4">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div>
                        <h4>WEBSITE TERMS OF USE</h4>
                        <p>Last Updated February 25, 2020</p>
                        <br>
                        <p>IMPORTANT –THESE TERMS OF USE (“TERMS”) ARE A LEGAL AGREEMENT BETWEEN YOU (“YOU”) AND ZUMBA
                            FITNESS, LLC, (“ZUMBA” OR “WE”) OWNER OF THE ZUMBA.COM, STRONGNATION.COM, ZUMBASHAKE.COM,
                            PLATEBYZUMBA.COM, OTHER RELATED WEBSITES, SUBDOMAINS (E.G., ZUMBA.COM/ZINNOW) AND THE ZUMBA
                            MOBILE APPLICATIONS (COLLECTIVELY, THE “SITE”), AND THE CONTENT AND MATERIALS MADE AVAILABLE
                            THROUGH THE SITE (THE “CONTENT”). YOU SHOULD READ CAREFULLY THE FOLLOWING TERMS AND
                            CONDITIONS. BY USING THE SITE OR ACCESSING ITS CONTENT, YOU ACCEPT AND AGREE TO THESE TERMS,
                            WHICH GOVERN YOUR USE OF THE SITE. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE
                            SITE, BECAUSE THEY AFFECT YOUR LEGAL RIGHTS AND OBLIGATIONS. IF YOU DO NOT AGREE WITH ANY
                            TERMS, YOUR SOLE REMEDY IS TO DISCONTINUE USE OF THE SITE.</p>
                        <p>Your use of the Site does not grant to you ownership of any Content, code, data or materials
                            you may access on the Site. You may view the content on the Site on your computer or other
                            internet-compatible device. Any distribution, publishing or exploitation of the Site, or any
                            Content, code, data or materials on the Site, is strictly prohibited unless you have
                            received the express prior permission of Zumba or the applicable rights holder. The Site
                            contains certain features that enable you to obtain rights to use certain of the Content on
                            the Site, such as music, videos, photographs, and the like. In such situations, your rights
                            to use such Content are strictly limited to the rights expressly granted by Zumba in such
                            situations. You may not otherwise copy, reproduce, distribute, publicly display, sell,
                            create derivative works from, decompile, reverse engineer, disassemble, or otherwise exploit
                            any Content, code, data or materials on the Site including, but not limited to, any music,
                            videos, or photographs. If you make other use of the Site, or the Content, code, data or
                            materials thereon, except as otherwise provided above, you may violate copyright and other
                            laws of the United States, other countries, as well as applicable state laws and may be
                            subject to liability for such unauthorized use. Zumba will aggressively enforce its
                            intellectual property rights to the fullest extent of the law, including the seeking of
                            criminal prosecution.</p>
                        <p>The use and accessibility of certain portions of the Site are only available to members of
                            our Zumba® Instructor Network (“ZIN™”) and/or STRONG Nation™ SYNC Network Members
                            (collectively, the “Members”) who receive materials through the ZIN™ NOW platform or any
                            other platform made available to them. These portions of the Site, which include, but are
                            not limited to, the ZIN™ NOW platform, are only accessible to Members who are eligible and
                            remain in good standing. In the event a person cancels or fails to renew his/her ZIN™
                            Membership and/or STRONG Nation™ SYNC Network Membership, or, if such membership lapses or
                            is terminated for any reason, access to these portions of the Site will be immediately
                            disabled and any content contained therein (including, for example, access to music and
                            choreography videos) will no longer be accessible to that person. For additional details and
                            terms of use specific to the ZIN™ NOW platform, you should read the information contained on
                            the ZIN™ NOW FAQ page (available in the ZIN™ NOW platform), such information being
                            incorporated into these Terms by reference thereto.</p>
                        <p>By using the Site, you will be deemed to have irrevocably agreed to the Terms. Some portions
                            of the Site may be subject to additional terms that will be available for your review prior
                            to accessing these portions of the Site. Such additional terms, which are incorporated
                            herein, will not change or replace these Terms, unless otherwise expressly stated.</p>
                        <p>Zumba reserves the right to modify, alter, update, or remove portions of the Terms at any
                            time without notice, so please check the Terms from time to time. Your continued use of the
                            Site signifies your acceptance of any changes. Zumba’s failure to enforce these Terms at any
                            time for any reason shall not be construed as a waiver of its right to enforce them at a
                            later time.</p>
                        <ol class="terms_of_use_list">
                            <li><a href="#one" class="morado" style="color: #007bff;">How to Contact Us</a></li>
                            <li><a href="#two" class="morado" style="color: #007bff;">Community Conduct</a></li>
                            <li><a href="#three" class="morado" style="color: #007bff;">Privacy</a></li>
                            <li><a href="#four" class="morado" style="color: #007bff;">Disclaimer and Warranties</a></li>
                            <li><a href="#five" class="morado" style="color: #007bff;">Our Use of Content</a></li>
                            <li><a href="#six" class="morado" style="color: #007bff;">Links to Third Party Websites</a></li>
                            <li><a href="#seven" class="morado" style="color: #007bff;">Indemnity</a></li>
                            <li><a href="#eight" class="morado" style="color: #007bff;">Applicable Laws and Jurisdictions</a></li>
                            <li><a href="#nine" class="morado" style="color: #007bff;">Copyrights and Trademarks</a></li>
                            <li><a href="#ten" class="morado" style="color: #007bff;">Term and Termination</a></li>
                            <li><a href="#eleven" class="morado" style="color: #007bff;">Mobile Services</a></li>
                            <li><a href="#twelve" class="morado" style="color: #007bff;">EU Shop</a></li>
                        </ol>
                    </div><br>
                    <div>
                        <dt id="one"></dt>
                        <h4>1. How to Contact Us</h4>
                        <p>This Site is controlled and operated by Zumba Fitness, LLC, located at 800 Silks Run, Suite
                            2310, Hallandale, FL 33009. Please forward any comments or complaints about the Site or any
                            Content to <a href="/contact" target="_blank"> Customer Care </a>.</p>
                        <p><a name="two"></a></p>
                        <h4>2. Community Conduct</h4>
                        <p>You may not reproduce, sell, resell or otherwise exploit any resource, or access to any
                            resource or Content contained on the Site.</p>
                        <p>You agree not to (a) sell any non-Zumba products; (b) send spam or promote any non-Zumba
                            event, materials, companies, or products; (c) transmit negative/harmful messages about any
                            person, facility, or company; (d) link to any inappropriate websites or videos; (e) mention
                            any copying of music or videos, which would be in violation of copyright laws; (f) take any
                            action intended to threaten, intimidate or harass any other user of the Site, or which is
                            abusive, objectionable, harmful, illegal, hateful or sexually, racially or ethnically
                            discriminatory; (g) further or promote any criminal activity or enterprise or provide
                            instructional information about illegal activities; (h) solicit passwords or personal
                            identifying information for commercial or unlawful purposes from other users; (i) conduct
                            commercial activities and/or sales of any kind without our prior written consent; (j)
                            promote an illegal or unauthorized copy of another person’s copyrighted work, provide
                            information to circumvent manufacture-installed copy-protect devices, or provide pirated
                            music or links to pirated music files; or (k) impersonate any person or entity or attempt to
                            gain unauthorized access to Zumba’s or any other person’s computers, software, or data.</p>
                        <p>Zumba&nbsp;will remove from the Site any messages Zumba deems as inappropriate.
                            Zumba&nbsp;reserve the right to make all final decisions as to what is considered proper and
                            improper.</p>
                        <p>Zumba® reserves the right, without notice, to prohibit use of the Site by any user who, in
                            our sole discretion, violates the Terms. Such a prohibition may occur without notice to the
                            user. If you believe another user is violating the Terms, you may notify us, and we will
                            work in good faith to resolve such issues. Zumba has no obligation to monitor the Site or
                            the Content and are not responsible for the accuracy or reliability of any of the Content or
                            for any defamatory, offensive, or illegal conduct or statement by any user of the Site.</p>
                        <p>Zumba reserves the right to investigate suspected violations of the Terms, including
                            gathering information from the user or users involved and the complaining party. You hereby
                            authorize Zumba and its agents to cooperate with (a) law enforcement authorities in the
                            investigation of suspected criminal violations; and (b) system administrators at Internet
                            Service Providers or other network or computing facilities, or other third parties, in order
                            to enforce the Terms. Such cooperation may include the disclosure of personally identifying
                            information.&nbsp;<strong>BY ACCEPTING THE TERMS, YOU WAIVE AND HOLD HARMLESS ZUMBA FITNESS,
                                LLC FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY MEMBER OF ZUMBA FITNESS, LLC
                                DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A
                                CONSEQUENCE OF ANY INVESTIGATIONS.</strong></p>
                        <p>Users of all ages are welcome to browse the Site however, you may only establish an account
                            if you are at least 18 years of age, or the legal age of majority in your state, province or
                            country of residence, and, if you are under the age of 18 or the legal age of majority, you
                            either are an emancipated minor, or have obtained the legal consent of your parent or legal
                            guardian to enter into this Agreement, submit content, participate on the Site, and fulfill
                            the obligations set forth in this Agreement, which forms a binding contract between you and
                            Zumba. In order to purchase products/services from the Site and in order to access/use some
                            features on the Site, you may be required to establish and use an account. In addition to
                            your name and contact information, you may be required to submit a valid credit card number,
                            billing address, and related billing information in connection with your account. When you
                            register for an account you must (i) provide accurate and truthful information, and (ii)
                            update such information from time to time as necessary to keep your registration information
                            current and accurate. By establishing an account, you represent and warrant you have the
                            right and are authorized to provide the information you provide when you register for the
                            account. You are responsible for maintaining the confidentiality of your account information
                            and password and for restricting access to such information and to your computer. All
                            activities that occur under your account or password shall be your responsibility.</p>
                        <p><a name="three"></a></p>
                        <h4>3. Privacy</h4>
                        <p>Please see our <a href="privacidad.html" class="morado" style="color: #007bff;"> Privacy Policy </a> which
                            is hereby incorporated into the Terms.</p>
                        <p><a name="four"></a></p>
                        <h4>4. DISCLAIMERS AND WARRANTIES</h4>
                        <p>Content on the Site is provided for entertainment purposes only. The Site and Content are
                            provided on an “as is” and “as available” basis. <strong>YOUR USE OF THE SITE IS AT YOUR OWN
                                RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, ZUMBA FITNESS, LLC EXPRESSLY
                                DISCLAIMS ALL OTHER REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, AS TO THE
                                SITE OR CONTENT, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY,
                                FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</strong></p>
                        <p>NEITHER ZUMBA NOR THE SITE PROVIDES MEDICAL ADVICE. THE SITE IS FOR CONSUMER EDUCATIONAL USE
                            ONLY. NOTHING CONTAINED IN THE SITE IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR,
                            MEDICAL ADVICE, DIAGNOSIS OR TREATMENT. THE SITE, INCLUDING THE MATERIAL AND ANY RELATED
                            SERVICES OR INFORMATION, DOES NOT CONSTITUTE THE PRACTICE OF ANY MEDICAL, NURSING OR OTHER
                            PROFESSIONAL HEALTH CARE ADVICE, DIAGNOSIS OR TREATMENT. ALWAYS SEEK THE ADVICE OF A
                            PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PROVIDER WITH ANY QUESTIONS REGARDING PERSONAL
                            HEALTH OR MEDICAL CONDITIONS. IF YOU HAVE OR SUSPECT THAT YOU HAVE A MEDICAL PROBLEM OR
                            CONDITION, PLEASE CONTACT A QUALIFIED HEALTH CARE PROFESSIONAL IMMEDIATELY.</p>
                        <p>ZUMBA DOES NOT RECOMMEND OR ENDORSE ANY SPECIFIC PHYSICIANS, PRODUCTS, PROCEDURES, OPINIONS
                            OR OTHER INFORMATION THAT MAY BE MENTIONED ON THE SITE, INCLUDING IN ANY MATERIALS. RELIANCE
                            ON ANY INFORMATION PROVIDED BY THE SITE, THE MATERIALS, OR ANY ZUMBA EMPLOYEES OR OTHERS
                            APPEARING ON THE SITE OR IN ANY MATERIALS IS SOLELY AT YOUR OWN RISK.</p>
                        <p>Zumba does not make any representations or warranties that (a) the Site or Content will meet
                            your requirements or expectations; (b) the operation of the Site or Content will be
                            uninterrupted, timely, secure, accurate, or error-free or that any errors will be corrected;
                            (c) any particular results will be obtained from the use of the Site; or (d) the Site or the
                            server that make the Site available are free of viruses or other harmful components. The
                            above exclusions apply to you to the extent permitted by applicable law.</p>
                        <p><a name="five"></a></p>
                        <h4>5. ZUMBA’S USE OF CONTENT</h4>
                        <p>You acknowledge your responsibility for any information, profiles, reviews, comments,
                            messages, text, files, images, photos, video, music, sounds, or other content or materials
                            (“User Content”) you submit, upload, post or otherwise provide or make available to us or
                            our vendors, on or through the Site or otherwise, including User Content you upload or post
                            on third party websites, such as Instagram, Twitter, Facebook and Pinterest, that are
                            associated with hashtags related to Zumba, including, for example, #zumba, #strongnation,
                            #zumbawear, and #strongid (“Submissions”).&nbsp; Such Submissions may be used on the Site
                            and/or on other Zumba marketing materials, including on the Site, in emails and on social
                            media. You may only make a Submission if you are 18 years of age or older. If we select a
                            Submission for use on the Site, it may be displayed for others to see, together with your
                            name and associated Instagram, Twitter, Facebook or Vine (if applicable) profile information
                            (such as your handle and profile picture). We are under no obligation to display, feature or
                            use any Submission, but may do so at our discretion.</p>
                        <p>All Submissions are treated as non-confidential and non-proprietary, except as specifically
                            set forth herein. By making a Submission, you grant, and represent and warrant that you have
                            the right to grant, Zumba, its affiliated entities, vendors and licensees a nonexclusive,
                            royalty-free, worldwide, perpetual, transferable, irrevocable, and fully sub-licensable
                            right and license to use, reproduce, modify, adapt, publish, sell, assign, translate, create
                            derivative works from, distribute, perform and display any Submission, as well as your name,
                            Instagram, Twitter, or Vine handle, Facebook ID, profile picture, image, likeness, comments,
                            posts, statements or other information, in any manner, and in any and all distribution
                            channels, venues, forms, media, or technology, whether now known or hereafter developed,
                            alone or as part of other works, without further notice or any compensation to you. You also
                            acknowledge that we may use your Submission, and any ideas, concepts or know how contained
                            therein, for any purpose including, without limitation, developing, manufacturing,
                            distributing and marketing products.&nbsp; Subject to the licenses granted in this Terms of
                            Use, you retain ownership of any copyrights and rights of publicity you may have in your
                            Submissions.</p>
                        <p>If you make a Submission, you represent and warrant that you own or otherwise control any
                            rights therein and any and all elements thereof; that you have the rights from any and all
                            third parties appearing in such Submission to grant the license contained in these Terms for
                            such third parties' names, images or likenesses and any other third party-owned elements as
                            necessary in and as part of your Submission; and that your Submission will not infringe or
                            violate the rights of any third parties, including, but not limited to, copyrights,
                            trademarks, rights of publicity/privacy, patent, trade secret or confidentiality. You
                            further represent and warrant that your Submissions comply with all applicable laws, rules
                            and regulations, and any third party agreements to which you are subject, including
                            Instagram, Twitter, Facebook, Vine, Pinterest and Google’s terms of use.</p>
                        <p>You further represent and warrant that your Submissions do not constitute or contain software
                            viruses, commercial solicitation, chain letters, mass mailings, or any form of "spam." You
                            may not use a false email address, impersonate any person or entity, or otherwise mislead us
                            as to the origin of any Submission. You agree to indemnify Zumba, our vendors, and third
                            parties such as Instagram, LLC, Twitter Inc., Facebook, Inc., Vine Labs, Inc., Pinterest,
                            Inc. and Google Inc. and any of our or their respective parents, affiliates, licensees,
                            licensors, and each of our or their respective officers, directors, employees, successors,
                            agents and assigns, for all claims arising from or in connection with (a) the use of any
                            Submission, including, without limitation, all claims arising out of or based upon copyright
                            or trademark infringement, misappropriation, invasion of privacy, defamation, right of
                            publicity and/or any blurring, alteration, editing, morphing, distortion, illusionary
                            effect, faulty reproduction, fictionalization or use in any composite form of your or any
                            other person's or entity's name, Instagram, Twitter, or Vine handle, Facebook ID, profile
                            picture, image, likeness, comments, posts, statements or other information and/or the
                            Submission; or (b) any breach or alleged breach by you of any of these Terms or applicable
                            laws.</p>
                        <p>When you make a Submission, you acknowledge and agree that the Submission will be
                            non-proprietary (except as specifically set forth herein) and non-confidential, may be made
                            available to the general public, and may be used by us without restriction. You further
                            irrevocably waive any "moral rights" or other rights with respect to attribution of
                            authorship or integrity of materials regarding any Submission that you may have under any
                            applicable law or under any legal theory.</p>
                        <p>Zumba reserves the right, in our sole discretion, to edit any Submission and to choose to
                            include or not include such Submission on the Site or otherwise use the Submission. The Site
                            may include the opinions, statements and other content of third parties. We are not
                            responsible for screening, monitoring or verifying such content, including such content's
                            accuracy, reliability or compliance with copyright or other laws.&nbsp;Any opinions,
                            statements, or other materials made available by third parties through the Site are those of
                            such third parties and not of Zumba, and we do not endorse any such opinions, statements, or
                            materials.</p>
                        <p>You acknowledge and agree that we have no control over, and shall have no liability for any
                            damages resulting from, the use (including, without limitation, re-publication) or misuse by
                            any third party of any Submission.</p>
                        <p>Any questions, comments, suggestions, reviews, or other information about our products or
                            services submitted to us through the Site (“Feedback”) shall be deemed non-confidential and
                            non-proprietary. We shall be free to use, reproduce, disclose and distribute such Feedback
                            in any manner without limitation. We specifically prohibit you from sending us any
                            information that you consider to be confidential or proprietary. Please note that if you do
                            send us any such information or material, the information will be non-confidential and
                            non-proprietary and we can fully exploit the information without any obligation or
                            liabilities to you.</p>
                        <p>You understand that we do not control, and are not responsible for Feedback made available
                            through the Site and that by using the Site, you may be exposed to Feedback that is
                            offensive, indecent, inaccurate, misleading, or otherwise objectionable. We expressly
                            disclaim any liability for such Feedback. In that regard, you agree that you must evaluate,
                            and bear all risks associated with, the use of any Feedback, that you may not rely on said
                            Feedback, and that under no circumstances will we be liable in any way for any Feedback or
                            for any loss or damage of any kind incurred as a result of the use of any Feedback posted,
                            emailed or otherwise made available. You acknowledge that we have the right (but not the
                            obligation) in our sole discretion to refuse, delete or move any Feedback for any reason.
                        </p>
                        <p>Your interactions with organizations, events and/or individuals found on or through the Site
                            are solely between you and such organizations and/or individuals. We expressly disclaim any
                            liability for such organizations, events or individuals.</p>
                        <p><a name="six"></a></p>
                        <h4>6. LINKS TO THIRD PARTY WEBSITES</h4>
                        <p>The Site includes links to various third party websites. We are providing these links solely
                            as a convenience. Such linked third party sites are not under our control. We have not
                            reviewed all of the sites linked from the Site and are not responsible or liable for the
                            contents available at any such linked site. The appearance of a third party link on the Site
                            does not imply our endorsement of the linked site, its sponsors or any products or services
                            offered on the linked site. Use of any linked site is at your own risk.</p>
                        <p><a name="seven"></a></p>
                        <h4>7. Indemnity</h4>
                        <p>You agree to indemnify, defend, and hold harmless Zumba Fitness, LLC and its officers,
                            employees, directors, affiliates, agents and sponsors from and against any and all claims,
                            damages, costs, or other expenses (including reasonable attorneys’ fees) that arise directly
                            or indirectly out of or from: (a) your breach of these Terms or of any representation or
                            warranty made by you in these Terms; (b) any Submissions you make; and/or (c) your
                            activities in connection with the Site or Content.</p>
                        <p><a name="eight"></a></p>
                        <h4>8. APPLICABLE LAWS AND JURISDICTION</h4>
                        <p>You agree to comply with all applicable laws, statutes, ordinances, and regulations regarding
                            your use of the Site and your purchase of the products and services on the Site.</p>
                        <p>Zumba controls the Site (excluding third party linked sites) from our corporate offices in
                            Florida, United States of America. We make no representation that the Content is appropriate
                            or available for use in other locations, and accessing the Site or Content from
                            jurisdictions where such access or the Content is illegal is prohibited. Users are solely
                            responsible for compliance with all the laws of their jurisdictions. The laws of Florida,
                            specifically excluding choice or conflict of laws principles, will apply to all disputes
                            relating to or arising from your use of the Site (a “Dispute”). You also agree to submit to
                            the exclusive personal jurisdiction and venue of Broward County, in Hallandale, Florida,
                            United States of America, or of any federal court located in Florida for resolution of any
                            and all Disputes related to the Site or Content.</p>
                        <p>You also acknowledge and understand that, with respect to any Dispute: <strong>YOU ARE GIVING
                                UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL OR IN ANY
                                OTHER REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN
                                ANY LAWSUIT INVOLVING ANY DISPUTE.</strong></p>
                        <p>In the event we incur attorneys’ fees or expenses in connection with the collection of sums
                            owed to us by you under these Terms, we shall be entitled to the recovery of those fees and
                            expenses from you.</p>
                        <p><a name="nine"></a></p>
                        <h4>9. COPYRIGHTS AND TRADEMARKS</h4>
                        <p>The Site and Content are the property of Zumba Fitness, LLC, and its licensors and are
                            protected by United States and International Copyright laws. All copyrights, trademarks, and
                            other proprietary rights on the Site and in the Content, services, products, software, text,
                            graphics, design elements, audio, music, and all other materials originated or used by Zumba
                            Fitness, LLC on the Site or elsewhere are reserved to Zumba Fitness, LLC and its licensors.
                        </p>
                        <p>The following are Zumba’s registered trademarks in the United States: ZUMBA®, ZUMBA FITNESS®,
                            STRONGNATION™, ZUMBA GOLD®, ZUMBATHON®, ZUMBINI®, ZUMBA SENTAO®, LET IT MOVE YOU®, Z-DRI®,
                            SHAKE SHAKE SHAKE®, ZUMBA SHAKE SHAKE SHAKE®, PLATE BY ZUMBA®, among other terms and logos.
                            We also own trademark rights in the following terms: DITCH THE WORKOUT-JOIN THE PARTY™,
                            PARTY IN PINK™, FITNESS-CONCERT™, ZIN™ and ZES™, among other terms and logos.</p>
                        <p>In addition, the Site may contain trademarks, logos, and links to third party websites. Any
                            third party domain names, URLs, trademarks or logos appearing on the Site or in the Content
                            are the sole property of their respective owners.</p>
                        <p>If you believe any Content infringes your copyright, please provide our Copyright Agent with
                            the following information: (a) an electronic or physical signature of the person authorized
                            to act on behalf of the owner of the copyright interest; (b) a description of the
                            copyrighted work that you claim has been infringed; (c) a description of the photograph
                            and/or video content you claim is infringing and where it is located on the Site; (d) your
                            address, telephone number, and email address; (e) a written statement that you have a good
                            faith belief that the disputed use is not authorized by the copyright owner, its agent or
                            the law; (f) a statement by you, made under penalty of perjury, that the above information
                            is accurate and that you are the copyright owner or authorized to act on the copyright
                            owner’s behalf. Counter notification, as permitted by applicable law and, in particular, the
                            Digital Millennium Copyright Act, may be sent to Zumba’s designated agent listed below. All
                            counter notifications must include responsive information to what is set forth above
                            including any required statements or signatures. Our Copyright Agent for notice of claims of
                            copyright infringement can be reached as follows: Global Intellectual Property Counsel, 800
                            Silks Run, Suite 2310, Hallandale, FL 33009, (954) 925-3755, email: <a
                                href="mailto:ip@zumba.com">ip@zumba.com</a>.</p>
                        <p><a name="ten"></a></p>
                        <h4>10. TERM AND TERMINATION</h4>
                        <p>These Terms and your right to use the Site will take effect at the moment you install,
                            download, access, or use the Site or Materials and is effective until terminated as set
                            forth below. These Terms will terminate automatically if you fail to comply with any of the
                            terms and conditions described herein. Zumba also reserves the right to terminate these
                            Terms (including any registered account) at any time and on reasonable grounds, which shall
                            include, without limitation, any reasonable belief of fraud, illegal activity, or actions or
                            omissions that violate any term or condition of these terms, or in order to protect its
                            name, business, or goodwill and/or any other user. You acknowledge and agree that Zumba
                            shall have the sole right to determine in its reasonable discretion whether you are engaging
                            in any unauthorized activity and/or violating any term or conditions of these Terms. Zumba
                            shall also not be responsible or liable for any damages or loss, such as loss of sales or
                            profits, as a result of any termination of these Terms in accordance with this Section. You
                            may also terminate this Agreement at any time by ceasing to use the Site. But, all
                            applicable provisions of these Terms will survive termination, as outlined below. Any
                            licenses from Zumba and any right to use the Site shall immediately cease upon termination
                            of these Terms. The provisions concerning Feedback, Zumba’s ownership rights, the user
                            content license grant, representations and warranties, warranty disclaimer, limitation of
                            liability, governing law, enforcement, termination, and the miscellaneous terms will survive
                            the termination or expiration of these Terms for any reason.</p>
                        <p><a name="eleven"></a></p>
                        <h4>11. MOBILE SERVICES</h4>
                        <p>The Site may include certain services that are available to you via your mobile phone or
                            other mobile device if you have subscribed to them, including the ability to use your mobile
                            device to receive and reply to messages from Zumba, and access certain other features
                            (collectively, “Mobile Services”). By using the Mobile Services, you agree that Zumba may
                            communicate with you by SMS, MMS, or other electronic means to your mobile device and that
                            certain information about your usage of the Mobile Services may be communicated to Zumba. In
                            the event you change or deactivate your mobile telephone number, you agree promptly to
                            update your mobile subscription account information to ensure that the messages Zumba
                            intends to send to you are not sent to another person who acquires your former mobile
                            telephone number. Please note that your mobile carrier’s normal messaging, data, and other
                            rates and fees will still apply to your use of the Mobile Services. In addition,
                            downloading, installing, or using certain Mobile Services may be prohibited or restricted by
                            your mobile carrier, and not all Mobile Services may work with all carriers or devices.
                            Therefore, you are responsible for checking with your mobile carrier to determine if the
                            Mobile Services are available for your mobile devices, what restrictions, if any, may be
                            applicable to your use of the Mobile Services, and how much they will cost you.</p>
                        <p><strong>Privacy Notice</strong> – Please see Zumba’s&nbsp;<a href="/support/policy/privacy"
                                target="_blank">Privacy Policy</a>.</p>
                        <p><a name="twelve"></a></p>
                        <h4>12. EU SHOP</h4>
                        <p>The sale of Zumba®&nbsp;products in the following countries are done though our subsidiary,
                            Zumba Fitness Europe, BV: Armenia, Austria, Azerbaijan, Belarus, Belgium, Bulgaria, Croatia,
                            Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Georgia, Greece,
                            Hungary, Iceland, Ireland, Italy, Kazakhstan, Latvia, Lithuania, Luxembourg, Malta,
                            Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovania, Spain, Sweden,
                            Switzerland, Turkey, Ukraine and United Kingdom. Zumba Fitness Europe, BV has an address of
                            WTC Schiphol Airport, Schiphol Boulevard 231, 1118 BH Amsterdam Schiphol, The Netherlands.
                            If you are making a purchase of Zumba®products through Zumba.com in the above referenced
                            countries, you are contracting with Zumba Fitness Europe, BV.</p>


                        <h4>13. METODOS DE PAGO</h4>
                        <p>Nuestras transacciones de venta serán efectuadas mediante la pasarela de Openpay</p>
                    </div>
                </div>

              </div>
          </div>
      </section>
  
    
    
  
      
  
    </div>
  </template>
  
  <script>
  //import metaList from "../../../helpers/metaSocialNetworks";
  export default {
    name:'Terminos',
      /*metaInfo() {
          return {
              title: "Zumba Wear®",
              meta: metaList
          }
      },*/

      created(){
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);
      }
  }
  </script>
  
  <style>
    .alturaBase {
        margin-top: 10%
    }
    .border-radius-15 {
        border-radius: 15px;
    }
    .imagen_banner-02 {
        background-repeat: no-repeat;
        background-position: 50%;
        -ms-flex-line-pack: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover;
        -ms-flex-pack: center;
        align-content: center;
        display: flex;
    }
    .text-conten-img {
        
        left: 10%;
        padding: .25rem .75rem;
        color: #fff!important;
        height: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color_blanco {
        color: #fff !important;
    }
    .superbig {
        font-size: 3.8rem;
    }
    @media (max-width: 860px) {
        .alturaBase {
            margin-top: 20%
        }
    }

    @media (max-width: 540px) {
        .alturaBase {
            margin-top: 25%
        }
        
    }


    @media (max-width: 414px) {
        .alturaBase {
            margin-top: 25%
        }
    }
  </style>
  