<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <div class="card">
                <input type="hidden" :value="convertLetras">
                <div class="card-header">
                    <h5>Datos de Facturación</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Razón Social <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.RazonSocial" class="form-control" id="RazonSocial" placeholder="Ingresar RazonSocial" />
                            <CValidation v-if="this.errorvalidacion.RazonSocial" :Mensaje="'*'+errorvalidacion.RazonSocial[0]"/>
                        </div>
                        <div class="form-group col-md-6">
                            <label>RFC <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Rfc" class="form-control" id="Rfc" placeholder="Ingresar Rfc" />
                            <CValidation v-if="this.errorvalidacion.Rfc" :Mensaje="'*'+errorvalidacion.Rfc[0]"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group col-md-12">
                            <label>Dirección <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Direccion" class="form-control" id="Direccion" placeholder="Ingresar Direccion" />
                            <CValidation v-if="this.errorvalidacion.Direccion" :Mensaje="'*'+errorvalidacion.Direccion[0]"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group col-md-6">
                            <label>Colonia <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Colonia" class="form-control" id="Colonia" placeholder="Ingresar Colonia" />
                            <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*'+errorvalidacion.Colonia[0]"/>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Codigo Postal <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.CodigoPostal" class="form-control" id="CodigoPostal" placeholder="Ingresar CodigoPostal" />
                            <CValidation v-if="this.errorvalidacion.CodigoPostal" :Mensaje="'*'+errorvalidacion.CodigoPostal[0]"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group col-md-4">
                            <label>Estado <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Estado" class="form-control" id="Estado" placeholder="Ingresar Estado" />
                            <CValidation v-if="this.errorvalidacion.Estado" :Mensaje="'*'+errorvalidacion.Estado[0]"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Municipio <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Municipio" class="form-control" id="Municipio" placeholder="Ingresar Municipio" />
                            <CValidation v-if="this.errorvalidacion.Municipio" :Mensaje="'*'+errorvalidacion.Municipio[0]"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Teléfono <span class="required">*</span></label>
                            <input type="text" v-model="objClientedatofiscal.Telefono" class="form-control" id="Telefono" placeholder="Ingresar Telefono" />
                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*'+errorvalidacion.Telefono[0]"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group col-md-6">
                            <label>Regimen Fiscal <span class="required">*</span></label>
                            <select v-model="objClientedatofiscal.RegimenFiscal" class="form-control-search form-select" id="RegimenFiscal">
                                <option value="0">Seleccione una opción</option>
                                <option v-for="(item, index) in ListaCombo1" :key="index" :value="item.id">{{item.label}}</option>
                            </select>
                            <CValidation v-if="this.errorvalidacion.RegimenFiscal" :Mensaje="'*'+errorvalidacion.RegimenFiscal[0]"/>
                        </div>
                    </div>

                    <div class="row mt-4 text-end">
                        <div class="col-md-12">
                            <button v-if="!bndNuevo" type="button" class="btn btn-secondary me-2" @click="deleteInformacion()" :disabled="DisableBtn">
                                <span v-if="loadingBtnDel" class="text-white spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Limpiar mis datos</button>

                            <button  type="button" class="btn btn-primary " @click="Guardar()" :disabled="DisableBtn">
                                <i v-if="loadingBtnSave" class="fad fa-spinner-third fa-spin"></i>
                                <template v-if="bndNuevo">Guardar</template> <template v-else>Actualizar mis</template> datos</button>

                            
                        </div>
                    </div>
                
                </div>
            </div>
        </template>
    </CLoader>
</template>

<script>
import CValidation from "@/components/CValidation.vue";
import CLoader from "@/components/CLoader.vue";
import StaticComboBox from "@/helpers/StaticComboBox";

export default {
    name: 'FormDatosFiscales',
    props: [''],
    components: {
        CLoader,
        CValidation
    },

    data() {
        return {
            bndNuevo: true,
            bndUser: false,
            loadingBtnSave: false,
            loadingBtnDel: false,
            objUser: {
                IdUsuario: 0,
                Rfc: '',
            },

            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  true,
            },

            objClientedatofiscal: {
				IdClienteDatoFiscal: 0,
				IdCliente: '',
				RazonSocial: '',
				Rfc: '',
				Direccion: '',
				Colonia: '',
				Municipio: '',
				Estado: '',
				Pais: '',
				CodigoPostal: '',
				Telefono: '',
				RegimenFiscal: 0,
            },
            ListaCombo1: StaticComboBox.RegimenesFiscales,
            errorvalidacion:[],
            DisableBtn: false,
        };
    },

    mounted() {
        this.limpiar();
        this.getUserData();
        this.recoveryInformacion();
    },

    computed:{
        convertLetras: function() {
            if(this.objClientedatofiscal.RazonSocial != undefined && this.objClientedatofiscal.RazonSocial != null &&  this.objClientedatofiscal.RazonSocial != '') {

                let normal = this.objClientedatofiscal.RazonSocial;
                const b = String.prototype.toUpperCase.call(normal);
                this.objClientedatofiscal.RazonSocial = b;

                }else {
                this.objClientedatofiscal.RazonSocial = '';
            }

            if(this.objClientedatofiscal.Rfc != undefined && this.objClientedatofiscal.Rfc != null &&  this.objClientedatofiscal.Rfc != '') {

                let normal = this.objClientedatofiscal.Rfc;
                const b = String.prototype.toUpperCase.call(normal);
                this.objClientedatofiscal.Rfc = b;

            }else {
                this.objClientedatofiscal.Rfc = ''
            }
        }
    },

    methods: {

        getUserData() {
            const usr = localStorage.getItem('user');

            if(usr != undefined && usr != null && usr != '') {
                this.objUser = JSON.parse(usr);
                this.bndUser = true;
            }
        },
        
        async recoveryInformacion() {
            
            this.ConfigLoad.ShowLoader = true;

            await this.$http.get("clientefiscales")
            .then((res) => {
                //console.log(res.data);

                if(res.data.status){
                    this.bndNuevo = false;
                    this.objClientedatofiscal     = res.data.data;
                }else {
                    if(this.bndUser) {
                        this.objClientedatofiscal.IdCliente = this.objUser.IdUsuario;
                    }
                }

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },

        async deleteInformacion() {
            
            this.ConfigLoad.ShowLoader = true;
            this.loadingBtnDel = true;

            this.$http.delete("clientefiscales/"+this.objClientedatofiscal.IdClienteDatoFiscal)
            .then((res) => {
                this.$toast.success('Datos limpios','¡Exito!','');
                this.limpiar();
                this.ConfigLoad.ShowLoader = false;
                this.loadingBtnDel = false;

                if(this.bndUser) {
                    this.objClientedatofiscal.IdCliente = this.objUser.IdUsuario;
                }

            }).catch(err => {
                console.log(err);
                //this.$toast.error(err.response.data.message);
            });
        },

        async Guardar()
        {
			this.errorvalidacion = [];
			this.DisableBtn = true;
            this.ConfigLoad.ShowLoader = true;
            this.loadingBtnSave = true;

			if(this.objClientedatofiscal.IdClienteDatoFiscal == 0) {
				await this.$http.post(
					'clientefiscales',this.objClientedatofiscal
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});

			} else {
				await this.$http.put(
					'clientefiscales/'+this.objClientedatofiscal.IdClienteDatoFiscal,
					this.objClientedatofiscal
				).then((res)=>{                 
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});

			}
        },

		EjecutaConExito(res)
        {      
            this.objClientedatofiscal = {};
            this.objClientedatofiscal = res.data.data;
            this.bndNuevo   = false;
            this.DisableBtn = false;  
            this.loadingBtnSave = false;
            this.$toast.success('Datos Guardados','¡Exito!');
            this.ConfigLoad.ShowLoader = false;
        },

        EjecutaConError(err)
        {
            this.DisableBtn = false;
            this.loadingBtnSave = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.$toast.warning('Complete los campos');
            }
            else{
                this.$toast.error(err.response.data.message);
            }

            this.ConfigLoad.ShowLoader = false;
        },


        limpiar() {
            this.objClientedatofiscal = {
				IdClienteDatoFiscal: 0,
				IdCliente: '',
				RazonSocial: '',
				Rfc: '',
				Direccion: '',
				Colonia: '',
				Municipio: '',
				Estado: '',
				Pais: '',
				CodigoPostal: '',
				Telefono: '',
				RegimenFiscal: 0,
            };

            this.bndNuevo = true;
        }
    },
};
</script>