<template>
    
        <main>
           <!--<input type="hidden" :value="reboot">-->
        <!-- Bienvenida -->
            <section v-if="parseInt(bienvenida.TipoBienvenida) == 1" class="home home-gradient pt-5 image-bg">
                <div class="container pt-3 home-content position-relative">
                    <div class="row align-items-center text-center text-md-start mt-5">
                        <div class="col-12 col-md-6">
                            <h1 class="fw-bold display-4 text-light ff-roboto">
                                
                                {{ bienvenida.Titulo }}
                            </h1>
                            <p class="fs-5 text-light ff-roboto">
                                {{ bienvenida.Subtitulo }}
                            </p>
                            <div class="row">
                                <div class="button-store">
                                  <!--  <a href="#"
                                        class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                            class="fab fa-google-play"></i>
                                        <p>
                                            Disponible en<span>Google Play</span>
                                        </p>
                                    </a>
                                    <a href="#"
                                        class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                            class="fab fa-apple"></i>
                                        <p>
                                            Disonible en<span>App Store</span>
                                        </p>
                                    </a>-->
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-center">
                            <img v-if="bienvenida.ImgSecundaria != null && bienvenida.ImgSecundaria != undefined" 
                                class="img-fluid home-img-animation position-relative" :src="RutaBienvenida+bienvenida.IdBienvenida+'/'+bienvenida.ImgSecundaria"
                                alt="" />
                        </div>
                    </div>
                </div>
                <div class="wave-effect wave-anim">
                    <div class="waves-shape shape-one">
                        <div class="wave wave-one"></div>
                    </div>

                    <div class="waves-shape shape-two">
                        <div class="wave wave-two"></div>
                    </div>

                    <div class="waves-shape shape-three">
                        <div class="wave wave-three"></div>
                    </div>
                </div>
                <div class="background-img" :style="'background-image: linear-gradient(-47deg, #7d4fe042, #4628dc74), url('+RutaBienvenida+bienvenida.IdBienvenida+'/'+bienvenida.ImgPortada+');'"></div>
            </section>

            <section v-else-if="parseInt(bienvenida.TipoBienvenida) == 2" class="contenedor position-relative">
                <video autoplay muted loop playsinline >
                    <source :src="RutaBienvenida+bienvenida.IdBienvenida+'/'+bienvenida.VideoPortada" type="video/mp4">
                </video>
                <div class="centrado">
                    <div class="container pt-3 home-content position-relative">
                        <div class="row align-items-center mt-5">
                            <div class="col-12 col-md-11 col-lg-7 d-none d-md-block d-lg-block">
                                <h1 class="fw-bold display-4 text-light ff-roboto">
                                    {{ bienvenida.Titulo }}
                                </h1>
                                <p class="fs-5 text-light ff-roboto">
                                    {{ bienvenida.Subtitulo }}
                                </p>
                                <div class="row">
                                    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-md-block d-lg-block">
                                        <div class="button-store">
                                            <a href="#"
                                                class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                                    class="fab fa-google-play"></i>
                                                <p>Disponible en<span>Google Play</span></p>
                                            </a>
                                            <a href="#"
                                                class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                                    class="fab fa-apple"></i>
                                                <p>Disonible en<span>App Store</span></p>
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-1 col-lg-5 d-none d-lg-block text-end">
                                <img v-if="bienvenida.ImgSecundaria != null && bienvenida.ImgSecundaria != undefined" 
                                    class="img-fluid home-img-animation position-relative" style="height: 530px;" 
                                    :src="RutaBienvenida+bienvenida.IdBienvenida+'/'+bienvenida.ImgSecundaria" 
                                    alt="Mujer con Zumba wear" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="over_only"></div>
                <div class="wave-effect wave-anim">
                    <div class="waves-shape shape-one">
                        <div class="wave wave-one"></div>
                    </div>
    
                    <div class="waves-shape shape-two">
                        <div class="wave wave-two"></div>
                    </div>
    
                    <div class="waves-shape shape-three">
                        <div class="wave wave-three"></div>
                    </div>
                </div>
            </section>

            <section v-else class="home home-gradient pt-5 image-bg">
                <div class="container pt-3 home-content position-relative">
                    <div class="row align-items-center text-center text-md-start mt-5">
                        <div class="col-12 col-md-6">
                            <h1 class="fw-bold display-4 text-light ff-roboto">
                                Con Zumba <br />te vistes y entrenas mejor
                            </h1>
                            <p class="fs-5 text-light ff-roboto">
                                La parte más importante de nuestra comunidad
                                eres tú, diviértete, baila, ejercitate y
                                muéstrale a todos lo bien que te sientes con
                                Zumba® y lo cómodo que es usar Zumba Wear®.
                            </p>
                            <div class="row">
                                <div class="button-store">
                                    <a href="#"
                                        class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                            class="fab fa-google-play"></i>
                                        <p>
                                            Disponible en<span>Google Play</span>
                                        </p>
                                    </a>
                                    <a href="#"
                                        class="text-decoration-none custom-btn d-inline-flex align-items-center m-2"><i
                                            class="fab fa-apple"></i>
                                        <p>
                                            Disonible en<span>App Store</span>
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-center">
                            <img class="img-fluid home-img-animation position-relative" src="@/assets/style/images/home-img-5.png"
                                alt="Mujer con Zumba wear" />
                        </div>
                    </div>
                </div>
                <div class="wave-effect wave-anim">
                    <div class="waves-shape shape-one">
                        <div class="wave wave-one"></div>
                    </div>

                    <div class="waves-shape shape-two">
                        <div class="wave wave-two"></div>
                    </div>

                    <div class="waves-shape shape-three">
                        <div class="wave wave-three"></div>
                    </div>
                </div>
                <div class="background-img"></div>
            </section>
        <!-- Termina Bienvenida -->

        <!-- FLYERS PROMOCIONALES -->
            <section v-if="listFlyers.length > 0" class="mt-4 container">
                <div class="row">
                    <div v-for="(flyer, indx) in listFlyers" :key="'fly_'+indx" class="col-12 col-md-6 col-lg-4 mb-4" v-bind:class=" indx > 1 ? 'd-md-none d-lg-block' : ''">
                        <div class="arrival w-100 h-100 rounded-3 d-flex align-items-end"
                            v-bind:style="{ 'background-image': 'url(' + RutaFlyer+flyer.IdFlyerPromocion+'/'+flyer.UrlImagen + ')' }">
                            <a style="cursor: pointer;" @click="redirectFlyer(flyer)" class="btn btn-primary ms-5 mb-4">Ver Mas <i
                                    class="far fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </section>
        <!-- TERMINA FLYERS PROMOCIONALES -->


        <!-- los mas vendidos -->
            <section v-if="showLoadingCardsVnd" class="my-5 container">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="row">
                            <div class="col-12 col-lg">
                                <h3 class="fw-bold">Los más vendidos</h3>
                            </div>
                            <div class="col-12 col-lg mt-2">

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row px-md-0 px-4">
                    <div class="col-12 col-md-5 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <div class="card" aria-hidden="true">
                                        
                            <div class="card-body">
                                <svg class="bd-placeholder-img card-img-top" width="100%" height="300" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                    <title>Placeholder</title>
                                    <rect width="100%" height="100%" fill="#868e96"></rect>
                                </svg>            
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-8 col-xl-9 px-3">
                        <div class="row">
                            <div class="col-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                        <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                        </svg>
                                        <div class="card-body">
                                            <h5 class="card-title placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </h5>
                                            <p class="card-text placeholder-glow">
                                                <span class="placeholder col-7"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-8"></span>
                                            </p>
                                            <p class="placeholder-glow">
                                                <span class="placeholder col-4"></span>
                                            </p>
                                            
                                        </div>
                                    </div>
                            </div>
                            <div class="col-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                        <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                        </svg>
                                        <div class="card-body">
                                            <h5 class="card-title placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </h5>
                                            <p class="card-text placeholder-glow">
                                                <span class="placeholder col-7"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-8"></span>
                                            </p>
                                            <p class="placeholder-glow">
                                                <span class="placeholder col-4"></span>
                                            </p>
                                            
                                        </div>
                                    </div>
                            </div>
                            <div class="col-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                        <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                        </svg>
                                        <div class="card-body">
                                            <h5 class="card-title placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </h5>
                                            <p class="card-text placeholder-glow">
                                                <span class="placeholder col-7"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-8"></span>
                                            </p>
                                            <p class="placeholder-glow">
                                                <span class="placeholder col-4"></span>
                                            </p>
                                            
                                        </div>
                                    </div>
                            </div>
                            <div class="col-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                        <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="#868e96"></rect>
                                        </svg>
                                        <div class="card-body">
                                            <h5 class="card-title placeholder-glow">
                                                <span class="placeholder col-6"></span>
                                            </h5>
                                            <p class="card-text placeholder-glow">
                                                <span class="placeholder col-7"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-4"></span>
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-8"></span>
                                            </p>
                                            <p class="placeholder-glow">
                                                <span class="placeholder col-4"></span>
                                            </p>
                                            
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
               

                
            </section>

            <section v-show="!showLoadingCardsVnd" class="my-5 container">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="row">
                            <div class="col-12 col-lg">
                                <h3 class="fw-bold">Los más vendidos</h3>
                            </div>
                            <div class="col-12 col-lg mt-2">
                                <!--<ul class="d-flex list-unstyled justify-content-lg-end">
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 ps-0 hover-purple-text">
                                            Destacados
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 hover-purple-text">
                                            Populares
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Nuevos
                                        </button>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row px-md-0 px-4">
                    <div class="col-12 col-md-5 col-lg-4 col-xl-3 mb-3 mb-md-0">
                        <div class="mx-auto banner d-flex flex-column align-items-start p-5 bg-img-2" 
                            v-bind:style="{ 'background-image': 'url(' + RutaWearwall+WearWallCv.IdWearwall+'/'+WearWallCv.ImgCover + ')' }">
                            <h3 class="fs-1 text-light fw-bolder mb-4">
                                Zumba WearWall
                            </h3>
                            <button @click="viewWall()" class="btn btn-primary mt-4">
                                Ir al WearWall
                                <i class="far fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-7 col-lg-8 col-xl-9 px-3">
                       <!-- <div v-show="!showLoadingCardsVnd"  class="best-sellers-slider">
                            <div class="bs-card" v-for="(item,index) in listPrdMsVendidos" :key="index">
                                    <div class="card card-produc position-relative">
                                        <div v-if="parseInt(item.TieneRebaja) > 0" class="tag px-2 py-1 bg-danger fw-bold">
                                        OFERTA
                                    </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <figure class="m-0">
                                                        <img :src="RutaProducto+item.IdProducto+'/'+item.ModeloPrincipal.Imagen"  class="img-fluid rounded" />
                                                    </figure>
                                                    <p class="mt-0">
                                                        <span v-if="item.Categoria != undefined && item.Categoria != null" class="text-body-tertiary fs-8">{{ item.Categoria.Nombre }}</span>
                                                    </p>
                                                </div>
                                                <div class="col-12">
                                                    <h2 class="fs-6 mb-1">
                                                        <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" class="text-darker hover-purple-text">
                                                            {{ item.Nombre }}
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div class="col-12">
                                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                                            <span class="text-decoration-line-through fs-8">{{ $formatNumber(item.Precio,'$') }}</span>
                                                        </p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.Precio,'$') }}
                                                        </p>
                                                    </template>
                                                    
                                                </div>
                                    
                                                <div class="col-12">
                                                    <div class="d-grid gap-2">
                                                        <button class="btn btn-primary pasarela">
                                                            <i class="far fa-shopping-bag"></i>
                                                            Agregar a la Bolsa
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div> -->
                            <VueSlickCarousel v-if="!showLoadingCardsVnd" class="best-sellers-slider" v-bind="settings">
                                <div class="bs-card" v-for="(item,index) in listPrdMsVendidos" :key="index">
                                    <div class="card card-produc position-relative h-100">
                                        <div v-if="parseInt(item.TieneRebaja) > 0" class="tag px-2 py-1 bg-danger fw-bold">
                                        OFERTA
                                    </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <figure class="m-0">
                                                        <img :src="RutaProducto+item.IdProducto+'/'+item.ModeloPrincipal.Imagen"  class="img-fluid rounded" />
                                                    </figure>
                                                    <p class="mt-0">
                                                        <span v-if="item.Categoria != undefined && item.Categoria != null" class="text-body-tertiary fs-8">{{ item.Categoria.Nombre }}</span>
                                                    </p>
                                                </div>
                                                <div class="col-12">
                                                    <h2 class="fs-6 mb-1">
                                                        <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" class="text-darker hover-purple-text">
                                                            {{ item.Nombre }}
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div class="col-12">
                                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                                            <span class="text-decoration-line-through fs-8">{{ $formatNumber(item.Precio,'$') }}</span>
                                                        </p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="prod-nam fw-bolder mb-1">
                                                            {{ $formatNumber(item.Precio,'$') }}
                                                        </p>
                                                    </template>
                                                    
                                                </div>
                                                
                                                <div class="col-12">
                                                    <div class="d-grid gap-2">
                                                        <button @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" class="btn btn-primary pasarela">
                                                            <i class="far fa-shopping-bag"></i>
                                                            Agregar a la Bolsa
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VueSlickCarousel>
                    </div>
                </div>
            </section>
        <!--termina los mas vendidos -->


        <!-- Los Favoritos -->
            <section v-if="showLoadingCards" class="container-lg my-5">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="row">
                            <div class="col-12 col-lg">
                                <h3 class="fw-bold">Los Favoritos</h3>
                            </div>
                            <div class="col-12 col-lg mt-2">
                                <ul class="d-flex list-unstyled justify-content-lg-end flex-wrap">
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 ps-0 hover-purple-text">
                                            Todos
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium me-2 hover-purple-text">
                                            Tops
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Bras
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Leggings
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Accesorios
                                        </button>
                                    </li>
                                    <li>
                                        <button class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Calzado
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                    <div class="card-body">
                                        <h5 class="card-title placeholder-glow">
                                        <span class="placeholder col-6"></span>
                                        </h5>
                                        <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        </p>
                                        <p class="placeholder-glow">
                                            <span class="placeholder col-4"></span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                    <div class="card-body">
                                        <h5 class="card-title placeholder-glow">
                                        <span class="placeholder col-6"></span>
                                        </h5>
                                        <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        </p>
                                        <p class="placeholder-glow">
                                            <span class="placeholder col-4"></span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                    <div class="card-body">
                                        <h5 class="card-title placeholder-glow">
                                        <span class="placeholder col-6"></span>
                                        </h5>
                                        <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        </p>
                                        <p class="placeholder-glow">
                                            <span class="placeholder col-4"></span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                    <div class="card-body">
                                        <h5 class="card-title placeholder-glow">
                                        <span class="placeholder col-6"></span>
                                        </h5>
                                        <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        </p>
                                        <p class="placeholder-glow">
                                            <span class="placeholder col-4"></span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative" aria-hidden="true">
                                    <svg class="bd-placeholder-img card-img-top" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                                    <div class="card-body">
                                        <h5 class="card-title placeholder-glow">
                                        <span class="placeholder col-6"></span>
                                        </h5>
                                        <p class="card-text placeholder-glow">
                                        <span class="placeholder col-7"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-4"></span>
                                        <span class="placeholder col-6"></span>
                                        <span class="placeholder col-8"></span>
                                        </p>
                                        <p class="placeholder-glow">
                                            <span class="placeholder col-4"></span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="!showLoadingCards" class="container-lg my-5">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="row">
                            <div class="col-12 col-lg">
                                <h3 class="fw-bold">Los Favoritos</h3>
                            </div>
                            <div class="col-12 col-lg mt-2">
                                <ul class="d-flex list-unstyled justify-content-lg-end flex-wrap">
                                    <li>
                                        <button @click="listProdFavoritos(0)" class="bg-transparent border-0 fw-medium me-2 ps-0 hover-purple-text">
                                            Todos
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="listProdFavoritos(14)" class="bg-transparent border-0 fw-medium me-2 hover-purple-text">
                                            Tops
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="listProdFavoritos(15)" class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Bras
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="listProdFavoritos(13)" class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Leggings
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="listProdFavoritos(6)" class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Accesorios
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="listProdFavoritos(3)" class="bg-transparent border-0 fw-medium hover-purple-text">
                                            Calzado
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div v-for="(item,index) in listPrdFavoritos" :key="index" class="col-lg-1-5 col-12 col-sm-6 col-md-4 mb-3">
                                <div class="card card-produc position-relative h-100" id="p1">
                                    <div v-if="parseInt(item.TieneRebaja) > 0" class="tag px-2 py-1 bg-danger fw-bold">
                                        OFERTA
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                                    <figure class="m-0 card-img">
                                                        <img :src="get_ImagePreview(item.Modelos,0)" alt="Producto" :id="'imgPrFav_'+item.IdProducto"
                                                        class="img-fluid c1 show-img" />
                                                    </figure>
                                                </a>
                                                <p class="mb-2">
                                                    <span v-if="item.Categoria != undefined && item.Categoria != null" class="text-body-tertiary fs-7">{{ item.Categoria.Nombre }}</span>
                                                </p>
                                            </div>
                                            <div class="col-12">
                                                <div class="colors">
                                                    <div v-for="(color,idx) in item.Modelos" :key="idx"
                                                        @mouseover="cambiarImgColor(color)"
                                                        @click="cambiarImgColor(color)"
                                                        v-b-tooltip.hover.Top :title="color.Nombre"
                                                        class="color-available" :style=" color.Color == '#ffffff' ? 'border-color:#000; border-width: 1px; background-color:'+color.Color+';' : 'background-color:'+color.Color+';'"></div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <h2 class="fs-6 mb-1">
                                                    <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" class="text-darker hover-purple-text">
                                                        {{ item.Nombre }}
                                                    </a>
                                                </h2>
                                            </div>
                                         
                                            <div class="col-12">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <template v-if="parseInt(item.TieneRebaja) > 0">
                                                            <span class="fw-bolder w-auto fs-5">{{ $formatNumber(item.PrecioRebaja,'$') }}</span>
                                                            <span class="text-decoration-line-through fs-6">{{ $formatNumber(item.Precio,'$') }}</span>
                                                        </template>
                                                        <template v-else>
                                                            <span class="fw-bolder w-auto fs-5">{{ $formatNumber(item.Precio,'$') }}</span>
                                                            
                                                        </template>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        <!-- termina Los Favoritos -->

        <!-- inicia ofertas -->
            <ProductosOfertasVue :pTituloSeccion="'Ofertas de temporada'"/>
        <!-- termina ofertas-->

        <!-- inicia Tendencias -->
            <section v-if="showLoadingCardsTop" class="container mb-4">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <h4 class="title-01 mb-4">Más Vendidos</h4>  
                        <div v-for="n in 3" :key="n" class="row hover-up mb-3">
                            <div class="col-4">
                                <svg class="bd-placeholder-img card-img-top" width="100%" height="85" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                            </div>
                            <div class="col-8">
                               
                                <h5 class="card-title  placeholder-glow">
                                    <span class="placeholder col-10 placeholder-sm"></span>
                                    <span class="placeholder col-6 placeholder-sm"></span>
                                </h5>
                               
                                
                                <div class="price">
                                    <p class="prod-nam fw-bolder my-1 fs-6 placeholder-glow">
                                        <span class="placeholder col-4 bg-primary"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <h4 class="title-01 mb-4">Tendencias</h4>
                        <div v-for="n in 3" :key="n" class="row hover-up mb-3">
                            <div class="col-4">
                                <svg class="bd-placeholder-img card-img-top" width="100%" height="85" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                            </div>
                            <div class="col-8">
                               
                                <h5 class="card-title  placeholder-glow">
                                    <span class="placeholder col-10 placeholder-sm"></span>
                                    <span class="placeholder col-6 placeholder-sm"></span>
                                </h5>
                               
                                
                                <div class="price">
                                    <p class="prod-nam fw-bolder my-1 fs-6 placeholder-glow">
                                        <span class="placeholder col-4 bg-primary"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-lg-4 col-xl-3 d-none d-lg-block">
                        <h4 class="title-01 mb-4">Recien Agregados</h4>
                        <div v-for="n in 3" :key="n" class="row hover-up mb-3">
                            <div class="col-4">
                                <svg class="bd-placeholder-img card-img-top" width="100%" height="85" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                            </div>
                            <div class="col-8">
                               
                                <h5 class="card-title  placeholder-glow">
                                    <span class="placeholder col-10 placeholder-sm"></span>
                                    <span class="placeholder col-6 placeholder-sm"></span>
                                </h5>
                               
                                
                                <div class="price">
                                    <p class="prod-nam fw-bolder my-1 fs-6 placeholder-glow">
                                        <span class="placeholder col-4 bg-primary"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-xl-3 d-none d-xl-block">
                        <h4 class="title-01 mb-4">Más buscados</h4>
                        <div v-for="n in 3" :key="n" class="row hover-up mb-3">
                            <div class="col-4">
                                <svg class="bd-placeholder-img card-img-top" width="100%" height="85" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#868e96"></rect>
                                    </svg>
                            </div>
                            <div class="col-8">
                               
                                <h5 class="card-title  placeholder-glow">
                                    <span class="placeholder col-10 placeholder-sm"></span>
                                    <span class="placeholder col-6 placeholder-sm"></span>
                                </h5>
                               
                                
                                <div class="price">
                                    <p class="prod-nam fw-bolder my-1 fs-6 placeholder-glow">
                                        <span class="placeholder col-4 bg-primary"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </section>

            <section v-if="!showLoadingCardsTop" class="container mb-4">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <h4 class="title-01 mb-4">Más Vendidos</h4>  
                        <div v-for="(item,index) in listProductosTop.msvendidos" :key="index" class="row hover-up mb-3">
                            <div class="col-4">
                                <figure class="mb-0">
                                    <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                        <img :src="RutaProducto+item.IdProducto+'/'+item.Modelos.Imagen" class="card-img-top rounded-3"
                                            alt="producto" />
                                    </a>
                                </figure>
                            </div>
                            <div class="col-8">
                                <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                    <h5 class="card-title fs-6 fw-bold hover-purple-text">
                                        {{ item.Nombre }}
                                    </h5>
                                </a>
                                <!--<p class="mt-1 mb-3 me-2 fs-8">
                                    <i class="fas fa-star text-yellow"></i><i class="fas fa-star text-yellow"></i><i
                                        class="fas fa-star text-yellow"></i><i class="fas fa-star text-yellow"></i><i
                                        class="fas fa-star text-yellow"></i><span class="text-body-tertiary">(5.0)</span>
                                </p>-->
                                <div class="price">
                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                            <span class="text-decoration-line-through fs-8 text-body-tertiary">{{ $formatNumber(item.Precio,'$') }}</span>
                                        </p>
                                    </template>
                                    <template v-else>
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.Precio,'$') }}
                                        </p>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    

                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <h4 class="title-01 mb-4">Tendencias</h4>
                        <div v-for="(item,index) in listProductosTop.tendencia" :key="index" class="row hover-up mb-3">
                            <div class="col-4">
                                <figure class="mb-0">
                                    <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                        <img :src="RutaProducto+item.IdProducto+'/'+item.Modelos.Imagen" class="card-img-top rounded-3"
                                            alt="producto" />
                                    </a>
                                </figure>
                            </div>
                            <div class="col-8">
                                <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                    <h5 class="card-title fs-6 fw-bold hover-purple-text">
                                        {{ item.Nombre }}
                                    </h5>
                                </a>
                                
                                <div class="price">
                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                            <span class="text-decoration-line-through fs-8 text-body-tertiary">{{ $formatNumber(item.Precio,'$') }}</span>
                                        </p>
                                    </template>
                                    <template v-else>
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.Precio,'$') }}
                                        </p>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-lg-4 col-xl-3 d-none d-lg-block">
                        <h4 class="title-01 mb-4">Recien Agregados</h4>
                        <div v-for="(item,index) in listProductosTop.nuevos" :key="index" class="row hover-up mb-3">
                            <div class="col-4">
                                <figure class="mb-0">
                                    <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;">
                                        <img :src="RutaProducto+item.IdProducto+'/'+item.imagenprincipal.Imagen" class="card-img-top rounded-3"
                                            alt="producto" />
                                    </a>
                                </figure>
                            </div>
                            <div class="col-8">
                                <a @click="viewDetalle(item.categoriaprincipal.IdCategoria,item.categoriaprincipal.Nombre,item)" style="cursor: pointer;">
                                    <h5 class="card-title fs-6 fw-bold hover-purple-text">
                                        {{ item.Nombre }}
                                    </h5>
                                </a>
                               
                                <div class="price">
                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                            <span class="text-decoration-line-through fs-8 text-body-tertiary">{{ $formatNumber(item.Precio,'$') }}</span>
                                        </p>
                                    </template>
                                    <template v-else>
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.Precio,'$') }}
                                        </p>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-xl-3 d-none d-xl-block">
                        <h4 class="title-01 mb-4">Más buscados</h4>
                        <div v-for="(item,index) in listProductosTop.buscados" :key="index" class="row hover-up mb-3">
                            <div class="col-4">
                                <figure class="mb-0">
                                    <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                        <img :src="RutaProducto+item.IdProducto+'/'+item.Modelos.Imagen" class="card-img-top rounded-3"
                                            alt="producto" />
                                    </a>
                                </figure>
                            </div>
                            <div class="col-8">
                                <a @click="viewDetalle(item.Categoria.IdCategoria,item.Categoria.Nombre,item)" style="cursor: pointer;">
                                    <h5 class="card-title fs-6 fw-bold hover-purple-text">
                                        {{ item.Nombre }}
                                    </h5>
                                </a>
                              
                                <div class="price">
                                    <template v-if="parseInt(item.TieneRebaja) > 0">
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.PrecioRebaja,'$') }}
                                            <span class="text-decoration-line-through fs-8 text-body-tertiary">{{ $formatNumber(item.Precio,'$') }}</span>
                                        </p>
                                    </template>
                                    <template v-else>
                                        <p class="prod-nam fw-bolder my-1 fs-6 align-middle text-morado">
                                            {{ $formatNumber(item.Precio,'$') }}
                                        </p>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </section>
        <!--temina Tendencias -->
 
        <!-- COROUSEL DE CATEGORIAS -->
            <section class="container mb-4">
                <div class="row">
                    <div class="col-lg-12 section-title mb-4">
                        <div class="d-flex flex-row-reverse align-items-baseline justify-content-between">
                            <div class="arrows-wrap d-flex align-items-center">
                                <div @click="catCarouserPrev()" class="arrow-slicker" id="categories-sa-prev" style="cursor: pointer;">
                                    <i class="fas fa-arrow-left"></i>
                                </div>
                                <div @click="catCarouserNext()" class="arrow-slicker" id="categories-sa-next" style="cursor: pointer;">
                                    <i class="fas fa-arrow-right"></i>
                                </div>
                            </div>
                            <div class="d-lg-flex d-block align-items-baseline">
                                <h3 class="fw-bold">Categorias</h3>
                                <ul class="list-unstyled ms-lg-3">
                                   <!-- <li>
                                        <a href="category_view.html"
                                            class="text-decoration-none text-darker fw-medium me-2 ps-0 hover-purple-text">
                                            Ver todas
                                            <i class="mx-1 fa fa-chevron-right fs-7"></i>
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                         <!-- <div class="category-slider slider">
                            <div  v-for="(item,index) in listCategorias" :key="index" class="category-slider__card"  >
                                <a href="category_view.html"
                                    class="text-decoration-none d-flex flex-column align-items-center" >
                                    <figure>
                                        <img :src="rutaIconoCat+item.Icono" alt="icono" />
                                    </figure>
                                    <h6 class="fw-bold">{{ item.Nombre }}</h6>
                                </a>
                            </div>
                        </div> -->
                        
                            <VueSlickCarousel v-if="!showLoadingCategorias"  v-bind="settingsCategorias" class="category-slider slider" ref="carouselCategorias">
                            
                                <div v-for="(item,index) in listCategorias" :key="index" class="category-slider__card">
                                
                                    <a style="cursor: pointer;" @click="viewListProducto(item.IdCategoria,0,'')"
                                        class="text-decoration-none d-flex flex-column align-items-center" >
                                        <figure>
                                            <img :src="rutaIconoCat+item.Icono" alt="icono" />
                                        </figure>
                                        <h6 class="fw-bold">{{ item.Nombre }}</h6>
                                    </a>
                                    
                                </div>
                                
                            </VueSlickCarousel>
                        
                        
                        
                    </div>
                </div>
            </section>
        <!-- TERMINA COROUSEL DE CATEGORIAS -->
        
        <!--News letter-->
            <section class="container container-width mb-4">
                <div class="position-relative newsletter p-3 p-sm-5">
                    <div class="row">
                        <div class="col-12 col-lg-9 col-xl-6">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <p class="fs-1 fw-bolder text-dark">
                                        ¡Recibe las últimas ofertas y novedades
                                        directamente en tu correo!
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p class="fs-6 text-body">
                                        Suscribte a nuestro
                                        <span class="text-morado">Newsletter</span>.
                                    </p>
                                </div>
                            </div>
                            <form class="form" action="">
                                <div class="bg-white d-flex justify-content-between rounded-5 w-auto">
                                    <div class="d-flex">
                                        <i class="far fa-paper-plane text-body-tertiary my-auto ps-4"></i>
                                        <input type="email" class="shadow-none form-control border-0 h-100 rounded-5"
                                            placeholder="ejemplo@email.com" />
                                    </div>
                                    <div class="text-end">
                                        <button class="btn fw-bold btn-primary rounded-5 px-3 py-3 fs-6">
                                            Suscribirse
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        <!--News letter-->
        </main>
   
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
//import 'vue-slick-carousel/dist/vue-slick-carousel.css';
//import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import ProductosOfertasVue from '../catalogos/productos/ProductosOfertas.vue';


export default {
    name: 'HomeTienda',
    components: { 
        VueSlickCarousel,
        ProductosOfertasVue 
    },

    data() {
        return {
            RutaBienvenida:'',
            bienvenida:{
                Titulo: '',
                Subtitulo: '',
                TipoBienvenida: 1,
                ImgPortada: '',
                ImgSecundaria: '',
                VideoPortada: '',
            },

            RutaFlyer: '',
            listFlyers: [],

            RutaWearwall:'',
            WearWallCv:{
                Activo: 0,
                IdWearwall:0,
                ImgCover: '',
                Titulo: ''
            },
            
            firstCharge: false,
            ListaCategorias: [],
            RutaProducto: '',
            settings:{
                "dots": false,
                "arrows": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "autoplay": true,
                "autoplaySpeed": 3000,
                "pauseOnFocus": false,
                       
                "responsive": [
                    {
                        "breakpoint": 1299,
                        "settings": {
                            "slidesToShow": 3,
                        },
                    },
                    {
                        "breakpoint": 991,
                        "settings": {
                            "slidesToShow": 2,
                        },
                    },
                    {
                        "breakpoint": 575,
                        "settings": {
                            "slidesToShow": 1,
                        },
                    },
                ],
                
            },

            //Los Mas Vendidos (Mes)
            showLoadingCardsVnd: true,
            listPrdMsVendidos: [],

            //Los Favoritos
            showLoadingCards: true,
            listPrdFavoritos: [],

            // Productos Top
            showLoadingCardsTop: true,
            listProductosTop: {
                msvendidos: [],
                tendencia: [],
                nuevos: [],
                buscados: [],
            },

            showLoadingCategorias: true,
            listCategorias: [],
            rutaIconoCat: '',

            settingsCategorias:{
                "dots": false,
                "arrows": false,
                "infinite": true,
                "slidesToShow": 8,
                "slidesToScroll": 1,
                "speed": 500,
                "autoplay": true,
                "autoplaySpeed": 3000,
                "responsive": [
                    {
                        "breakpoint": 991,
                        "settings": {
                            "slidesToShow": 6,
                        },
                    },
                    {
                        "breakpoint": 767,
                        "settings": {
                            "slidesToShow": 4,
                            "arrows": false,
                        },
                    },
                    {
                        "breakpoint": 575,
                        "settings": {
                            "slidesToShow": 3,
                            "arrows": false,
                        },
                    },
                ],
                
            },

            isReady: false, 


        };
    },

    methods: {

        async recoveryBienvenida() {

            await this.$http.get("bienvenida",{params:{}})
            .then((res) => {
                //console.log(res.data);
                this.bienvenida         = res.data.data;
                this.RutaBienvenida     = res.data.rutaFile;
                

            }).finally(()=> {
                window.scrollTo(0,0);
                this.listFlyersProm();
            });
        },

        async listFlyersProm() {

            await this.$http.get("flyersprom",{
                params:{
                    TipoUso: 1
                }

            }).then((res) => {
                //console.log(res.data);
                this.listFlyers      = res.data.data;
                this.RutaFlyer       = res.data.rutaFile;
                

            }).finally(()=> {
                this.recoveryCoverWearwall();
            });
        },

        async recoveryCoverWearwall() {

            await this.$http.get("wearwallcv",{params:{}})
            .then((res) => {
                //console.log(res.data);
                this.WearWallCv      = res.data.data;
                this.RutaWearwall    = res.data.rutaFile;
                

            }).finally(()=> {
                this.listProdMsVendidos();
            });
        },


        async listProdMsVendidos() {
            this.showLoadingCardsVnd = true;

            await this.$http.get("getprodtopvendidos",{
                params:{
                    /*TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,*/
                }
            }).then((res) => {
                //console.log(res.data.productos);
                this.RutaProducto = res.data.rutaFile;
                this.listPrdMsVendidos = res.data.productos;

            }).finally(()=>{
                this.showLoadingCardsVnd = false;
                this.listProdFavoritos();
            });
        },


        async listProdFavoritos(idcat = 0) {
            this.showLoadingCards = true;

            await this.$http.get("getprodfavorits",{
                params:{
                    IdCategoria: parseInt(idcat) > 0 ? parseInt(idcat) : 0
                }
            }).then((res) => {
                //this.RutaProducto = res.data.rutaFile;
                this.listPrdFavoritos = res.data.productos;


            }).finally(()=>{
                this.showLoadingCards = false;
                this.listTopProductos();

            });
        },

        

        async listTopProductos() {
            this.showLoadingCardsTop = true;

            await this.$http.get("getproductostop",{
                params:{
                    /*TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,*/
                }
            }).then((res) => {
                //console.log(res.data.data);
                this.listProductosTop = res.data.data;


            }).finally(()=>{
                this.showLoadingCardsTop = false;
                this.getCategorias();
                
            });
        },

        async getCategorias() {
            this.ListaCategorias = [];
            this.showLoadingCategorias = true;

                await this.$http.get('categorias', {
                    params: {
                        isSimple: 1,
                        onlyPrincipales: 1,
                    }

                }).then((res) => {
                    //console.log(res.data);

                    let arrResponse = [];
                    res.data.data.forEach(element => {
                        
                        this.ListaCategorias.push({
                            IdCategoria: element.IdCategoria,
                            Nombre: element.Nombre,
                            Asociado: element.Asociado,
                            Icono: element.Icono,
                        });
                    });

                    this.listCategorias = this.ListaCategorias;
                    this.rutaIconoCat   = res.data.rutaIcono;


                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.showLoadingCategorias = false;
                    //this.isReady = true;
                })
           
        },

        get_ImagePreview(array,index) {
            var nameimagen="";
            if(array != undefined && array != null & array.length > 0) {
                if(array[index]) {
                    nameimagen=array[index].Imagen
                }
                return this.RutaProducto+array[index].IdProducto+'/'+nameimagen;
            }else{
                return this.RutaProducto+'noimagen.jpg';
            }
        },

        cambiarImgColor(arrayImgs) {
            var imagenname = "";
            if(arrayImgs.Imagen !== undefined) {
                imagenname=arrayImgs.Imagen;
            }
            document.getElementById("imgPrFav_"+arrayImgs.IdProducto).src = this.RutaProducto+arrayImgs.IdProducto+'/'+imagenname;
        },

        catCarouserNext() {
            this.$refs.carouselCategorias.next();
        },

        catCarouserPrev(){
            this.$refs.carouselCategorias.prev();
        },


        viewDetalle(idcategoria,nomcategoria,producto) {
            this.$router.push({name:'viewproducto',
                query:{
                    'cat':idcategoria,
                    'prod':producto.IdProducto,
                    'feat':0,
                    'nomcat':nomcategoria.replace(/\s+/g, '-'),
                    'nomp':producto.Nombre.replace(/\s+/g, '-')
                }
            });
            //this.bus.$emit('cambiaLogoNegro');
            
        },

        viewWall() {
            this.$router.push({name: 'wearwall', params:{}});
        },

        viewListProducto(idCat = 0,isTop = 0,textbusqueda = ''){
            
            this.$router.push({name:'productos', 
                params: { 
                    pIdCategoria: idCat, 
                    pIsTop: isTop, 
                    pTextBusqueda: textbusqueda 
                } 
            });
            
        },

        redirectFlyer(item) {
            //console.log(item);
            switch ( parseInt(item.TipoFlyer) ) {
                case 1:
                    if(item.IdSubCategoria != null && item.IdSubCategoria != undefined && parseInt(item.IdSubCategoria) > 0){
                        this.viewListProducto(item.IdSubCategoria,0,'');
                    }else {
                        this.viewListProducto(item.IdCategoria,0,'');
                    }
                    break;
                case 2:
                    this.$router.push({name:'viewproducto',
                        query:{
                            'cat':item.IdCategoria,
                            'prod':item.IdProducto,
                            'feat':0,
                            'nomcat':'',
                            'nomp':item.NomProducto.replace(/\s+/g, '-')
                        }
                    });
                    
                    break;

                case 3:
                    this.$router.push({name: 'viewevento', params:{Id:item.IdEvento}});
                    break;
            
            }
            
        }
        
    },

    mounted() {
        sessionStorage.setItem('PositionProduct','');
        sessionStorage.setItem('currentPage','1');
        localStorage.setItem('fromUrl','');
        localStorage.setItem('IdCategoria','');

        this.bus.$emit('cambiaLogoBlanco');
        this.recoveryBienvenida();        
        
    },
    computed: {
        reboot:function () {
            if(this.$store.state.status === '' && this.firstCharge) {
                //this.getCategorias();
                //this.get_ProductosRebaja();
                //this.get_Novedades();
                //this.getBanners();
                //this.getWearCover();
                //this.bus.$emit('count_Cart');
            }

            /*if(this.isReady) {
                //console.log('activado');
                
                $(".category-slider").slick({
                    // appendArrows: $('#categories-arrows'),
                    dots: false,
                    infinite: true,
                    arrows: false,
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    autoplay: true,
                    prevArrow: $("#categories-sa-prev"),
                    nextArrow: $("#categories-sa-next"),
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 6,
                            },
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 4,
                                arrows: false,
                            },
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: 3,
                                arrows: false,
                            },
                        },
                    ],
                }); 
            }*/
        },
    }
};
</script>
<style>

</style>