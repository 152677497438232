//************************************************************ AQUI IMPORTAMOS HERRAMIENTAS IMPORTANTES *******************************************************************************
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import VueTreeselect from "@riophae/vue-treeselect";
import vSelect from "vue-select";
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Toast from "vue-toastification";
import VueNumeric from 'vue-numeric';
import VueMeta from 'vue-meta';
import VueCountdown from '@chenfengyuan/vue-countdown';
//import $ from "jquery";
//import "jquery-syotimer";

//import VCalendar from 'v-calendar';

//************************************************************ AQUI IMPORTAMOS ARCHIVOS CSS, JAVASCRIPT, BOOSTRAP, FONTS, ICONOS, HELPERS, ETC ****************************************
// ESTILOS PRINCIPALES
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-toastification/dist/index.css";

// IMPORT DE COMPONENTES
import '@/import/Index';


// CONFIG
//import MyPlugin from "@/config/General.js";
import MyPlugin from "@/helpers/General.js";
import HttpConfig from "@/config/HttpConfig";

// ESTILOS PROPIOS
import './assets/style/css/main.css';


// JS GENERALES
import "@/assets/style/plugin/popper/popper.min.js";
import "@/assets/style/plugin/slick/slick.min.js";
//import "@/assets/style/plugin/elevatezoom/jquery.elevatezoom";

// ESTILOS GENERALES
import "@/assets/style/plugin/font_awesome/css/fontawesome.min.css";
import "@/assets/style/plugin/font_awesome/css/all.css";
import "@/assets/style/plugin/slick/slick.css";
import "@/assets/style/plugin/slick/slick-theme.css";




//************************************************************ AQUI VA LA BASE URL LOCAL, SERVIDOR DEMO, SERVIDOR PRODUCCIÓN **********************************************************
// CONTROL DEL AXIOS
Vue.config.productionTip  = false
Vue.prototype.bus         = new Vue;

let token = localStorage.getItem('token_user');
Vue.prototype.$http = HttpConfig(token);

Vue.config.productionTip = false


//************************************************************ AQUI VA LA CONFIGURACIÓN DE LAS HERRAMIENTAS IMPORTANTES ***************************************************************

// CONFIG GENERAL
Vue.use(MyPlugin, store, router);


// COMPONENTES GLOBALES DE HERRAMIENTAS IMPORTANTES
library.add(fas)
library.add(fab)
Vue.component("treeselect", VueTreeselect);
Vue.component("v-select", vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component(VueCountdown.name, VueCountdown);

// VUE BOOTSTRAP
Vue.use(BootstrapVue);

// OPCIONALMENTE, SE INSTALA EL COMPONENTE DEL ICONO DE BOOTSTRAP VUE
Vue.use(IconsPlugin);

// ALERT SWEET
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

// VUE TOAST
Vue.use(Toast,{
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

// JQUERY

window.$ = window.jQuery = require("jquery");


Vue.use(VueNumeric);
Vue.use(VueMeta,{
  //refreshOnceOnNavigation: true
});
//Vue.use(VCalendar);



Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  
  // TODO: Perform any custom logic or log to server

};


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
